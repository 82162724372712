import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { body50, headline50 } from "css/text"

import ModalContainer, { weChatModal } from "components/modal/ModalContainer"

import { ReactComponent as ReactLogo } from "./wechatqrcode.svg"

const QRCodeContainer = styled.div`
  width: 70%;
  max-width: 260px;
  margin-block-end: 0.9375rem;
  align-self: center;
  margin: auto;

  ${getMediaQuery("m")} {
    width: 100%;
    max-width: 360px;
    margin-block-end: 1.25rem;
  }

  > svg {
    height: 100%;
    width: 100%;

    > path + path {
      stroke: rgb(var(--rolex-green));
    }
  }
`

const Heading = styled.h2`
  ${headline50}
  align-self: center;
  text-align: center;
`

const Paragraph = styled.p`
  ${body50}
  align-self: center;
  text-align: center;
  margin-block-start: 1.25rem;
`

export default function WeChatModal({ onClose, label, text, qr_code }) {
  const console = useConsole()

  console.verbose("WeChatModal(%o)")

  return (
    <ModalContainer onClose={onClose} className={weChatModal} modalHeading='modal-heading' modalContent='modal-content'>
      <QRCodeContainer>
        <ReactLogo />
      </QRCodeContainer>
      <Heading id='modal-heading'>{label}</Heading>
      <Paragraph id='modal-content'>{text}</Paragraph>
    </ModalContainer>
  )
}
