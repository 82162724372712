import { useEffect, useRef, memo } from "react"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe, setSource } from "./utils"
import { useEnv } from "contexts/Env"
import { CFGV7 } from "./context"

const { MAX_FRAMES, QUALITYSIZE } = CFGV7

function trueIndex(v) {
  return v.toString().padStart(3, "0")
}

function initSource3d(path3d, quality, imgpath, catalogYear) {
  return `${imgpath}/v7/dam/${catalogYear}/360/${quality}/${path3d}/${path3d}--`
}

function initSourceCld(path3d, quality, secureDistribution, catalogYear) {
  return `https://${secureDistribution}/image/upload/${quality}/v1/catalogue/${catalogYear}/360/${path3d}/${path3d}--`
}

function initSource2d(rmc, imgpath, facet, catalogYear) {
  return `${imgpath}${setSource(facet, rmc, catalogYear)}`
}

function createUrl(blobs) {
  return function (b) {
    let url = URL.createObjectURL(b)
    blobs.push(url)
    return url
  }
}

function lohi(getParams, o) {
  //  console.log(":::::", o.rmc, "lohi", o.paths)
  let loframes = o.paths.reduce((a, v) => {
    ////    console.log(v)
    ////    console.log(getParams(v).loframes)
    a = a.concat(getParams(v).loframes.split(","))
    return [...new Set(a)]
  }, [])
  //  console.log(o.rmc, "loframes", loframes)
  let hiframes = o.paths.reduce((a, v) => {
    a = a.concat(getParams(v).hiframes.split(","))
    return [...new Set(a)]
  }, [])
  //  console.log(o.rmc, "hiframes", hiframes)
  hiframes.forEach(i => {
    let found = loframes.indexOf(i)
    if (found >= 0) loframes.splice(found, 1)
  })
  //  console.log(o.rmc, loframes.length + hiframes.length)
  return { loframes, hiframes }
}

function qualityPath(s, q) {
  return `${s}-webp-${q}`
}

function qualityPathCld(s, q) {
  return `w_${s}/q_${q}`
}

function fetchRmc(rmc, path3d, loframes, hiframes, imgpath, catalogYear, mobile, secureDistribution) {
  ////  console.log(rmc, path3d)
  let q = Array.from({ length: 2 }).map((v, i) => qualityPathCld(QUALITYSIZE.S.lo[i], QUALITYSIZE.Q.lo[i]))
  const lopath = initSourceCld(path3d, q[mobile], secureDistribution, catalogYear)
  q = Array.from({ length: 2 }).map((v, i) => qualityPathCld(QUALITYSIZE.S.hi[i], QUALITYSIZE.Q.hi[i]))
  const hipath = initSourceCld(path3d, q[mobile], secureDistribution, catalogYear)
  const pk20watchpath = initSource2d(rmc, imgpath, "watch", catalogYear)
  const pk20shadowpath = initSource2d(rmc, imgpath, "shadow", catalogYear)
  //  console.log("rmc", rmc)
  //  console.log("loframes", loframes)
  //  console.log("hiframes", hiframes)
  //    console.log(lopath, hipath)
  let lodata = loframes.map(v => ({
    rmc: rmc,
    path: `${lopath}${trueIndex(v)}.webp`,
    index: v,
    size: QUALITYSIZE.S.lo[mobile],
  }))
  let hidata = hiframes.map(v => ({
    rmc: rmc,
    path: `${hipath}${trueIndex(v)}.webp`,
    index: v,
    size: QUALITYSIZE.S.hi[mobile],
  }))

  return lodata.concat(hidata)
}

function loadB64(aborts, blobs) {
  return function (path) {
    let controller = new AbortController()
    aborts.push(controller)
    return (
      fetch(new Request(path), {
        signal: controller.signal, credentials: "include"
      })
        .then(r => r.status === 200 && r.blob())
        .then(b => {
          return new Promise(resolve => {
            let reader = new FileReader()
            reader.onload = function () {
              resolve(this.result)
            }
            reader.readAsDataURL(b)
          })
        })
        //      .then(data => JSON.stringify(data))
        .catch(e => null)
    )
  }
}

const CCR = 50

export const CfgPreload = memo(({ Ctx }) => {
  const {
    getParams,
    model,
    getModel,
    family,
    updateStep,
    orientation,
    flagship,
    frames,
    preload,
    startrmc,
    updateProgress,
    loaded,
    total,
    catalog,
    fetching,
    preloading,
    flagships,
    clearFrames,
    precatalog,
    mobile,
    wa,
  } = useFrameAnimation(Ctx)
  const rfaborts = useRef([])
  const rfblobs = useRef([])
  const rfloop = useRef(null)
  const env = useEnv()
  const { cloudinary: { secureDistribution } } = env

  async function init(v) {
    if (!v) return
    startrmc.set(catalog.current?.[0].rmc)
    loaded.current = total.current = 0
    updateProgress(0)
    await clearFrames()
    //    await kill()
    let rmcs3d = catalog.current?.filter(v => v.has_3d_rendition)
    //    console.log("rmcs3d", rmcs3d)
    rfloop.current = rmcs3d.reduce((a, o) => {
      const { loframes, hiframes } = lohi(getParams, o)
      //      total.current += loframes.length + hiframes.length + 2
      return a.concat(fetchRmc(o.rmc, o.path3d, loframes, hiframes, env.content, env.catalogYear, mobile.get(), secureDistribution))
    }, [])
    total.current = rfloop.current.length
    let count = 0
    //    console.log("rfloop.current", rfloop.current)
    //    console.log("total frames =", total.current)
    //    return
    for (let i = 0; i < CCR; i++) {
      load(v)
    }

    async function load(uid) {
      if (v !== uid || !rfloop.current.length) return
      let { rmc, path, index, size } = rfloop.current.shift()
      let bloburl = await loadB64(rfaborts.current, rfblobs.current)(path)
      rfblobs.current.push(bloburl)
      if (!frames.current.has(rmc)) frames.current.set(rmc, new Map())
      frames.current.get(rmc).set(+index, {
        src: bloburl,
        size: size,
      })
      updateProgress(1)
      if (++count >= total.current) {
        return
      }
      load(uid)
    }
  }

  async function initflagships(v) {
    if (!v) return
    await clearFrames()
    //    await kill()
    let list = JSON.parse(flagships.get())
    if (list.length <= 1) return fetching.set(list[0].path)
    //fetching.set(list[2].path)
  }

  function kill() {
    return new Promise(resolve => {
      //    console.log("Func", "CfgPreload", "kill")
      rfaborts.current.forEach(controller => controller.abort())
      rfblobs.current.forEach(url => URL.revokeObjectURL(url))
      rfaborts.current = []
      rfblobs.current = []
      rfloop.current = []
      resolve()
    })
  }

  useEffect(() => kill)

  //  useEffect(() => mvSubscribe(reset, kill, false), [])
  useEffect(() => mvSubscribe(preload, init), [])
  useEffect(() => mvSubscribe(preloading, initflagships), [])

  return null
})
