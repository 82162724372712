import { startTransition, useId, useLayoutEffect, useRef, useState, useEffect } from "react"
import FocusTrap from "focus-trap-react"
import { motion, useAnimationControls, useMotionValue } from "framer-motion"
import hash from "@emotion/hash"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useHeader } from "contexts/Header"
import { useNavigation } from "contexts/Navigation"
import { ctx__ONLY_FOR_SUBNAV__ as ctx, useSubNav } from "contexts/SubNav"

import getMediaQuery from "css/breakpoints"
import { normal, legend100 } from "css/text"

import useScrollLock from "hooks/useScrollLock"

import { Icon } from "components/icon/Icon"
import SubNavCard from "components/cards/SubNavCard"
import Link from "components/link/Link"
import Button from "components/button/Button"
import PopinButton from "components/popin-edito/PopinButton"
import Roller from "components/sub-nav/Roller"

import Cta from "./Cta"

export const display = `--${hash("menu-pane-display")}`

const Root = styled(motion.div)`
  position: relative;
  display: grid;
  grid-template-rows: 4.375rem 1fr;
  background: rgb(var(--light-black) / 1);
  background: linear-gradient(270deg, rgb(var(--light-black) / 1), black);

  grid-template-columns: auto min-content var(--outer-margin);
  ${getMediaQuery("m")} {
    grid-template-rows: 5rem 1fr;
  }
`

const NavContainer = styled(motion.div)`
  overflow: hidden;
  grid-area: 2/1/-1/-1;
`

const Toggle = styled.button`
  ${legend100}
  ${normal}
  height: 100%;
  width: 100%;
  grid-column: 0;
  grid-row: 1;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  background: none;
  color: white;
  gap: 0.5rem;
  cursor: pointer;
  transition: color 0.3s ease-out;

  &::after {
    content: "";
    width: auto;
    display: block;
  }

  span {
    ::after {
      background: currentColor;
      content: "";
      display: block;
      height: 1em;
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5,11L0.9,4H7h7L7.5,11z"/></svg>');
      pointer-events: none;
      width: 1em;
      transition: background-color 0.3s ease-out;
    }
  }

  &.focus-visible {
    outline-offset: -6px !important;
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--light-green));

      ::after {
        background-color: rgb(var(--light-green));
      }
    }
  }
`

const ListElement = styled(motion.li)`
  scroll-snap-align: center;

  flex: 0 0 calc(var(--grid-col-unit) * 5 + var(--grid-gap) * 4);

  ${getMediaQuery("m")} {
    flex: 0 0 calc(var(--grid-col-unit) * 4 + var(--grid-gap) * 3);
  }

  &:first-of-type {
    html:not([dir="rtl"]) & {
      scroll-snap-align: start;
    }
    scroll-margin-left: var(--outer-margin);
  }
  &:last-of-type {
    scroll-snap-align: end;
    scroll-margin-right: var(--outer-margin);
    padding-inline-end: var(--outer-margin);
  }
`

const BtnCont = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  align-items: center;

  &.wbacklink {
    grid-template-columns: min-content min-content auto;
  }

  & > button {
    padding-inline-start: var(--outer-margin);
    ${getMediaQuery("m")} {
      padding-inline-start: var(--outer-margin);
    }
  }

  &.wbacklink > button {
    padding-inline-start: 0.815rem;

    ${getMediaQuery("m")} {
      padding-inline-start: 1.875rem;
    }
  }
`

const BackLink = styled(Link)`
  grid-column: 1;
  grid-row: 1;

  white-space: nowrap !important;

  padding-inline-start: var(--outer-margin) !important;
  padding-inline-end: 0.815rem !important;

  &.focus-visible {
    outline-offset: -6px !important;
  }

  height: 100% !important;
  display: flex !important;

  &:hover {
    color: rgb(var(--light-green)) !important;
  }

  & > svg.icon {
    margin-inline: 0 var(--gap) !important;
  }

  html:not([dir="rtl"]) & svg {
    transform: scaleX(-1);
  }

  & > span.link-content {
    ${legend100}
    ${normal}
    clip-path: initial;
    height: initial;
    overflow: initial;
    position: initial;
    white-space: nowrap;
    width: initial;
    display: none;
  }

  ${getMediaQuery("m")} {
    padding-inline-end: 1.875rem !important;

    & > span.link-content {
      display: initial;
    }
  }
`

const Separator = styled.div`
  grid-row: 1;
  display: inline;
  position: relative;
  width: 1px;
  height: 12px;
  background: white;
`

export default function Pane({ toggle_label, Card = SubNavCard, cards, cta, article_simple, back_link, ariaLabel, popin_button }) {
  const console = useConsole()

  const header = useHeader()
  const id = useId()
  const scrollLock = useScrollLock()
  const navigation = useNavigation()
  const dictionary = useDictionary()
  const subnav = useSubNav()

  const [active, setActive] = useState(false)

  const headerExpansionWhenOpened = useRef()
  const rroot = useRef()
  const rcontainer = useRef()
  const listRef = useRef()

  const anim = useAnimationControls()
  const curState = useMotionValue("hide")

  const hasSeenSubnav = process.browser && JSON.parse(window.localStorage.getItem("hasSeenSubnav"))
  const hasAcceptedGdprInThePast = process.browser && JSON.parse(window.localStorage.getItem("isGdprOpen")) === null
  const isGdprOpen = process.browser && JSON.parse(window.localStorage.getItem("isGdprOpen")) === true
  const isNotGdpr = process.browser && JSON.parse(window.localStorage.getItem("isNotGdpr")) === true

  console.log("Globe CTA 2", cta)

  Object.assign(ctx, {
    open: () =>
      startTransition(() => {
        headerExpansionWhenOpened.current = header.expansion()
        header.freeze(id, headerExpansionWhenOpened.current)
        header.overlay.activate(() => ctx.close())
        scrollLock.lock(rroot.current)
        curState.set("show")
        anim.start("show")
        setActive(true)
      }),
    close: async () => {
      return Promise.race([
        new Promise(resolve => setTimeout(resolve, 1000)),
        new Promise(resolve =>
          startTransition(() => {
            scrollLock.unlock(rroot.current)
            !header.lockers.size && header.overlay.deactivate()
            setActive(false)
            curState.set("hide")
            anim.start("hide").then(() => resolve())
          })
        ),
      ]).then(() => {
        header.unfreeze(id, headerExpansionWhenOpened.current)
      })
    },
    hide: () => {
      curState.set(`stash:${curState.get()}`)
      setActive(false)
      anim.start("stash")
    },
    unhide: () => {
      if (curState.get() === "stash:show") {
        setActive(true)
        curState.set("show")
        anim.start("show")
      } else {
        setActive(false)
        curState.set("hide")
        anim.start("hide")
      }
    },
    toggle: () => {
      active ? ctx.close() : ctx.open()
    },
  })

  function onClose() {
    if (curState.get() !== "stash:show") {
      ctx.close()
    }
  }

  useEffect(() => {
    if (toggle_label) {
      subnav.label = toggle_label
    }
  }, [toggle_label])

  useLayoutEffect(() =>
    header.on(header.events.lock, () => {
      anim.start("disable")
      if (active) ctx.close()
    })
  )
  useLayoutEffect(() => header.on(header.events.willunlock, () => anim.start("hide")))
  useLayoutEffect(() => header.on(header.events.unlock, () => anim.start("hide")))

  useLayoutEffect(function lockHeaderWhenActive() {
    if (active) navigation.lock(id, () => ctx.close())
    return () => navigation.unlock(id)
  })

  useLayoutEffect(function hideContentWhenActive() {
    const elements = [document.querySelector("main"), document.querySelector("footer")]
    if (active) elements.forEach(node => node.setAttribute("aria-hidden", "true"))
    else elements.forEach(node => node?.removeAttribute("aria-hidden"))
  })

  const animateOnFirstVisit = () => {
    // ctx.open()
    setTimeout(() => {
      ctx.close()
      window.localStorage.setItem("hasSeenSubnav", JSON.stringify(true))
    }, 1000)
  }

  useEffect(() => {
    if (isGdprOpen) return
    if (!hasAcceptedGdprInThePast && hasSeenSubnav === null) animateOnFirstVisit()
    if (isNotGdpr && hasSeenSubnav === null) animateOnFirstVisit()
  }, [])

  useEffect(() => {
    window.addEventListener("gdprOpen", () => {
      ctx.open()
      setActive(false)
    })

    return () => window.removeEventListener("gdprOpen", () => {})
  }, [])

  useEffect(() => {
    window.addEventListener("gdprClosed", animateOnFirstVisit)
    return () => window.removeEventListener("gdprClosed", animateOnFirstVisit)
  }, [])

  const contVariants = {
    hide: { y: 0, visibility: "visible" },
    show: { y: 0, visibility: "visible" },
    stash: { y: "-100%", visibility: "hidden", transition: { visibility: { delay: 0.2 } } },
    disable: { visibility: "hidden", transition: { visibility: { delay: 0.2 } } },
  }

  const variants = {
    hide: { height: 0, transitionEnd: { visibility: "hidden" } },
    show: { height: "auto", visibility: "visible", transition: { staggerChildren: 0.02, delayChildren: 0.3 } },
  }

  const liVariants = {
    hide: { opacity: 0, transition: { duration: 0.35 } },
    show: { opacity: 1, transition: { duration: 0.35 } },
  }

  const toggleVariants = {
    hide: { rotate: 0, transition: { duration: 0.35, ease: "linear" } },
    show: { rotate: 180, transition: { duration: 0.35, ease: "linear" } },
  }

  return (
    <FocusTrap active={active} containerElements={[rroot.current]} focusTrapOptions={{ allowOutsideClick: true, onDeactivate: onClose }}>
      <Root
        ref={rroot}
        className='dark-theme'
        aria-modal={active}
        aria-label={ariaLabel ? ariaLabel : toggle_label}
        role='dialog'
        animate={anim}
        variants={contVariants}
        initial={(isNotGdpr || !hasAcceptedGdprInThePast) && hasSeenSubnav === null ? "show" : "hide"}
      >
        <BtnCont className={back_link?.href ? "wbacklink" : ""}>
          {back_link?.href ? (
            <>
              <BackLink {...back_link} icon='none' className='inline'>
                <Icon type='arrowBack' className='icon' />
                <span className='link-content'>{back_link.label}</span>
              </BackLink>
              <Separator />
            </>
          ) : null}
          <Toggle
            onClick={ctx.toggle}
            aria-label={`${toggle_label} - ${!active ? dictionary.ariaLabelOpenNavigation() : dictionary.ariaLabelCloseNavigation()}`}
            aria-expanded={active}
            aria-controls='sub-navigation'
          >
            {toggle_label}
            <motion.span variants={toggleVariants} />
          </Toggle>
        </BtnCont>
        <NavContainer ref={rcontainer} variants={variants}>
          <Roller ref={listRef} id='sub-navigation' active={active}>
            {!!cards?.length
              ? cards.map((card, i, a) => (
                  <ListElement key={`card-${i}`} variants={liVariants}>
                    <Card {...card} index={i} meta={{ index: i + 1, reverseIndex: a.length - i }} />
                  </ListElement>
                ))
              : null}
          </Roller>
        </NavContainer>
        {cta.length >= 1 ? <Cta cta={cta} /> : null}
      </Root>
    </FocusTrap>
  )
}
