import styled from "@emotion/styled"
import { motion, useTransform, transform } from "framer-motion"

import getMediaQuery from "css/breakpoints"
import { grid } from "css/grid"
import { body50 } from "css/text"
import { Chapo as ChapoClass } from "css/classnames"
import { contrastMode } from "css/a11y"

import { useViewport } from "contexts/Viewport"

import Chapo from "components/text/Chapo"

import { modularGradient } from "components/articles/common"

const Container = styled(motion.div)`
  position: relative;

  ${grid}
  grid-template-rows: min-content min-content;
  grid-column: main;
  grid-row: skyline;

  ${getMediaQuery("m")} {
    grid-template-rows: min-content;
  }

  & .${ChapoClass} {
    position: relative;
    grid-column: col 1 / span 5;
    grid-row: 1;
    margin-block-end: 1.25rem;

    ${getMediaQuery("m")} {
      margin-block-end: 2.5rem;
      grid-column: col 1 / span 9;
    }
    ${getMediaQuery("l")} {
      margin-block-end: 0;
      grid-column: col 1 / span 5;
    }

    ${contrastMode}
  }

  @media (prefers-contrast: no-preference) {
    ${modularGradient}
  }
`

const ChapoCS = styled(Chapo)``

const Paragraph = styled.p`
  position: relative;
  ${body50}
  grid-column: col 2 / span 5;
  grid-row: 2;

  ${getMediaQuery("m")} {
    grid-column: col 4 / span 9;
  }
  ${getMediaQuery("l")} {
    grid-row: 1;
    grid-column: col 7 / span 5;
  }
  ${contrastMode}
  margin-block-end: 30px;
`

export default function Skyline({ chapo, paragraph, prog, doubled, isHeading }) {
  const { height } = useViewport()

  const opacity = useTransform([prog, height], ([p, h]) => transform(p, doubled ? [h * 0.9, h * 1.1] : [h * 0.4, h * 0.6], [0, 1]))

  return (
    <Container className='dark-theme' style={{ opacity }}>
      {chapo ? <Chapo doubled={doubled} isHeading={isHeading} className={ChapoClass} text={chapo} /> : null}
      {paragraph ? <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} /> : null}
    </Container>
  )
}
