import { ALWAYSCOMPLETE } from "./constants"
import { useCallback, useLayoutEffect, useRef } from "react"

export function buildWorker(worker) {
  const code = worker.toString()
  const blob = new Blob([`(${code})()`])
  return new Worker(URL.createObjectURL(blob))
}

export function tracktime(fname, start) {
  return null
  if (!start) {
    console.warn(`~~ ${fname} started`)
    return Date.now()
  }
  console.warn(`~~ ${fname} ended in ${Date.now() - start}ms`)
  return Date.now()
}

export function mvSubscribe(mv, changeFunc, run = true) {
  const unsub = mv.onChange(v => {
    changeFunc(v)
  })
  if (run) {
    changeFunc(mv.get())
  }
  return unsub
}

export function cssApply(el, props, straight) {
  if (!process.browser) return null
  //  myconsole("cssApply", el, props)
  if (!el || !props) return null
  props.map(line => {
    const prop = line[0]
    el.style[prop] = straight && prop === "transition" ? "none" : line[1]
  })
}

export function requestIntervalNew(update, duration = 0) {
  if (!process.browser) return
  let start = Date.now()
  let stop = false
  let past = 0

  const intervalFunc = () => {
    if (stop) return
    past = Date.now() - start
    if (past < duration) {
      window.requestAnimationFrame(intervalFunc)
    } else {
      past = duration
    }
    update(past)
  }
  //  window.requestAnimationFrame(intervalFunc)
  intervalFunc()

  return () => ({
    stop: () => (stop = true),
  })
}

export function requestInterval(update, duration = 0) {
  if (!process.browser) return
  let start = Date.now()
  let stop = false
  let past = 0

  const intervalFunc = () => {
    if (stop) return
    past = Date.now() - start
    if (past < duration) {
      window.requestAnimationFrame(intervalFunc)
    } else {
      past = duration
    }
    update(past)
  }
  //  window.requestAnimationFrame(intervalFunc)
  intervalFunc()

  return () => ({
    stop: () => (stop = true),
  })
}

export const rAF = process.browser ? window.requestAnimationFrame : null

export function cssSwap(el, cn = "wv_reveal", fn = null) {
  if (!process.browser || !el) return
  if (cn) el.classList.remove(cn)
  rafDelay(() => {
    if (typeof fn === "function") fn()
    if (!cn) return
    rafDelay(() => {
      el.classList.add(cn)
    })
  })
}

const VARIANTS = {
  swap: {
    in: {
      opacity: 0,
      transition: {
        duration: 0,
        //        ease: [0.32, 0.94, 0.6, 1],
      },
    },
    out: {
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.61, 1, 0.88, 1],
      },
    },
  },
}

// inout, in@out, in, in@, out, @out
export function fmSwap(control, fn = null, swapmode = "in@out") {
  if (!process.browser) return
  control.stop()
  if (swapmode.indexOf("in") < 0) swap()
  else control.start(VARIANTS.swap.in).then(swap)

  function swap() {
    if (swapmode.indexOf("@") >= 0 && typeof fn === "function") fn()
    if (swapmode.indexOf("out") >= 0) control.start(VARIANTS.swap.out)
  }
}

export function fmDisplay(control, state) {
  if (!process.browser) return
  control.start(VARIANTS.display(state))
}

export function rafDelay(fn = null) {
  if (!process.browser || !fn) return
  window.requestAnimationFrame(function () {
    window.requestAnimationFrame(function () {
      fn()
    })
  })
}

export function rafSwap(el, cn = "", fn = null, duration = 200) {
  if (!process.browser) return

  el.classList.remove(cn)
  //  console.log("rafSwap begin")
  window.requestAnimationFrame(() => {
    if (typeof fn === "function") fn()
    //    console.log("rafSwap process")
    requestInterval(update, duration, true)
  })

  function update() {
    //    window.requestAnimationFrame(() => {
    el.classList.add(cn)
    //      console.log("rafSwap end")
    //    })
  }
}

export function getIndexListFromRange(range, dir = 0, max = 0, source = []) {
  //  console.log(">>>> getIndexListFromRange", range, dir, max)
  let [start, end] = range.split(",")
  end ??= +start
  let i = +start
  if (+start === +end) {
    if (!~source.indexOf(i)) source.push(i)
    return source
    //    mp.set(start, 0)
    //    return mp
  }
  while (i !== +end) {
    if (!~source.indexOf(i)) source.push(i)
    i += dir
    if (dir > 0 && i >= max) i = 0
    if (dir < 0 && i < 0) i = max - 1
  }
  if (!~source.indexOf(i)) source.push(i)
  return source
}

export function keyFrameToMap(k) {
  if (!k.length) return new Map()
  //  console.log(">>>> keyFrameToMap", k)
  return k
    .split(";")
    .filter(v => !!v.length)
    .reduce((a, v) => {
      let [i, x, y, z, r] = v.split(",")
      let values = new Map()
      if (!!x.length) values.set("x", +x)
      if (!!y.length) values.set("y", +y)
      if (!!z.length) values.set("z", +z)
      if (!!r.length) values.set("r", +r)
      a.set(+i, values)
      return a
    }, new Map())
}

export function mapToKeyFrame(m) {
  if (!m || m.size === 0) return ""
  return [...m.entries()]
    .reduce((a, [k, v]) => {
      a.push([k, v.has("x") ? v.get("x") : "", v.has("y") ? v.get("y") : "", v.has("z") ? v.get("z") : "", v.has("r") ? v.get("r") : ""].join(","))
      return a
    }, [])
    .join(";")
}

export function renewObject(v) {
  return JSON.parse(JSON.stringify(v))
}

export function renewMap(v) {
  return new Map(renewObject(Array.from(v)))
}

export function sortValues(m) {
  let keys = Array.from(m.keys())
  keys.sort(sortKeys)
  let values = keys.map(k => m.get(k))
  return [keys, values]
}

export function sortKeys(a, b) {
  return a - b
}

export function clampIndex(v, min, max, dir) {
  v += dir
  if (dir > 0 && v >= max) v = 0
  if (dir < 0 && v < min) v = max - 1
  return v
}

export function uniqueValues(a) {
  return [...new Set(a)]
}

const filterChar = "Configv7"

const logColorsByHook = {
  Main: "red",
  Callback: "magenta",
  onChange: "grey",
  useEffect: "green",
  useLayoutEffect: "green",
  CustomHook: "blue",
  Listener: "gold",
  Func: "olive",
}

export const logmine = (color, ...args) => {
  return true
  const colortype = logColorsByHook[color]
  const colorcode = colortype || color
  const display = []
  const hide = []
  //  console.log.apply(null, [colorcode, [filterChar, ...args]])
  let ready = display.reduce((a, item) => {
    return a || !!~args.indexOf(item) || color === item
  }, !display.length)
  ready = hide.reduce((a, item) => {
    return a && color !== item
  }, ready)
  if (ready) {
    //    console.log.apply(null, [["%c", filterChar, ...args].join(" "), `background: ${colorcode}; color: white;`])
    const last = args.pop()
    if (typeof last === "object") console.log(last)
  }
}

export const useMediaLoader = (ready, uid, ref, list, video = false, domdelay = 40, displaydelay = 20) => {
  //  logmine("CustomHook", "useImageLoader", list)
  //  logmine("CustomHook", "useImageLoader", ref)
  const readyRef = useRef([])
  const tmRef = useRef(null)

  const end = useCallback(() => {
    //    logmine("Callback", "useMediaLoader", "end")
    clearTimeout(tmRef.current)
    tmRef.current = setTimeout(() => {
      ready.set(true)
    }, displaydelay)
  }, [ready, displaydelay])

  const onload = useCallback(
    e => {
      //    if (!readyRef.current.length) return
      const i = e.target.getAttribute("data-loadindex")
      //      logmine("CustomHook", uid, "useMediaLoader", "onload", i)
      //      logmine("CustomHook", uid, "useMediaLoader", "onload", e.target.currentSrc)
      const index = readyRef.current.indexOf(i)
      if (!!~index) readyRef.current.splice(index, 1)
      e.target.removeAttribute("data-loadindex")
      //      logmine("CustomHook", uid, "useMediaLoader", "onload readyRef.current", readyRef.current)
      if (!readyRef.current.length) end()
    },
    [uid, end]
  )

  useLayoutEffect(() => {
    //    logmine("useLayoutEffect", "useMediaLoader", "list", list)
    if (!process.browser || !ref) return
    clearTimeout(tmRef.current)
    const { current: root } = ref
    if (!root) return
    ready.set(false)
    if (!list || !list.length) return

    rafDelay(() => {
      //      logmine("useLayoutEffect", "useMediaLoader", "play list", list)
      readyRef.current = [...root.querySelectorAll("img")]
        .filter(el => (ALWAYSCOMPLETE && !el.width) || !el.complete)
        .concat(video ? [...root.querySelectorAll("video")] : [])
        .map((el, i) => {
          el.setAttribute("data-loadindex", `${uid}_${i}`)
          return el
        })
        .map(el => el.getAttribute("data-loadindex"))

      if (!readyRef.current.length) {
        end()
      } else {
        root.addEventListener("load", onload, true)
        root.addEventListener("error", onload, true)
        if (video) root.addEventListener("canplaythrough", onload, true)
      }
    })

    return () => {
      root.removeEventListener("load", onload, true)
      root.removeEventListener("error", onload, true)
      if (video) root.removeEventListener("canplaythrough", onload, true)
      //      logmine("useLayoutEffect", "useMediaLoader", "events cleared")
    }
  }, [list])
}

export const setSource = (variation, v, catalogYear = "2023") => {
  let url = ""
  let param = ""
  switch (variation) {
    case "dial":
      url = "configurator/raw-dial-with-shadow"
      break
    case "shadow":
      param = "&ch1=1380"
      url = "upright-c-shadow"
      break
    case "watch":
      url = "upright-c"
      break
    default:
      throw new Error("unknown variation")
  }
  //&ch1=1380&imwidth=800
  return `/v7/dam/${catalogYear}/${url}/${v}.png?impolicy=v7-main-configurator${param}&imwidth=800`
}

export const setPicture = src => {
  return {
    sources: [{ src: src, width: "800px", height: "1180px" }],
  }
}
