import { useRef, useState } from "react"
import styled from "@emotion/styled"

import { useBreadcrumbs, useBreadcrumbItem } from "@react-aria/breadcrumbs"

import { Provider } from "contexts/Breadcrumb"
import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"
import { useDictionary } from "contexts/Dictionary"

import { legend80 } from "css/text"
import { useLocale } from "contexts/Locale"

import { I18nProvider } from "react-aria"

const Root = styled.nav`
  padding-block: 2.5rem 1rem;
  padding-inline: var(--outer-margin);
  position: relative;
  z-index: 1;
  background-color: white;

  ol {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    ${legend80}
  }

  li:last-of-type span {
    color: rgb(var(--rolex-green));
  }

  a {
    color: inherit;
    display: inline-flex;
    text-decoration: none;
    transition: color 0.3s;

    @media (hover: hover) {
      :hover {
        color: rgb(var(--rolex-green));
      }
    }

    * {
      pointer-events: none;
    }
  }

  [aria-hidden] {
    font-size: 0.75em;
    font-weight: 400;
    margin-inline: 0.25rem;
  }
`

const Item = styled.a()

function BreadcrumbItem(props) {
  const rlink = useRef()
  const navigation = useNavigation()
  const { itemProps } = useBreadcrumbItem(props, rlink)
  const { href, index, isCurrent, title, rel } = props

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.target.getAttribute("href"))
  }

  return (
    <li itemProp='itemListElement' itemScope itemType='https://schema.org/ListItem'>
      {!isCurrent ? (
        <>
          <Item {...itemProps} href={navigation.localize(href)} itemProp='item' onClick={navigate} rel={rel}>
            <span itemProp='name'>{title}</span>
          </Item>
          <span aria-hidden='true'>{" /\u00a0"}</span>
        </>
      ) : (
        <span aria-current='page' itemProp='name'>
          {title}
        </span>
      )}
      <meta content={index + 1} itemProp='position' />
    </li>
  )
}

export function Breadcrumb(props) {
  const console = useConsole()
  const dictionary = useDictionary()
  const { navProps } = useBreadcrumbs(props)
  const [parts, setParts] = useState(props.items ?? null)

  const ctx = {
    set(parts) {
      setParts(parts)
    },
  }
  const { slug } = props
  console.verbose("Breadcrumb(%o)", props.items)

  // Function to modify breadcrumb items based on slug
  const modelpage = (bcitems, slug) => {
    const newbcitems = [...bcitems]
    const lastItem = newbcitems[newbcitems.length - 1]

    const replacePlaceholders = (href, value) => href.replace(/%[^%]+%/, value)
    const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

    const deductedParts = slug?.split("/")
    const title = deductedParts.length === 4 ? deductedParts[3] : ""
    const href = replacePlaceholders(lastItem["href"], title)

    if (lastItem["title"] === "%rmc%" || lastItem["title"] === "%name%") {
      newbcitems.pop()

      const words = title.split("-")

      let dicoKey = ""
      words.map((w, i) => {
        if (i === 0) {
          dicoKey += w
        } else {
          dicoKey += w.charAt(0).toUpperCase() + w.slice(1)
        }
      })

      const newTitle = lastItem["title"] === "%rmc%" ? title : capitalize(dictionary[dicoKey || "all"]())
      newbcitems.push({ title: newTitle, href: href })
    }

    return newbcitems
  }

  return (
    <Provider value={ctx}>
      {parts?.length ? (
        <Root {...navProps}>
          <ol itemScope itemType='https://schema.org/BreadcrumbList'>
            <>
              <BreadcrumbItem href='/' title={dictionary.home()} rel='home' index={0} />
              {modelpage(parts, slug).map((item, index) => (
                <BreadcrumbItem {...item} index={index + 1} isCurrent={index === parts.length - 1} key={index + "/"} />
              ))}
            </>
          </ol>
        </Root>
      ) : null}
    </Provider>
  )
}

export default function BootstrapBreadcrumb(props) {
  // I18nProvider allows to use the localized strings from react-aria

  const locale = useLocale()

  return (
    <I18nProvider locale={locale.current.codes.www}>
      <Breadcrumb {...props}></Breadcrumb>
    </I18nProvider>
  )
}
