import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { useLocale } from "contexts/Locale"
import { useMotionValue, useTransform } from "framer-motion"
import { useContext, useEffect, useLayoutEffect, useRef } from "react"
import { CFGV7 } from "./context"
import { mvSubscribe } from "./utils"

const { PARAMS, PATHS, STEPS, STEPS_LENGTH, FLAGSHIPS, SPLIT } = CFGV7

export function FrameAnimationProvider({ children, Ctx, items, bkgmode, clear }) {
  //  console.log("cfgv7 props", items)
  const frames = useRef(new Map())
  const catalog = useRef(null)
  const precatalog = useRef(null)
  const canvasctx = useRef(null)
  const fetching = useMotionValue(null)
  const preload = useMotionValue(null)
  const progress = useMotionValue(0)
  const step = useMotionValue(0)
  const reset = useMotionValue(0)
  const step2step = useRef(0)
  const locale = useLocale()
  let waStarted = useRef(false).current

  const startrmc = useMotionValue(null)
  const flagships = useMotionValue(items)
  const split = !!items && JSON.parse(items).length > 1
  const family = useMotionValue(null)

  const restart = () => reset.set(Date.now())

  const model = useMotionValue(null)
  const getModel = (rmc = model.get()) => {
    return !!catalog.current && !!rmc ? catalog.current.find(v => v.rmc === rmc) : null
  }

  const total = useRef(0)
  const loaded = useRef(0)
  const preloading = useMotionValue(0)
  const launching = useMotionValue(0)
  const pklist = useMotionValue(null)

  const modellist = useTransform([step, model], ([step, model]) => {
    if (step === 0 || !model) return null
    let stepname = STEPS[step]
    return getModel(model)?.[`variations_${stepname}`]
  })

  const lastofrange = useMotionValue(false)

  const updateProgress = (q = 1) => {
    loaded.current += q
    progress.set(total.current ? loaded.current / total.current : 0)
    //    console.log("---", loaded.current, total.current)
    return true
  }

  const swapping = useMotionValue(0)
  const rolling = useMotionValue(null)
  const orientation = useMotionValue(0)
  const fromlaststep = useMotionValue(null)
  const restore = useMotionValue(0)
  const beforelaststep = useMotionValue(0)

  const getParams = v => {
    v ??= step2step.current
    //    mylog("Func", "FrameAnimationProvider", "getParams", v)
    let dir = PATHS.get(fcode)?.get(v) || 0
    //    console.log(dir)
    return PARAMS.get(v)[dir]
  }

  let fcode = useRef(null).current
  const rfcanvas = useRef(false)

  const resized = useMotionValue(null)
  const mobile = useMotionValue(0)

  const rfwh = useRef({ offsetWidth: 0, offsetHidth: 0 })
  const amget = (o, i) => o?.[i]

  const updateStep = (v = -1) => {
    let launch = +(v == 0)
    if (v > 0 && v !== step.get()) {
      fromlaststep.set(model.get())
      step2step.current = `${step.get()},${v}`
      swapping.set(1)
      if (step.get() === 0) waStarted = true
      step.set(v)
    } else {
      let swap = false
      if (launch || (v < 0 && v > -1)) {
        step.set(-10 * v)
        swap = true
      }
      step2step.current = step.get()
      swapping.set(swap)
      restore.set(0)
      if (!swap && preload.get()) wa()
      waStarted = false
    }
  }

  function step2wa() {
    let sindex = step.get()
    switch (sindex) {
      case 2:
        return "size"
      default:
        return `${STEPS[sindex].replace("flagship", "home")}`
    }
  }

  function wa(data = {}) {
    if (!process.browser) return
    const m = getModel()
    if (!m) return

    let split = window.location.pathname.slice(1).split("/")
    if (locale.current.codes.www !== locale.defaultLocale.codes.www) split.splice(0, 1)
    let [siteSection, siteSubSection] = split
    siteSubSection = siteSubSection || ""

    const eventName = ["configuratorStepViews"]

    if (waStarted /* && step2wa() == "home"*/) eventName.push("configuratorStarted"/*, "viewVariationsCTA"*/)
    if (step.get() === 6) eventName.push("configuratorLastStep")

    waTriggerEvent({
      eventName: eventName,
      eventType: "Page Load",
      pageName: `watches:${m.familyCode}:configure:${m.rmc}:step:${step2wa()}`,
      configurator: {
        step: step2wa(),
      },
      products: [
        {
          productCategory: m.familyCode,
          productID: m.rmc,
          productQuantity: "1",
          productModel: m.nameCode,
        },
      ],
      ...data,
    })
  }

  function setBeforeLastStep() {
    const m = getModel()
    if (!m) return
    let v = step.get()
    let nstep = m.next[v]
    if (nstep < 0 && v !== STEPS_LENGTH) nstep = STEPS_LENGTH
    beforelaststep.set(+(nstep === STEPS_LENGTH))
  }

  function lastStep() {
    return step.get() >= STEPS_LENGTH
  }

  useEffect(() => {
    return restart
  }, [])

  useEffect(() => mvSubscribe(step, setBeforeLastStep, false), [])
  useEffect(() => mvSubscribe(model, setBeforeLastStep, false), [])

  function onStartrmc(v) {
    if (!v || !getModel(v)) return (fcode = null)
    fcode = getModel(v).familyCode
  }

  useEffect(() => mvSubscribe(startrmc, onStartrmc), [])

  function clearFrames(savekeys = [], saveindex = -1) {
    return new Promise(resolve => {
      ;[...frames.current.entries()].map(([k, v]) => {
        let included = savekeys.includes(k)
        v.map(({ src }, kk) => {
          let keep = included && saveindex === kk
          if (!keep && typeof src === "string" && src.indexOf("blob:") === 0) {
            URL.revokeObjectURL(src)
            //            console.log("clearFrames revoked", src)
          }
          if (!keep) v[kk] = null
        })
        if (!included) frames.current.delete(k)
      })

      resolve()
    })
  }

  async function kill() {
    //    mylog("Func", "FrameAnimationProvider", "kill")
    await clearFrames()
    model.set(null)
    progress.set(0)
    total.current = 0
    loaded.current = 0
    step2step.current = 0
    step.set(0)
    launching.set(0)
    swapping.set(0)
    clear()
  }
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  function syncHeight() {
    //    const modal = document.querySelector(".cfgv7Modal")
    const { offsetWidth, offsetHeight } = document.querySelector(".cfgv7Modal")
    //    rfwh.current = { offsetWidth, offsetHeight }
    orientation.set(+(offsetWidth < offsetHeight))
    mobile.set(+(offsetWidth < 480))
    resized.set({ offsetWidth, offsetHeight })
    //    document.documentElement.style.setProperty("--cfg-window-inner-height", `${window.innerHeight}px`)
  }

  useLayoutEffect(() => {
    if (!process.browser) return
    window.addEventListener("resize", syncHeight, true)
    window.addEventListener("orientationchange", syncHeight, true)
    syncHeight()

    return () => {
      window.removeEventListener("resize", syncHeight, true)
      window.removeEventListener("orientationchange", syncHeight, true)
    }
  }, [])

  useEffect(() => kill)

  return (
    <Ctx.Provider
      value={{
        getParams,
        frames,
        catalog,
        getModel,
        total,
        loaded,
        updateProgress,
        fetching,
        progress,
        step,
        beforelaststep,
        model,
        updateStep,
        swapping,
        family,
        startrmc,
        modellist,
        //        split,
        reset,
        restart,
        lastofrange,
        rolling,
        launching,
        preload,
        canvasctx,
        rfwh,
        resized,
        rfcanvas,
        //        params,
        orientation,
        preloading,
        lastStep,
        bkgmode,
        flagships,
        precatalog,
        clearFrames,
        mobile,
        split,
        wa,
        amget,
        fromlaststep,
        restore,
        pklist,
      }}
    >
      {children}
    </Ctx.Provider>
  )
}

export function useFrameAnimation(ctx) {
  return useContext(ctx)
}
