import { useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import EventTarget from "@ungap/event-target"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"
import { useDictionary } from "contexts/Dictionary"
import { useSubNav } from "contexts/SubNav"
import {
  cssVarExpansion,
  cssVarFocusIn,
  cssVarHeight,
  cssVarTransitionDelay,
  cssVarTransitionDuration,
  cssVarFreeze,
  cssVarHideSubNav,
  ctx__ONLY_FOR_HEADER__ as ctx,
} from "contexts/Header"
import { useMenu } from "contexts/Menu"

import useConstant from "hooks/useConstant"
import useScrollLock from "hooks/useScrollLock"

import exposeEventTarget from "utils/exposeEventTarget"

import Nav from "components/header/Nav"
import Overlay from "components/header/Overlay"
import Skip from "components/header/Skip"
import Menu from "components/menu/Pane"
import Search from "components/search/Pane"
import { statesLabel } from "components/menu/Pane"

const Root = styled.header`
  ${cssVarFocusIn}: 0;
  & nav:nth-of-child(1):focus-within {
    ${cssVarFocusIn}: 1;
  }

  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--z-top, 1000);
  transform: translate3d(0, calc((var(${cssVarHeight}) * -1) * (1 - var(${cssVarFreeze}, max(var(${cssVarExpansion}, 1), var(${cssVarFocusIn}, 0))))), 0);
  transition: transform var(${cssVarTransitionDuration}, 0ms);

  /* background: linear-gradient(90deg, #0b3e27, #197149); */

  & > div:first-of-type > * {
    transition: transform 0.3s linear;
  }

  &.hidefrommenu > div:first-of-type > * {
    transform: translate3d(-100vw, 0, 0);
  }

  & > nav:last-of-type {
    position: absolute;
    width: 100%;
    z-index: 0;
  }
`

const SubNav = styled.nav`
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`

const events = {
  update: "update",
  lock: "lock",
  unlock: "unlock",
  willunlock: "willunlock",
}

export default function Header({ id, children, sections, links_section, watches, language_title, nofooter, ...rest }) {
  const console = useConsole()

  const scrollLock = useScrollLock()
  const navigation = useNavigation()

  const rroot = useRef()
  const et = useConstant(() => new EventTarget())
  const expansion = useConstant(() => ({ scroll: { y: 0 }, t: 1 }))
  const lockers = useConstant(() => new Map())
  const freezers = useConstant(() => new Map())
  const dictionary = useDictionary()
  const menu = useMenu()
  const subnavContext = useSubNav()

  Object.assign(ctx, {
    /** context as a kind of singleton, no Provider */
    cssVars: {
      expansion: cssVarExpansion,
      focusin: cssVarFocusIn,
      height: cssVarHeight,
      transitionDelay: cssVarTransitionDelay,
    },
    ref: rroot,
    events,
    ...exposeEventTarget(et),
    get lockers() {
      return lockers
    },
    expansion: () => expansion.t,
    lock: (what, unlockHandler = Function.prototype, details = {}) => {
      const from = lockers.size
      lockers.set(what, unlockHandler)
      if (lockers.size > from) {
        const event = new Event(events.lock)
        Object.assign(event, details)
        et.dispatchEvent(event)
      }
    },
    unlock: (what, details = {}) => {
      lockers.delete(what)
      if (lockers.size === 0) {
        const event = new Event(events.unlock)
        Object.assign(event, details)
        et.dispatchEvent(event)
      }
    },
    forceUnlock: async (details = {}) => {
      const event = new Event(events.willunlock)
      Object.assign(event, details)
      et.dispatchEvent(event)

      return Promise.all([...lockers.values()].map(handler => handler?.()))
        .catch(err => console.error(err))
        .then(() => {
          lockers.clear()
          const event = new Event(events.unlock)
          Object.assign(event, details)
          et.dispatchEvent(event)
        })
    },
    freeze: (id, expandedOrCollapsed, { subNav = false } = {}) => {
      expandedOrCollapsed ??= expansion.t
      const at = +!!expandedOrCollapsed

      if (freezers.has(id)) freezers.delete(id)
      freezers.set(id, at)
      rroot.current.style.setProperty(cssVarFreeze, at)
      if (subNav) rroot.current.style.setProperty(cssVarHideSubNav, 1)

      return () => ctx.unfreeze(id, at)
    },
    unfreeze: (id, expandedOrCollapsed) => {
      if (!freezers.has(id)) return
      freezers.delete(id)

      if (freezers.size) {
        rroot.current.style.setProperty(cssVarFreeze, [...freezers][freezers.size - 1])
      } else {
        if (typeof expandedOrCollapsed === "number") {
          expansion.scroll.y = global.scrollY
          expansion.t = +!!expandedOrCollapsed
          const event = new Event(ctx.events.update)
          Object.assign(event, expansion)
          et.dispatchEvent(event)
        }

        rroot.current.style.removeProperty(cssVarFreeze)
        rroot.current.style.removeProperty(cssVarHideSubNav)
      }
    },
    forceUnfreeze: () => {
      freezers.clear()
      rroot.current.style.removeProperty(cssVarFreeze)
      rroot.current.style.removeProperty(cssVarHideSubNav)
    },
  })

  function onMenuStateChange(state) {
    if (state === statesLabel.rollergrid) {
      rroot.current.classList.add("hidefrommenu")
      // anim.start("showgrid")
    } else if (state === statesLabel.closegrid) {
      rroot.current.classList.remove("hidefrommenu")
      // anim.start("closegrid")
    }
  }
  useLayoutEffect(() => menu.currentState.onChange(onMenuStateChange))

  useLayoutEffect(function scrollLockEffects() {
    const root = rroot.current

    if (lockers.size) scrollLock.lock(root)
    else scrollLock.unlock(root)

    const onlock = () => {
      ctx.freeze(id, 1)
      scrollLock.lock(root)
    }
    const onunlock = () => {
      ctx.unfreeze(id, 1)
      scrollLock.unlock(root)
      scrollLock.forceUnlock()
    }

    et.addEventListener(events.lock, onlock)
    et.addEventListener(events.unlock, onunlock)

    navigation.lock(id, () => ctx.forceUnlock())
    return () => {
      navigation.unlock(id)
      scrollLock.unlock(root)
      et.removeEventListener(events.lock, onlock)
      et.removeEventListener(events.unlock, onunlock)
    }
  })

  useLayoutEffect(function scrollEffects() {
    let clientHeight = 0
    let scrollY = 0
    const root = rroot.current
    // const nav = root.querySelector("nav")
    const nav = root.querySelector("div.header-container")

    let debounceRAF
    const onresize = () => {
      cancelAnimationFrame(debounceRAF)
      debounceRAF = requestAnimationFrame(() => {
        clientHeight = nav.clientHeight
        update()
      })
    }

    const update = e => {
      scrollY = global.scrollY
      // const delta = scrollY - expansion.scroll.y
      let shouldUpdate = false

      if (!freezers.size) {
        if (scrollY <= clientHeight) {
          shouldUpdate = !expansion.t
          if (shouldUpdate) expansion.t = 1
        } else {
          const target = +!(expansion.scroll.y < scrollY)
          shouldUpdate = Math.abs(scrollY - expansion.scroll.y) > 1 && expansion.t !== target
          if (shouldUpdate) expansion.t = target
        }

        if (shouldUpdate) {
          const event = new Event(ctx.events.update)
          Object.assign(event, expansion)
          et.dispatchEvent(event)
        }
      }

      expansion.scroll.y = scrollY
    }

    global.addEventListener("resize", onresize, { passive: true })
    global.addEventListener("scroll", update, { passive: true })
    onresize()
    return () => {
      global.removeEventListener("resize", onresize, { passive: true })
      global.removeEventListener("scroll", update, { passive: true })
    }
  })

  useLayoutEffect(function updateStyles() {
    return ctx.on(ctx.events.update, ({ t }) => {
      document.documentElement.style.setProperty(cssVarExpansion, t)
    })
  })

  useLayoutEffect(function forceExpansionOnFirstRender() {
    const event = new Event(ctx.events.update)
    Object.assign(event, expansion)
    et.dispatchEvent(event)
  }, [])

  console.verbose("Header(%o)", { sections, lockers })
  return (
    <>
      <Helmet>
        <style>{`:root{
          ${cssVarHeight}: clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem);
          ${cssVarTransitionDuration}: 300ms; 
          ${cssVarTransitionDelay}: 0ms; 
          ${cssVarExpansion}: 1;
          }
          `}</style>
      </Helmet>
      <Skip nofooter={nofooter} />
      <Root
        ref={rroot}
        role='banner'
        className='header-nav'
        style={{
          [cssVarHideSubNav]: 0,
        }}
      >
        <Nav
          style={{
            [cssVarHeight]: "clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem)",
          }}
        />
        <Menu sections={sections} links_section={links_section} watches={watches} language_title={language_title} />
        <Search />
        {children.length !== 0 && (
          <SubNav className='header-subnav' aria-label={`${subnavContext.label || ""} ${dictionary.subnavigation()}`}>
            {children}
          </SubNav>
        )}
      </Root>
      <Overlay />
    </>
  )
}
