import { startTransition, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import hash from "@emotion/hash"
import { useDictionary } from "contexts/Dictionary"
import { useConsole } from "contexts/Console"
import { useSearch as useSearchPanel } from "contexts/Search"
import { useSearch } from "./context"
import { useHeader } from "contexts/Header"
import { legend100, normal, visuallyHidden } from "css/text"
import { ctx__ONLY_FOR_SEARCH__ as ctx } from "contexts/Search"
import getMediaQuery from "css/breakpoints"
import SearchIcon from "./SearchIcon"
import useSSR from "hooks/useSSR"
import { useNavigation } from "contexts/Navigation"

const cssOn = `css-${hash("search:toggle:on")}`
const cssOff = `css-${hash("search:toggle:off")}`
const cssAlt = `css-${hash("search:toggle:alt")}`

const Root = styled.div`
  display: grid;
`

const Button = styled.button`
  all: unset;
  cursor: pointer;
  user-select: none;
  grid-area: 1/1;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(15px, auto) 1fr;
  padding: var(--touch-padding);
  position: relative;
  left: var(--touch-padding);
  color: rgb(var(--pure-white));

  ${getMediaQuery("xxl", { min: true })} {
    grid-gap: 1ex;
  }

  [dir="rtl"] & {
    left: calc(-1 * var(--touch-padding));
    right: auto;
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green));
      fill: rgb(var(--ocean-green));
    }
  }

  & > span {
    ${legend100}
    ${normal}

    /* ${getMediaQuery("xxl", { max: true })} {
      ${visuallyHidden}
    } */

    & > span {
      font-size: 0%;
      opacity: 0;

      ${getMediaQuery("xxl", { min: true })} {
        font-size: min(var(--m, 0) * 100%, 100%);
        opacity: min(var(--m, 0), 1);
      }

      transition: font-size 100ms, opacity 300ms;
    }
  }

  transition: opacity 300ms, color 300ms, fill 300ms;
  will-change: opacity, color, fill;

  & > svg {
    fill: currentColor;
    [dir="rtl"] & {
      transform: scaleX(-1);
    }
  }

  .${cssAlt} & {
    left: calc(-1 * var(--touch-padding));

    [dir="rtl"] & {
      left: auto;
      right: calc(-1 * var(--touch-padding));
    }
  }
`

const On = styled(Button)`
  z-index: 1;
  opacity: 1;
  pointer-events: auto;

  .${cssOff} & {
    opacity: 0;
    pointer-events: none;
  }
`

// const Off = styled(Button)`
//   opacity: 0;
//   pointer-events: none;
// `

export default function MenuToggle(props) {
  const console = useConsole()
  const [active, setActive] = useState(true)
  const rroot = useRef()
  const rbutton = useRef()
  const focusNextRender = useRef(false)
  const searchPanel = useSearchPanel()
  const header = useHeader()
  const dictionary = useDictionary()
  const ssr = useSSR()
  const navigation = useNavigation()
  const { isOnSearchResultsPage } = useSearch()

  Object.assign(ctx, {
    toggle: {
      focus: ({ keyboard }) => {
        if (keyboard) focusNextRender.current = true
      },
    },
  })

  const activate = e => {
    const keyboard = !e.clientX && !e.clientY
    searchPanel.pane.activate({ keyboard })

    // workaround to focus on the input instead of the close button when the search panel is opened
    // if there's a #search-input on the page, focus it
    const searchInput = document.getElementById("search-input")
    console.log({ searchInput })
    setTimeout(() => {
      if (searchInput) searchInput.focus({ focusVisible: true })
    }, 100)
  }

  useLayoutEffect(() => header.on(header.events.lock, () => startTransition(() => setActive(false))))
  useLayoutEffect(() => header.on(header.events.unlock, () => startTransition(() => setActive(true))))
  useLayoutEffect(() => {
    let raf
    if (focusNextRender.current) {
      raf = requestAnimationFrame(() => {
        if (active) rbutton.current.focus({ focusVisible: true })
      })
    }
    focusNextRender.current = false
    return () => cancelAnimationFrame(raf)
  })

  console.verbose("Search:Toggle(%o)", { active, alt: props.alt })

  return (
    <Root ref={rroot} className={[active ? cssOn : cssOff, props.alt && cssAlt].filter(Boolean).join(" ")}>
      <On
        ref={rbutton}
        type='button'
        onClick={activate}
        disabled={isOnSearchResultsPage ? true : !active}
        aria-hidden={!active || isOnSearchResultsPage ? "true" : "false"}
        tabIndex={!active ? -1 : 0}
        style={{ "--m": ssr ? 0 : 1 }}
      >
        <svg height='18' width='18' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
          <path d='m14.8 13.4-4-4c.7-1 1.1-2.1 1.1-3.4 0-3.2-2.6-5.8-5.8-5.8-3.3 0-5.9 2.6-5.9 5.8s2.6 5.8 5.8 5.8c1.3 0 2.4-.4 3.4-1.1l4 4zm-8.8-3.5c-2.1 0-3.9-1.7-3.9-3.9 0-2.1 1.8-3.9 3.9-3.9s3.9 1.8 3.9 3.9c0 2.2-1.7 3.9-3.9 3.9z' />
        </svg>
        <span>
          <span>{dictionary.search()}</span>
        </span>
      </On>
      {/* <Off as='div' className={active ? cssOn : cssOff}>
        <SearchIcon />
        <span>
          <span>{dictionary.search()}</span>
        </span>
      </Off> */}
    </Root>
  )
}
