import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import { headline100, headline70, surtitle70 } from "css/text"

const Root = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  height: fit-content;
  margin-block-end: 1.5rem;
  ${({ align }) => align && `text-align: ${align} !important;`}

  & > h2 {
    ${headline70}
    grid-row-start: 2;

    em {
      color: rgb(var(--rolex-green));
      font-style: normal;
    }
  }

  .kicker, .subtitle {
    ${surtitle70}
    grid-row-start: 1;
    margin-block-end: 10px;
  }

  .surtitle {
    ${headline100}
    margin-block-end: 10px;
  }
`


export default function Date({ surtitle, title, subtitle, kicker, justify, className, id }) {
  const console = useConsole()

  console.verbose("date(%o)", { title, kicker, justify, className, id })

  return (
    <Root className={`title-block ${className}`} align={justify}>
      {kicker && <p className='kicker'>{kicker}</p>}
      {surtitle && <p className='surtitle'>{surtitle}</p>}
      <h2 className='title' dangerouslySetInnerHTML={{ __html: title }} id={id} />
      {subtitle && <p className='subtitle'>{subtitle}</p>}
    </Root>
  )
}
