import styled from "@emotion/styled"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"
import { motion } from "framer-motion"

export const Root = styled(motion.div)`
  position: relative;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  margin: 0;
  width: 86vw;

  ${getMediaQuery("m")} {
    min-width: 576px;
    width: 56vw;
  }

  ${getMediaQuery("l")} {
    width: 38vw;
  }
  ${getMediaQuery("m", { max: true })} {
    height: 100vh;
  }
  grid-column: doc;
  top: 0;
  padding-top: 20px;
  --fx: 0.4s;
  --delay: calc((1 - var(--t, 0)) * 600ms);
  --t: calc(70px + var(${cssVarHeaderHeight}));
`

export const SuggList = styled.div`
  padding: 0 0 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-inline-start: 35px;

  grid-column: main;
  flex-direction: column;

  span:last-of-type {
    margin-top: 20px;
  }

  ${getMediaQuery("m", { max: true })} {
    padding: 6px 0px 30px 0px;
    padding-inline-start: 0px;
  }
`

export const InnerList = styled(motion.ul)`
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  width: 100%;
`

export const FlexLi = styled(motion.li)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  pointer-events: ${({ noResult }) => (noResult ? "none" : "auto")};
`

export const ResultLine = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  text-align: unset;
  color: rgb(var(--pure-white));
  padding: 0 0 10px;
  margin: 0;
  width: 100%;
  ${getMediaQuery("m")} {
    &:hover {
      font-weight: bold;
    }
  }
  span {
    font-weight: bold;
  }
`

export const TitleLine = styled(motion.span)`
  padding: 0 0 10px;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(var(--ocean-green) / 1);
`
export const ScreenReaderDiv = styled.div`
  position: absolute;
  left: -9999px;
`
