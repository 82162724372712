import { useRef } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import useParallax from "hooks/useParallax"

import Image from "components/media/ImageCLD"

import PageHeading from "./PageHeading"
import CTATxt from "./CTATxt"

const Header = styled.header`
  position: relative;
  justify-content: center;

  overflow: hidden;

  z-index: 0;

  ${fullGrid};

  &.multirows {
    grid-template-rows: min-content min-content 40px;

    ${getMediaQuery("m")} {
      grid-template-rows: min-content min-content 60px;
    }
  }
`

const Figure = styled(motion.figure)`
  display: block;
  width: 100%;
  grid-column: doc;
  grid-row: 1 / -1;

  z-index: -1;
  background-color: black;

  & picture {
    display: flex;
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transform: translateY(var(--ypos)) scale(1.2);
  }
`

export default function CoverImage({ heading, heading_gradient, image_cld: image, text, video_amp_cta, className }) {
  const console = useConsole()

  const ref = useRef()

  const y = useParallax(ref)

  const cl = text?.length && !text?.[0]?.link_404 ? " multirows" : ""

  return (
    <Header ref={ref} className={`${className}${cl}`}>
      <PageHeading
        animate
        heading={heading}
        heading_gradient={heading_gradient}
        link={text?.[0]?.link_404}
        video_amp={video_amp_cta?.length ? video_amp_cta[0].video_amp : null}
      />
      <Figure style={{ "--ypos": y }}>
        <Image {...image} sizes='100vw' animate />
      </Figure>
      {text?.length && !text?.[0]?.link_404 ? <CTATxt blocks={text} /> : null}
    </Header>
  )
}
