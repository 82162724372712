import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { motion, useReducedMotion, useTransform, useMotionTemplate, transform } from "framer-motion"

import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"

import useSSR from "hooks/useSSR"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"
import Image from "components/media/ImageCLD"

import { useValue } from "./context"
import TextBlock from "./texts/TextBlock"

const Li = styled(motion.li)`
  position: relative;
  justify-content: center;

  height: 200vh;
  margin-block-start: -100vh;

  z-index: 0;

  &:first-of-type {
    height: 100vh;

    margin-block-start: 0;
  }

  perspective: 10px;
`

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  text-decoration: none;

  ${fullGrid}
  grid-template-rows: [top-row] 1fr 1fr [bottom-row];

  &:hover {
    ${({ cta }) => (cta ? "cursor: pointer" : "")};
  }
`

const containerStyle = css`
  height: 100%;
  width: 100%;
`

const MediaContainer = styled(motion.div)`
  height: 100vh;
  width: 100%;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  overflow: hidden;
  position: sticky;
  top: 0;
  pointer-events: none;
`

const VideoContainer = styled(motion.div)`
  ${containerStyle}

  & .${cssContainer} {
    z-index: -1;
    height: 100%;

    & .${cssVideo}, .${cssPoster}, .${cssFallback} {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ImageContainer = styled(motion.div)`
  ${containerStyle}

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: #212121;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
`

function VideoItem({ video, prog, index, opacity }) {
  const console = useConsole()

  const shouldReduceMotion = useReducedMotion()

  const videRef = useRef()

  const { inStartVid, inStopVid } = useValue()

  const vidPlay = useTransform([prog, inStartVid, inStopVid], ([p, i, o]) => p >= i[index] && p <= o[index])

  function onVidToPlay(bool) {
    if (shouldReduceMotion) return
    if (bool) {
      videRef.current.play()
    } else {
      videRef.current.pause()
    }
  }
  useEffect(() => vidPlay.onChange(onVidToPlay))

  return (
    <VideoContainer>
      <Video ref={videRef} autoPlay={index === 0} noButton autoPause={false} {...video} sizes='100vw' />
      <Overlay
        style={index !== 0 ? { opacity } : {}}
        initial={index === 0 ? { opacity: 1 } : {}}
        animate={index === 0 ? { opacity: 0, transition: { delay: 0.5, duration: 0.9 } } : {}}
        transition={{ type: "tween", duration: 1 }}
      />
    </VideoContainer>
  )
}

function ImageItem({ image, index, opacity }) {
  const console = useConsole()

  return (
    <ImageContainer>
      <Image {...image} sizes='100vw' />
      <Overlay
        style={index !== 0 ? { opacity } : {}}
        initial={index === 0 ? { opacity: 1 } : {}}
        animate={index === 0 ? { opacity: 0, transition: { delay: 0.5, duration: 0.9 } } : {}}
        transition={{ type: "tween", duration: 1 }}
      />
    </ImageContainer>
  )
}

function AshlarContent({ heading, chapo, paragraph_blocks, media, cta, index }) {
  const console = useConsole()

  const ssr = useSSR()

  const { input, prog } = useValue()

  const diff = useTransform(input, inp => inp[index + 1] - inp[index])

  const p = useTransform([prog, input, diff], ([p, inp, d]) => transform(p, [inp[index] - d * 0.6, inp[index] - d * 0.25], [index === 0 ? 0 : 20, 0]))
  const opacity = useTransform([prog, input, diff], ([p, inp, d]) => {
    if (ssr) {
      return 1
    }
    return transform(p, [inp[index] - d * 0.6, inp[index] - d * 0.25], [index === 0 ? 0 : 1, 0])
  })

  const blur = useMotionTemplate`blur(${p}px)`

  function onClick(e) {
    if (e.target.tagName === "BUTTON" || e.target.tagName === "A") return
    const btn = e.target.getElementsByTagName("button")
    const lnk = e.target.getElementsByTagName("a")
    const target = lnk[0] || btn[0]
    target && target.click()
  }

  return (
    <Wrapper className='dark-theme' onClick={onClick} cta={cta.length !== 0}>
      <MediaContainer style={{ filter: blur }}>
        {media[0]?.video_cld || media[1]?.video_cld ? (
          <VideoItem video={media[0]?.video_cld ?? media[1]?.video_cld} index={index} prog={prog} opacity={opacity} />
        ) : media[0]?.image_cld || media[1]?.image_cld ? (
          <ImageItem image={media[0]?.image_cld ?? media[1]?.image_cld} index={index} opacity={opacity} />
        ) : null}
      </MediaContainer>
      <TextBlock heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} cta={cta} index={index} />
    </Wrapper>
  )
}

export default function Ashlar({ heading, chapo, paragraph_blocks, media, cta, uid, ...props }) {
  const console = useConsole()

  const [index, setIndex] = useState(null)

  const { pagesCount, updatePageNum, removePage, num } = useValue()

  const ref = useRef()

  useLayoutEffect(() => {
    updatePageNum(uid)
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
    return () => removePage(uid)
  }, [])

  function onNumChange() {
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
  }
  useEffect(() => num.onChange(onNumChange))

  return (
    <Li ref={ref} style={{ zIndex: pagesCount - index }} id={`ashlar--${index}`}>
      <AshlarContent heading={heading} chapo={chapo} paragraph_blocks={paragraph_blocks} media={media} cta={cta} index={index} />
    </Li>
  )
}
