import { useConsole } from "contexts/Console"
import { cssVarExpansion, cssVarTransitionDelay } from "contexts/Header"
import { useNavigation } from "contexts/Navigation"
import useSSR from "hooks/useSSR"
/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext, useEffect, useState } from "react"

import { waTriggerEvent } from "../components/analytics/DigitalDataLayer.js"

export const ctx__ONLY_FOR_WISHLIST__ = {}
const Context = createContext(ctx__ONLY_FOR_WISHLIST__)

export const Provider = ({ children }) => {
  const ssr = useSSR()
  const [wishlist, setWishlist] = useState(null)
  const [notificationRMC, setNotificationRMC] = useState(null)
  const [wishlistUpdateIndex, setWishlistUpdateIndex] = useState(0)
  const console = useConsole()
  const navigation = useNavigation()

  useEffect(() => {
    // update context with wishlist in local storage
    if (process.browser)
      setWishlist(JSON.parse(localStorage.getItem("rmcWishList") || "[]") || [])
  }, [])

  useEffect(() => {
    // if rmc parameter is present in the url, add it to the wishlist
    if (process.browser) {

      // get rmc parameter in the url
      const urlParams = new URLSearchParams(window.location.search)
      const rmcs = urlParams.get("rmc") ? urlParams.get("rmc").replace("(", "").replace(")", "").split(",") : []

      if (rmcs && rmcs.length > 0) {
        let newWishlist = []
        rmcs.forEach(rmc => {
          // if (wishlist.indexOf(rmc) === -1) { /** ??? */
          newWishlist.push(rmc)
          // }
        })

        if (newWishlist && newWishlist.length > 0) {
          // console.log("rmcs- new wishlist", newWishlist)
          setWishlist(newWishlist)
          updateLocalStorage(newWishlist)
          setWishlistUpdateIndex(wishlistUpdateIndex + 1)
        }

        // remove rmc parameter from the url
        // window.history.replaceState({}, document.title, window.location.pathname)
      }
    }
  }, [])

  const updateWishlist = list => {
    // update wishlist in both context and local storage
    setWishlist(list)
    updateLocalStorage(list)
    setWishlistUpdateIndex(wishlistUpdateIndex + 1)
    // window.dispatchEvent(new Event("wishlistUpdate"))
  }

  const triggerNotification = (rmc, action) => {
    // trigger notification for 3 seconds
    setNotificationRMC({ rmc, action })

    // set header visible
    document.documentElement.style.setProperty(cssVarExpansion, 1)
    document.documentElement.style.setProperty(cssVarTransitionDelay, "300ms")

    // clear previous timeout so the notification doesn't disappear too early
    clearTimeout(window.wishlistNotificationTimeout)
    window.wishlistNotificationTimeout = setTimeout(() => {
      setNotificationRMC(null)
    }, 3000)
  }

  const updateLocalStorage = list => {
    // update wishlist in local storage
    if (process.browser) localStorage.setItem("rmcWishList", JSON.stringify(list))
  }

  const toggleWishlist = (rmc, family) => {
    // console.log("toggleWishlist", { rmc, family })
    // add or remove rmc from wishlist
    if (wishlist.includes(rmc)) {
      const newWishlist = wishlist.filter(item => item !== rmc)
      updateWishlist(newWishlist)
      triggerNotification(rmc, "remove")

      // Tracking
      waTriggerEvent({
        eventName: "removeFromWishlist",
        eventType: "Click",
        products: [{
          productCategory: family ? family.toLowerCase().replace(" ", "-") : "No family provided",
          productID: rmc,
          productQuantity: "1"
        }]
      })
    } else {
      const newWishlist = wishlist
      newWishlist.push(rmc)
      updateWishlist(newWishlist)
      triggerNotification(rmc, "add")

      // Tracking
      waTriggerEvent({
        eventName: "addToWishlist",
        eventType: "Click",
        products: [{
          productCategory: family ? family.toLowerCase().replace(" ", "-") : "No family provided",
          productID: rmc,
          productQuantity: "1"
        }]
      })
    }
  }

  const isInWishlist = rmc => {
    // check if rmc is in wishlist
    return wishlist.includes(rmc)
  }

  // Events
  const onNotificationMouseEnter = () => {
    // clear timeout when hovering over notification
    clearTimeout(window.wishlistNotificationTimeout)
  }

  const onNotificationMouseLeave = () => {
    // trigger notification for 3 seconds
    window.wishlistNotificationTimeout = setTimeout(() => {
      setNotificationRMC(null)
    }, 3000)
  }

  return (
    <Context.Provider
      value={{ wishlist, toggleWishlist, isInWishlist, wishlistUpdateIndex, notificationRMC, onNotificationMouseEnter, onNotificationMouseLeave }}
    >
      {children}
    </Context.Provider>
  )
}

export function useWishlist() {
  return useContext(Context)
}
