import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Price from "components/price/Price"
import WishlistButton from "components/wishlist-button/WishlistButton"
import getMediaQuery from "css/breakpoints"
import { bold, legend100, legend120, legend150, light, normal } from "css/text"
import { motion, useAnimationControls, useTransform } from "framer-motion"
import { memo, useCallback, useEffect, useRef, useState } from "react"

import { CFGV7 } from "./context"
import { useFrameAnimation } from "./FrameAnimation"
import { cssSwap, fmSwap, mvSubscribe, rafDelay } from "./utils"

const SWAP_IN = css`
  transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

const Wishlist = styled.aside`
  --offset: calc(1.875rem + 1.25rem);
  ${getMediaQuery("s")} {
    --offset: calc(3.125rem + 1.375rem);
  }
  position: relative;
  z-index: 4;
  padding: var(--offset) var(--outer-margin) 0;
  box-sizing: border-box;
  flex: 1;
  align-items: start;
  display: flex;
  overflow: hidden;
  pointer-events: none;
  ${SWAP_OUT}

  & button {
    pointer-events: auto;
    transform: translateY(-50%);
  }

  .wishlist-button-variations {
    transform: none;
  }

  .wishlist-button-root {
    margin-block-start: -1rem;
  }

  &.wv_reveal {
    ${SWAP_IN}
  }
`

const Main = styled.section`
  position: relative;
  padding: 0 var(--outer-margin);
  box-sizing: border-box;
  pointer-events: none;
  ${SWAP_OUT}

  &.wv_reveal {
    ${SWAP_IN}
  }

  @media (orientation: landscape) {
    //    max-width: 40%;
  }
  height: 0;
  overflow: hidden;
  &.wv_reveal {
    height: auto;
  }
  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }

  & * {
    font-size: clamp(0.875rem, 0.8125rem + 0.1563vw, 1rem); // 14px > 16px
  }

  & > h2 {
    padding: 0;
    ${bold}
  }
  & > div {
    will-change: opacity;
    opacity: 0;
    min-height: 26px;
    & button {
      pointer-events: auto;
    }
    &.wv_reveal {
      transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 0ms;
      opacity: 1;
    }
  }
`

const CfgActiveTitle = memo(({ Ctx }) => {
  const { model, getModel, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const _label = useTransform(model, update)
  const rfmain = useRef()

  function update(m) {
    return getModel(m)?.familyName
  }

  function render() {
    setLabel(_label.get())
  }
  /*
  function display() {
    cssSwap(rfmain.current, "wv_reveal", render)
  }
*/
  useEffect(() => mvSubscribe(_label, render), [])
  useEffect(() => kill, [])

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  return <h2 ref={rfmain}>{label}</h2>
})

const CfgActivePrice = memo(({ Ctx }) => {
  const { model } = useFrameAnimation(Ctx)
  const [rmc, setRmc] = useState(null)
  const rfmain = useRef()
  const anim = useAnimationControls()

  function updateRmc(v) {
    return () => {
      setRmc(null)
      rafDelay(() => setRmc(v))
    }
  }

  function update(v) {
    if (!v) return
    fmSwap(anim, updateRmc(v), "in@")
  }

  function onPriceReady() {
    fmSwap(anim, null, "out")
  }

  useEffect(() => mvSubscribe(model, update, false), [])
  //  useEffect(() => mvSubscribe(ready, onReady, false), [])

  function kill() {
    setRmc(null)
  }

  useEffect(() => kill, [])
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  return (
    <motion.div ref={rfmain} animate={anim}>
      {rmc && <Price rmc={rmc} onReady={onPriceReady} />}
    </motion.div>
  )
})

const CfgActiveDescription = memo(({ Ctx }) => {
  const { model, getModel, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const _label = useTransform(model, update)

  function update(m) {
    return getModel(m)?.description
  }

  function render() {
    setLabel(_label.get())
  }

  useEffect(() => mvSubscribe(_label, render), [])

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])

  return <p dangerouslySetInnerHTML={{ __html: label }}></p>
})

const CfgActiveDial = memo(({ Ctx }) => {
  const { model, getModel, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const _label = useTransform(model, update)

  function update(m) {
    return getModel(m)?.dial
  }

  function render() {
    setLabel(_label.get())
  }

  useEffect(() => mvSubscribe(_label, render), [])

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])

  return <p dangerouslySetInnerHTML={{ __html: label }}></p>
})

export const CfgActiveWishlist = memo(({ Ctx }) => {
  const { swapping, model, lastStep, getModel } = useFrameAnimation(Ctx)
  const [wmodel, setWmodel] = useState(null)

  const rfmain = useRef()

  function render(v) {
    if (v || !lastStep()) return display(false)
    rafDelay(() => display(true))
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(swapping, render, false), [])

  function update(v) {
    //      if (!lastStep()) return
    let m = getModel(v)
    console.log("modelsel", { m })
    if (!m) return
    setWmodel({
      rmc: v,
      family: m.familyCode,
      modelName: m.model,
      newmodelselection: m.newmodelselection,
    })
  }

  useEffect(() => mvSubscribe(model, update), [])

  function kill() {
    setWmodel(null)
  }

  useEffect(() => kill, [])

  return <Wishlist ref={rfmain}>{wmodel && <WishlistButton {...wmodel} className='wishlist-button-variations' withLabel />}</Wishlist>
})

export const CfgActive = memo(({ Ctx }) => {
  const { step, mobile, swapping, lastStep } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  /*
  function onStep(v) {
    display(v > 0 && !mobile.get())
  }
*/
  function onSwapping(v) {
    display(!v && lastStep())
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  //  useEffect(() => mvSubscribe(step, onStep), [])
  useEffect(() => mvSubscribe(swapping, onSwapping), [])

  return (
    <Main ref={rfmain}>
      <CfgActiveTitle Ctx={Ctx} />
      <CfgActiveDial Ctx={Ctx} />
      <CfgActiveDescription Ctx={Ctx} />
      <CfgActivePrice Ctx={Ctx} />
    </Main>
  )
})

export const CfgActiveOld = memo(({ Ctx }) => {
  const { step, mobile, swapping, lastStep } = useFrameAnimation(Ctx)
  const rfmain = useRef()

  function onStep(v) {
    display(v > 0 && !mobile.get())
  }

  function onSwapping(v) {
    display(!v && step.get() > 0 && (!mobile.get() || lastStep()))
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(step, onStep), [])
  useEffect(() => mvSubscribe(swapping, onSwapping), [])

  return (
    <Main ref={rfmain}>
      <CfgActiveWishlist Ctx={Ctx} />
      {false && <CfgActiveTitle Ctx={Ctx} />}
      {false && <CfgActiveDescription Ctx={Ctx} />}
      {false && <CfgActivePrice Ctx={Ctx} />}
    </Main>
  )
})
