import { useEffect, useState, useRef, useLayoutEffect, memo } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { headline70, body50, bold, legend120 } from "css/text"
import { buttonReset } from "css/buttons"
import { CfgAbstractLoaderBtn } from "./loader"
import { CfgSimpleLoaderBtn } from "./loader"
import { CfgCatalog } from "./catalog"
import { CfgPreload } from "./preload"
import { useDictionary } from "contexts/Dictionary"

const SWAP_IN = css`
  transition: opacity 800ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

const Main = styled.section`
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 75%;
  box-sizing: border-box;
  padding: 0 var(--outer-margin);
  text-align: center;
  ${SWAP_OUT}
  &.wv_reveal {
    ${SWAP_IN}
  }

  &.dark-theme {
  h2 {
    color: rgb(var(--pure-white));
}
  }
  &.light-theme {
  h2 {
    color: rgb(var(--light-black));
}
  }

  & > div {
    flex: 1;
    align-self: end;

    .wv_reload & {
      display: none;
    }

    & > section {
      padding: 20px 0 0;
      & h1 {
        ${legend120}
      }
      & ul {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        & li {
          & button {
            position: relative;
            ${buttonReset}
            font-size: 16px;
            ${bold}
            padding-block: 10px;
            padding-inline: 20px;
            cursor: pointer;
            opacity: 0.5;
            transition: color 0.3s, opacity 0.3s;
            .dark-theme & {
              color: rgb(var(--pure-white));
            }
            .light-theme & {
              color: rgb(var(--light-black));
            }

            @media (hover: hover) and (pointer: fine) {
              &[aria-disabled="false"]:hover {
                opacity: 1;
              }
            }

            &[aria-disabled="true"] {
              cursor: default !important;
            }
            &[aria-selected="true"] {
              cursor: default !important;
              opacity: 1;
              &:after {
                opacity: 1;
              }
            }

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              margin-block-start: -3px;
              margin-inline-start: 8px;
              width: 8px;
              height: 8px;
              border-radius: 8px;
              .dark-theme & {
                background: rgb(var(--pure-white));
              }
              .light-theme & {
                background: rgb(var(--rolex-green));
              }
              opacity: 0;
              transition: opacity 0.5s;
            }
          }
        }
      }
    }
  }

  & h2 {
    ${body50}
    ${bold}
    box-sizing: border-box;
  
    & > span {
      display: block;
      ${headline70}
    }
  }
`

function CfgPreloadListItem({ Ctx, path, label, rmc }) {
  const { model, fetching, startrmc, preload, wa } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfselected = useRef(false)

  const dictionary = useDictionary()

  function onClick(e) {
    if (rfselected.current) return
    fetching.set(path)
    preload.set(0)
    startrmc.set(rmc)
    model.set(rmc)
  }

  function onFetching(v) {
    if (!v) {
      rfmain.current?.setAttribute("aria-disabled", false)
      rfmain.current?.removeAttribute("disabled")
      return
    }
    rfselected.current = v === path
    rfmain.current?.setAttribute("aria-disabled", v !== path)
    if (v !== path) rfmain.current?.setAttribute("disabled", "")
    rfmain.current?.setAttribute("aria-selected", v === path)
  }

  useLayoutEffect(() => mvSubscribe(fetching, onFetching), [])

  return (
    <button ref={rfmain} onClick={onClick} aria-label={`${dictionary.size()}: ${label}`}>
      {label}
    </button>
  )
}

const CfgPreloadList = memo(({ Ctx }) => {
  const { flagships } = useFrameAnimation(Ctx)
  const [items, setItems] = useState(null)

  function onFlagships(v) {
    if (!v) return setItems(null)
    let list = JSON.parse(v)
    setItems(list)
  }

  useEffect(() => mvSubscribe(flagships, onFlagships), [])

  return (
    items?.length > 1 && (
      <section>
        <ul>
          {items?.map(item => (
            <li key={item.rmc}>
              <CfgPreloadListItem Ctx={Ctx} {...item} />
            </li>
          ))}
        </ul>
      </section>
    )
  )
})

export const CfgLauncher = memo(({ Ctx }) => {
  const { step, family } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const [title, setTitle] = useState(null)
  const dictionary = useDictionary()

  function onFamily(v) {
    if (!v) return
    setTitle(dictionary.configuratorTitleLauncher(`<span>${v}<span>`))
    display(true)
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(family, onFamily), [])

  function onStep(v) {
    display(v === 0)
  }

  useEffect(() => mvSubscribe(step, onStep, false), [])

  return (
    <Main ref={rfmain}>
      <CfgCatalog Ctx={Ctx} />
      <CfgPreload Ctx={Ctx} />
      <div>
        <h2 id='config-modal-heading' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <CfgPreloadList Ctx={Ctx} />
        <CfgSimpleLoaderBtn Ctx={Ctx} />
      </div>
    </Main>
  )
})
