import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { Provider } from "contexts/Navigation"
import useConstant from "hooks/useConstant"
import { compile, match } from "path-to-regexp"
import { useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import exposeEventTarget from "utils/exposeEventTarget"
import { disable as disableUseSSR } from "hooks/useSSR"
import * as components from "components/components"

export default function Navigation({ children, initialState }) {
  const console = useConsole()

  const locale = useLocale()
  const forceLocaleInURL = initialState.force_locale_in_url
  const { page: initialPageUID, pages } = initialState[locale.current.codes.www]

  const location = useLocation()
  const history = useHistory()
  const domain = process.browser ? `${global.location.protocol}//${global.location.hostname}` : process.env.REVERSE_PROXY_URL
  const slug = "/" + ((match(`${locale.schema}/:slug(.*)?`)(location.pathname) ?? {}).params?.slug || "")

  const et = useConstant(() => new EventTarget())
  const lockers = useConstant(() => new Map())
  const operation = useRef(null)

  const localize = (reqUrl, reqCode = null) => {
    if (!["/", "#", "?"].includes(reqUrl[0])) reqUrl = "/" + reqUrl

    let pathname = reqUrl,
      search,
      hash
    if (pathname?.includes("#")) {
      const idx = reqUrl.indexOf("#")
      hash = pathname.slice(idx)
      pathname = pathname.slice(0, idx)
    }

    if (pathname?.includes("?")) {
      const idx = reqUrl.indexOf("?")
      search = pathname.slice(idx)
      pathname = pathname.slice(0, idx)
    }

    pathname = pathname === "/" ? "" : pathname

    let code = null
    if ([...locale.codes, ...locale.externalCodes].includes(reqCode)) code = reqCode
    else code = locale.current.codes.www || locale.defaultLocale.codes.www
    if (!forceLocaleInURL && code === locale.defaultLocale.codes.www) code = null

    const base = compile(locale.extendedSchema)({ locale: code })
    const url = new URL(`${base}${pathname}`, domain)

    if (search) url.search = search
    if (hash) url.hash = hash

    console.verbose("Navigation::localize(%o) => %o", { reqUrl, reqCode }, { url, code })
    return url.href.slice(url.origin.length)
  }

  const lock = (id, unlock) => lockers.set(id, unlock)
  const unlock = id => {
    const handler = lockers.get(id)
    lockers.delete(id)
    return handler
  }

  const route = slug => [`/:locale(${locale.codes.join("|")})`, !forceLocaleInURL && "?", slug !== "/" && slug].filter(Boolean).join("")

  const model = async (targetUrl, asInitial) =>
    Promise.resolve(new URL(targetUrl))
      .then(url => {
        url.pathname = url.pathname.replace(new RegExp(".html$"), "")
        url.pathname += !asInitial ? ".model.json" : ".initial.json"
        return url
      })
      .then(url => new Request(url))
      .then(request => fetch(request))
      .then(response => {
        if (response.status >= 400) throw { status: response.status }
        return response
      })
      .then(response => response.json())
      .then(data => {
        if (!asInitial) {
          const { uid, ...props } = data
          const page = pages.find(page => page.uid === uid)
          if (!page) throw { status: 404 }
          Object.assign(page, props)
          return page
        }

        if (asInitial) {
          Object.assign(initialState, data)
          return data[data.current_locale.codes.www].pages.find(page => page.uid === data[data.current_locale.codes.www].page)
        }
      })

  const navigate = async (slug, state = null, { replace = false, retry = true, scroll = true } = {}) => {
    disableUseSSR()
    if (!slug) throw new Error("missing slug parameters")

    const targetUrl = new URL(slug, `${global.location.origin}`)

    const nextCode = match(`${locale.schema}/:slug(.*)?`)(targetUrl.pathname)?.params?.locale || locale.defaultLocale.codes.www
    const isInitial = nextCode !== locale.current.codes.www

    if (operation.current) operation.current.abort()
    const aborter = (operation.current = new AbortController())
    let abortHandler

    return Promise.race(
      [!isInitial && new Promise((_, reject) => setTimeout(() => reject({ timeout: true }), 300)), model(targetUrl, isInitial)].filter(Boolean)
    )
      .then(raceWinner =>
        Promise.race([
          new Promise((_, reject) => {
            if (aborter.signal.aborted) return reject({ type: "abort" })
            abortHandler = err => reject(err)
            aborter.signal.addEventListener("abort", abortHandler, { once: true })
          }),
          Promise.all([
            // if raceWinner is the model, preload components
            ...(raceWinner?.preload ?? []).map(component => {
              try {
                const Component = components[component]
                const lazy = Component?.$$typeof === Symbol.for("react.lazy")
                console.verbose("Component preload:", Component)
                if (!lazy) return
                Component._init(Component._payload)
              } catch (errOrPromise) {
                if (typeof errOrPromise?.then === "function")
                  return Promise.race([errOrPromise, new Promise(resolve => setTimeout(resolve, 300))]).catch(err => console.error(err))
                else console.error(errOrPromise)
              }
            }),
            // lockers should animate to unlock state
            ...[...lockers].map(([_, handler]) => {
              const op = handler({ to: targetUrl, signal: aborter.signal })
              return Promise.resolve(op).catch(err => console.error(err))
            }),
          ]),
        ]).then(() => aborter.signal.removeEventListener("abort", abortHandler))
      )
      .then(() => () => {
        const next = `${targetUrl.pathname}${targetUrl.search}${targetUrl.hash}`
        history[replace ? "replace" : "push"](next, state)
        if (scroll) global?.scrollTo?.(0, 0)
      })
      .catch(err => {
        // if aborted, nothing to do
        if (err.type === "abort" || aborter.signal.aborted) return () => {}

        // navigation should happen now, clear lockers
        void [...lockers].map(([id]) => lockers.delete(id))

        // go to error page
        if (err.status)
          return () => {
            if (retry) ctx.navigate(ctx.localize(`/${err?.status}`, nextCode), state, { replace: true, retry: false })
          }

        // go to page, and await here ( placeholder )
        if (err.timeout) {
          console.verbose("navigation timeout %o", targetUrl)
          return () => {
            const next = `${targetUrl.pathname}${targetUrl.search}${targetUrl.hash}`
            history[replace ? "replace" : "push"](next, state)
            if (scroll) global?.scrollTo?.(0, 0)
          }
        }

        // unmanaged error?
        return () => {
          console.error(err)
          throw err
        }
      })
      .then(handler => {
        aborter.signal.onabort = null
        return handler()
      })
  }

  const ctx = {
    ...exposeEventTarget(et),
    localize,
    lock,
    model,
    navigate,
    pages,
    push: (slug, state, opts = {}) => navigate(slug, state, Object.assign({}, opts, { replace: false })),
    replace: (slug, state, opts) => navigate(slug, state, Object.assign({}, opts, { replace: true })),
    route,
    slug,
    get homepage() {
      return slug === "/"
    },
    unlock,
  }

  console.verbose("Navigation(){%o}", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
