import { forwardRef, useLayoutEffect, useImperativeHandle, useState, useId } from "react"
import { Helmet } from "react-helmet-async"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { useViewport } from "contexts/Viewport"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"

import useScript from "hooks/useScript"
// import useSSR from "hooks/useSSR"

const videoScriptSrc = "https://amp.akamaized.net/hosted/1.1/player.esi?apikey=rolex&version=9.1.25"

const Container = styled.div`
  /* Hide the inline video while allowing AMP to display native subtitles correctly */
  aspect-ratio: 16/9;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 9px;

  ${props => props.captionStyle && props.captionStyle}
`

const Player = styled.div`
  // ========================
  // Begin AMP customization
  // ------------------------

  .amp-player {
    background: transparent;
  }

  .amp-poster,
  .amp-ready .amp-poster,
  .amp-share.amp-panel,
  .amp-buffering-overlay,
  .amp-active .amp-buffering-overlay,
  .amp-waiting .amp-buffering-overlay,
  .amp-qualitychange-manual .amp-buffering-overlay,
  .amp-busy .amp-buffering-overlay,
  .amp-error-msg,
  .amp-pip,
  .amp-unmute,
  .amp-share.amp-icon,
  .amp-jump-back,
  .amp-jump-forward,
  .amp-ads,
  .amp-error-message,
  .amp-airplay,
  .amp-autoplay-off {
    display: none !important;
  }

  /* force LTR layout of video controls -- this should not include captions */
  .amp-ui {
    .amp-progress,
    .amp-controls {
      direction: ltr;
    }
  }

  .amp-overlays {
    background: transparent;
    transition: none;
  }

  .amp-slide-in-out {
    transition: none;
  }

  .amp-progress {
    .amp-range {
      background-color: rgb(255 255 255);
    }
    .amp-handle {
      display: none;
    }
    .amp-track {
      background-color: rgb(255 255 255 / 0.5);
    }
    .amp-value {
      background-color: rgb(var(--rolex-green));
    }
  }

  // ------------------------
  // End AMP customization
  // ========================
`

function CoreVideo({ autoplay = false, sources, captions }, handle) {
  const console = useConsole()
  const id = useId()
  const locale = useLocale()
  const env = useEnv()
  const status = useScript(videoScriptSrc)
  const { orientation } = useViewport()

  const [player, setPlayer] = useState()
  const [captionStyle, setCaptionStyle] = useState("")

  // If url is valid, second argument will be ignored
  // and the url argument will be used as the base URL
  const baseUrl = url => new URL(url, env.content).href

  const srcLandscape = sources?.find(({ media }) => media === "landscape")?.src
  const srcPortrait = sources?.find(({ media }) => media === "portrait")?.src ?? srcLandscape

  const src = { portrait: baseUrl(srcPortrait), landscape: baseUrl(srcLandscape) }

  // WA
  const videoName = src?.landscape.substring(src?.landscape.lastIndexOf("/") + 1)
  const videoTag = ""
  let milestoneFiftyTriggered = false

  const isMobileSafari = () => {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
  }

  const langMappings = {
    "pt-br": "pt_br",
    "zh-hans": "chs",
    "zh-hant": "cht",
  }

  const track = locale.locales.map(locale => ({
    kind: "captions",
    label: locale.label,
    src: baseUrl(`${captions}_${locale.codes.www}.vtt`),
    srclang: langMappings[locale.codes.www] || locale.codes.www,
    type: "text/vtt",
  }))

  useLayoutEffect(() => {
    if (status !== "ready" || player) return

    global.akamai.amp.AMP.create(id, {
      autoplay: autoplay,
      captioning: {
        crossorigin: true,
        renderer: "auto",
      },
      language: langMappings[locale.current.codes.www] || locale.current.codes.www,
      media: {
        src: src[orientation.get()],
        track: !!captions && track,
        type: "video/mp4",
      },
      playsinline: true, // for iOS
      settings: {
        defaults: {
          captions: {
            backgroundColor: "white",
            backgroundOpacity: "0%",
            edgeType: "none",
            fontColor: "black",
            fontFamily: "smallCapitals",
            fontSize: "200%",
            presets: {
              iosFontSize: { large: orientation.get() === "landscape" ? "100%" : "75.0%" },
            },
            styles: { text: { fontVariant: "normal" } },
            windowColor: "white",
            windowOpacity: "100%",
            visible: true,
          },
        },
      },
    })
      .then(player => {
        setPlayer(player)

        // Fix akamai caption style change that is not working with React.
        // Retrieve the style tag and its content to reinject the new style through styled-component.
        if (!isMobileSafari()) {
          const cs = player.container.querySelector(".amp-captioning style")
          if (cs) {
            const style = cs.textContent
              .split("#" + id)
              .join("")
              .trim()
            setCaptionStyle(style)
          }
          const observer = new MutationObserver(mutation => {
            const style = mutation[0].addedNodes[0].textContent
              .split("#" + id)
              .join("")
              .trim()
            setCaptionStyle(style)
          })
          observer.observe(cs, { childList: true })
        }

        console.verbose("Akamai AMP(%o)", player)

        player?.addEventListener?.("fullscreenchange", onfullscreenchange)
        player?.addEventListener?.("playstatechange", onplaystatechange)
        player?.addEventListener?.("timeupdate", ontimeupdate)

        return () => {
          player?.removeEventListener?.("fullscreenchange", onfullscreenchange)
          player?.removeEventListener?.("playstatechange", onplaystatechange)
          player?.removeEventListener?.("timeupdate", ontimeupdate)
        }
      })
      .catch(err => console.error(err))

    const onfullscreenchange = e => {
      if (e.player?.displayState === "normal") {
        e.player?.pause()
        e.player.currentTime = 0
      }
    }

    const onplaystatechange = e => {
      if ((e.player?.playState === "playing") & (e.player?.absoluteCurrentTime === 0)) {
        waTriggerEvent({
          eventName: "video0",
          eventType: "Click",
          videoInfo: {
            name: videoName,
          } 
        })
      }
      if (e.player?.playState === "ended") {
        waTriggerEvent({
          eventName: "video100",
          eventType: "Click",
          videoInfo: {
            name: videoName,
          } 
        })
        milestoneFiftyTriggered = false // reset flag in case of replay
        e.player.exitFullScreen()
      }
    }

    const ontimeupdate = e => {
      const videoProgression = Math.round((e.player.absoluteCurrentTime / e.player.absoluteDuration) * 100)

      if (videoProgression >= 50) {
        if (!milestoneFiftyTriggered) {
          waTriggerEvent({
            eventName: "video50",
            eventType: "Click",
            videoInfo: {
              name: videoName,
            } 
          })

          milestoneFiftyTriggered = true
        }
      }
    }
  }, [status, player])

  useImperativeHandle(
    handle,
    () =>
      new Proxy(
        {},
        {
          get(t, k) {
            if (player?.[k]) return player[k]
            return Function.prototype
          },
        }
      )
  )

  console.verbose("VideoAMP(%o)", { sources: src, captions: track })
  return (
    <>
      <Helmet>
        <link rel='preconnect' href='https://amp.akamaized.net' crossorigin />
        <link rel='dns-prefetch' href='https://amp.akamaized.net' />
      </Helmet>
      <Container className='amp-container' captionStyle={captionStyle}>
        <Player id={id} />
      </Container>
    </>
  )
}

export default forwardRef(CoreVideo)
// const ForwardedCoreVideo = forwardRef(CoreVideo)

// export default forwardRef(function Bootstraped({ children, props }, ref) {
//   const ssr = useSSR()

//   if (ssr) return null
//   else
//     return (
//       <ForwardedCoreVideo {...props} ref={ref}>
//         {children}
//       </ForwardedCoreVideo>
//     )
// })
