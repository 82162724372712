import { useEffect, useRef, memo, createContext } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { useRollerAnimation, RollerAnimationProvider } from "./RollerAnimation"
import { CfgStepList } from "./dials.steplist"
import { CfgWatchRoller } from "./dials.watchroller"
import { CFGV7 } from "./context"

const { DIAL_SCALE, DIAL_OFFSET_Y, PK20_RATIO } = CFGV7

const SWAP_IN = css`
  transition: opacity 600ms cubic-bezier(0.22, 1, 0.36, 1) 0ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: visible;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

const Main = styled.section`
  --shadow-ratio: 1380 / 1180;
  ${SWAP_OUT}
  &.wv_appear {
    ${SWAP_IN}
  }
  position: absolute;
  z-index: 3;
  left: 0;
  width: 100%;
  top: 50%;
  transform-origin: 50% 50%;
  @media (orientation: portrait) {
    transform: translateY(calc(${DIAL_OFFSET_Y.portrait * PK20_RATIO}% - 50%));
    height: calc(${DIAL_SCALE.portrait}vw / ${PK20_RATIO});
  }
  @media (orientation: portrait) and (min-width: 481px) {
    transform: translateY(calc(${DIAL_OFFSET_Y.portrait_fat * PK20_RATIO}% - 50%));
    height: calc(${DIAL_SCALE.portrait_fat}vw / ${PK20_RATIO});
  }
  @media (orientation: landscape) {
    transform: translateY(calc(${DIAL_OFFSET_Y.landscape}% - 50%));
    height: ${DIAL_SCALE.landscape}%;
  }
  display: flex;
  justify-content: center;
  pointer-events: none;
`

const CfgRollerContents = memo(({ ctx, rctx }) => {
  const { lastStep, rolling, swapping, step, fromlaststep } = useFrameAnimation(ctx)
  const { readyALL } = useRollerAnimation(rctx)
  const rfmain = useRef(null)

  function onreadyALL(v) {
    if (!v) return
    rolling.set(false)
  }

  useEffect(() => mvSubscribe(readyALL, onreadyALL, false), [])

  function onStep(v) {
    if (!lastStep()) rolling.set(false)
  }

  useEffect(() => mvSubscribe(step, onStep, false), [])

  function onSwapping(v) {
    if (v) return
    display(lastStep())
  }

  function onFromLastStep(v) {
    if (!v) display(false)
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_appear")
  }

  useEffect(() => mvSubscribe(swapping, onSwapping, false), [])
  useEffect(() => mvSubscribe(fromlaststep, onFromLastStep, false), [])

  return (
    <Main ref={rfmain}>
      <CfgStepList ctx={ctx} rctx={rctx} />
      <CfgWatchRoller ctx={ctx} rctx={rctx} />
    </Main>
  )
})

export const CfgRoller = memo(({ Ctx }) => {
  const { reset, resized } = useFrameAnimation(Ctx)
  const rollerCtx = createContext()

  return (
    <RollerAnimationProvider Ctx={rollerCtx} reset={reset} resized={resized}>
      <CfgRollerContents ctx={Ctx} rctx={rollerCtx} />
    </RollerAnimationProvider>
  )
})
