import { useEffect, useState, useRef, useLayoutEffect, useCallback, createContext } from "react"

import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import { VARIANTS } from "./constants"
import { cssApply } from "./utils"
import getMediaQuery from "css/breakpoints"
import { buttonText, buttonReset, buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"
import useScrollLock from "hooks/useScrollLock"
import { useHeader } from "contexts/Header"
import { useSubNav } from "contexts/SubNav"
import { FocusScope } from "@react-aria/focus"
import { useDictionary } from "contexts/Dictionary"

const Modal = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: min(100%, 100dvh);
  //  height: -webkit-fill-available;
  overflow-y: auto;
  transform: translateZ(0);

  &.wv_reveal {
    transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 0ms;
    opacity: 1;
    visibility: visible;
  }

  &:focus-within {
    nav {
      visibility: visible;
    }
  }
`

const Close = styled.button`
  ${buttonIcon}
  ${translucentDark}
--spacing: 1.875rem;
  ${getMediaQuery("m")} {
    --spacing: 3.125rem;
  }
  inset-block-start: var(--spacing);
  inset-inline-end: var(--spacing);
  position: absolute;
  z-index: 103;
`

export function CfgModal({ children, active, kill }) {
  const rfmodal = useRef(null)
  const scrollLock = useScrollLock()
  const header = useHeader()
  const subnav = useSubNav()
  const dictionary = useDictionary()

  function onClose(e) {
    kill()
  }

  useLayoutEffect(() => {
    const el = rfmodal.current
    header.freeze("cfg", 0)
    subnav?.hide?.()
    scrollLock.lock(el)
    //    header.freeze("cfg", 0)
    rfmodal.current?.classList.add("wv_reveal")
    rfmodal.current?.querySelector("button")?.focus()

    return () => {
      scrollLock.unlock(el)
      header.unfreeze("cfg", 1)
      subnav?.unhide?.()
      //      header.unfreeze("cfg", 1)
    }
  }, [])

  return (
    <FocusScope contain restoreFocus autoFocus>
      <Modal role='dialog' aria-modal='true' aria-labelledby='config-modal-heading' ref={rfmodal} className='cfgv7Modal dark-theme'>
        <Close onClick={onClose} aria-label={dictionary.popinClose()}>
          <Icon type='close' />
        </Close>
        {children}
      </Modal>
    </FocusScope>
  )
}
