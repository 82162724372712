import { useConsole } from "contexts/Console"
import { Personalize as UFPersonalize } from "@uniformdev/context-react"
import generateComponents from "utils/generateComponents"

function Variation(props) {
  const console = useConsole()

  console.verbose("P13n:Variation(%o)", { props })
  return generateComponents(props, { console })
}

export default function P13N(props) {
  const console = useConsole()

  // console.verbose("\n••••••• P13N APP", props)
  const variations = props.variations.reduce((variations, variation, i) => {
    for (const {
      criteria: { name: pz = null } = {},
      component: [component],
    } of Object.values(variation)) {
      !!component &&
        variations.push(
          Object.assign(
            component,
            pz && { pz },
            props?.children && { children: props.children },
            props?.breadcrumb && { breadcrumb: props.breadcrumb },
            props?.slugForBreadcrumb && { slugForBreadcrumb: props.slugForBreadcrumb },
            {
              key: `${props.uid}-${i}`,
            }
          )
        )
    }
    // slugForBreadcrumb
    return variations.sort((a, b) => +!!b?.pz - +!!a?.pz) /** move default variant at the end of the array */
  }, [])

  console.verbose("P13n(%o)", { props, variations })
  return <UFPersonalize variations={variations} component={Variation} />
}
