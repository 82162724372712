import { css } from "@emotion/react"
import getMediaQuery from "css/breakpoints"
import { bold, visuallyHidden } from "css/text"

// ========================
// Buttons utilities
// ------------------------

export const buttonReset = css`
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  padding: 0;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;

  :is(a, button):not(:disabled) {
    cursor: pointer;
  }

  * {
    pointer-events: none;
  }
`

// TODO: refine, deal w/ inline btns
export const buttonContrastMode = css`
  html.prefers-contrast &:not(header &) {
    background: white !important;
    border-color: black !important;
    border-style: solid !important;
    border-width: 2px !important;
    color: black !important;
    transition: none !important;

    svg {
      fill: black;
    }

    @media (hover: hover) {
      :hover {
        background-color: black !important;
        border-color: white !important;
        color: white !important;

        svg {
          fill: white;
        }
      }
    }
  }
`

export const buttonCommon = css`
  ${buttonReset}
  ${bold}
  ${buttonContrastMode}
  --gap: 0.5rem;
  --height: var(--btn-height);
  --padding: 1.875rem;

  ${getMediaQuery("m")} {
    --padding: 2.1875rem;
  }

  align-items: center;
  box-sizing: border-box;
  color: var(--text, inherit);
  column-gap: var(--gap);
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  transition: background 0.3s, color 0.3s;
  white-space: nowrap;
  width: fit-content;

  // Disabled state
  :is(:disabled, .disabled) {
    opacity: 0.5;
  }

  // Icon <svg />
  svg {
    /* fill: currentColor; */
    height: 14px;
    width: 14px;
  }

  // To hide the text, wrap it inside any tag
  > *:not(svg) {
    ${visuallyHidden}
  }

  // Default theme
  @media (hover: hover) {
    :hover {
      color: var(--text, rgb(var(--dark-grey)));
    }
  }

  .dark-theme & {
    color: var(--text, rgb(var(--pure-white))); // jic

    @media (hover: hover) {
      :hover {
        color: var(--text, rgb(var(--grey)));
      }
    }
  }
`

const reverseIcon = css`
  &.reverseIcon {
    flex-direction: row-reverse;

    svg {
      margin-inline-end: calc(var(--gap) * -1);
    }
  }

  &:not(.reverseIcon) svg {
    margin-inline-start: calc(var(--gap) * -1);
  }
`

export const buttonOffset = css`
  margin-inline-start: calc(var(--padding) * -1);
`

// ========================
// Buttons types
// ------------------------

export const buttonText = css`
  ${buttonCommon}
  ${reverseIcon}

  height: var(--height);
  padding-inline: var(--padding);
`

export const buttonInline = css`
  ${buttonCommon}
  ${reverseIcon}
  display: inline;
  line-height: 1.5;
  padding-block: 0.5rem;
  white-space: normal;

  svg {
    margin-bottom: -1px;
    margin-inline: var(--gap) !important;
  }

  height: auto;
`

export const buttonFilled = css`
  ${buttonCommon}
  ${reverseIcon}

  background: var(--container, var(--grey));
  border-radius: 9999px;
  height: var(--height);
  padding-inline: var(--padding);
`

export const buttonIcon = css`
  ${buttonCommon}

  background: var(--container, var(--grey));
  border-radius: 50%;
  height: var(--height);
  width: var(--height);
`

// ========================
// Buttons themes
// ------------------------

export const green = css`
  --text: rgb(var(--green));

  @media (hover: hover) {
    :hover {
      --text: rgb(var(--light-black));
    }
  }

  .dark-theme & {
    --text: inherit;

    @media (hover: hover) {
      :hover {
        --text: rgb(var(--grey));
      }
    }
  }
`

export const opaqueGreen = css`
  --container: linear-gradient(45deg, rgb(var(--gradient-green-from)), rgb(var(--gradient-green-to)));
  --text: rgb(var(--pure-white));

  @media (hover: hover) {
    a:hover > &,
    :hover {
      --container: linear-gradient(45deg, rgb(var(--gradient-dark-green-from)), rgb(var(--gradient-dark-green-to)));
      --text: rgb(var(--pure-white));
    }
  }
`

export const opaqueWhite = css`
  --container: linear-gradient(45deg, rgb(var(--light-grey)), rgb(var(--pure-white)));
  --text: rgb(var(--light-black));

  @media (hover: hover) {
    :hover {
      --container: linear-gradient(45deg, rgb(var(--light-grey) / 0.5), rgb(var(--pure-white) / 0.5));
    }
  }
`

export const opaqueWhiteCfg = css`
  --container: linear-gradient(45deg, rgb(var(--light-grey)), rgb(var(--pure-white)));
  --text: rgb(var(--light-black));

  @media (hover: hover) {
    :hover {
      --container: linear-gradient(45deg, rgb(var(--light-black)), rgb(0 0 0));
      --text: rgb(var(--pure-white));
    }
  }
`

export const opaqueBlack = css`
  --container: linear-gradient(45deg, rgb(var(--light-black)), rgb(0 0 0));
  --text: rgb(var(--pure-white));

  @media (hover: hover) {
    :hover {
      --container: linear-gradient(45deg, rgb(var(--light-grey)), rgb(var(--pure-white)));
      --text: rgb(var(--light-black));
    }
  }
`

export const translucentDark = css`
  --container: rgb(var(--grey) / 0.3);
  --text: rgb(var(--light-black));

  backdrop-filter: blur(5px);

  @media (hover: hover) {
    :hover {
      --container: rgb(243 243 243 / 0.5);
      --text: rgb(var(--light-black));
    }
  }

  /* to specifically add dark theme to one button */
  &.dark-theme {
    --container: rgb(var(--dark-grey) / 0.3);
    --text: rgb(var(--pure-white));

    @media (hover: hover) {
      :hover {
        --container: rgb(84 84 84 / 0.5);
        --text: rgb(var(--pure-white));
      }
    }
  }

  .dark-theme & {
    --container: rgb(var(--dark-grey) / 0.3);
    --text: rgb(var(--pure-white));

    @media (hover: hover) {
      :hover {
        --container: rgb(84 84 84 / 0.5);
        --text: rgb(var(--pure-white));
      }
    }
  }
`

export const translucentLight = css`
  --container: rgb(var(--grey) / 0.3);
  --text: rgb(var(--light-black));

  backdrop-filter: blur(5px);

  @media (hover: hover) {
    :hover {
      --container: rgb(243 243 243 / 0.5);
      --text: rgb(var(--light-black));
    }
  }

  .dark-theme & {
    --container: rgb(var(--grey) / 0.3);
    --text: rgb(var(--pure-white));

    @media (hover: hover) {
      :hover {
        --container: rgb(84 84 84 / 0.5);
        --text: rgb(var(--pure-white));
      }
    }
  }
`
