import { useRef, useLayoutEffect } from "react"
import styled from "@emotion/styled"
import { motion, useMotionValue, animate } from "framer-motion"
import { FocusScope } from "@react-aria/focus"

import { useViewport } from "contexts/Viewport"
import { useConsole } from "contexts/Console"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import useConstant from "hooks/useConstant"
import useScrollLock from "hooks/useScrollLock"

import { useStory } from "../context"
import Close from "./ui/Close"
import Arrows from "./ui/Arrows"
import KeyCtrl from "./KeyControl"
import { getStoryIndex, clamp, getPrevSlug, getNextSlug } from "./utils"
import { PlayerProvider, usePlayer } from "./context"
import Story from "./Story"

const Root = styled(motion.div)`
  ${fullGrid}
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform-origin: center;
  z-index: 3000;
  pointer-events: none;

  grid-template-rows: [doc-start] calc(var(--outer-margin)) [close] 0px auto [doc-end];

  ${getMediaQuery("m")} {
    grid-template-rows: [doc-start] calc(var(--outer-margin) - var(--grid-gap) / 2) [close] 0px auto [doc-end];
  }
  ${getMediaQuery("l")} {
    grid-template-rows: [doc-start] calc((var(--outer-margin) - var(--grid-gap)) / 2) [close] 0px auto [doc-end];
  }
`

const SolidBg = styled.div`
  background-color: rgb(var(--light-black));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`

const InteractionZone = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: all;
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

function StoryContainer({ stories, openId }) {
  const console = useConsole()

  const viewport = useViewport()
  const { storyOpener } = useStory()

  const { slug, dragProg, isHolding, slugMap, slugList, isTouchDevice, focusable } = usePlayer()
  const scrollLock = useScrollLock()

  const ref = useRef()
  const closeRef = useRef()
  const arrowsRef = useRef()
  const arrowPrevRef = useRef()
  const arrowNextRef = useRef()

  const axis = useMotionValue("x")
  const y = useMotionValue(0)
  const isTapReady = useMotionValue(false)
  const isPanStarted = useMotionValue(false)

  const tapPt = useConstant(() => new DOMPoint(-1, -1))

  function closeStories() {
    storyOpener.set("")
  }

  function onPan(e, info) {
    isPanStarted.set(true)
    if (axis.get() === "y") {
      if (info.offset.y < 0) return
      const d = info.offset.y
      const f = 1 / (Math.abs(d) / (viewport.height.get() / 3) + 1)
      y.set(d * f)
    } else {
      dragProg.set(info.offset.x)
    }
  }

  function onPanEnd(e, info) {
    isPanStarted.set(false)
    if (axis.get() === "y") {
      if (info.velocity.y > 500) {
        closeStories()
      } else {
        animate(y, 0)
      }
      isHolding.set(false)
    } else {
      dragProg.set(info.offset.x)
      if (Math.abs(dragProg.get()) > viewport.width.get() / 5) {
        const curStoryIndex = getStoryIndex(slugMap, slug.get())
        const dir = false ? (dragProg.get() < 0 ? -1 : 1) : dragProg.get() >= 0 ? -1 : 1
        if ((curStoryIndex === 0 && dir < 0) || (curStoryIndex === slugMap.length - 1 && dir > 0)) {
          isHolding.set(false)
        } else {
          const nextindex = clamp(curStoryIndex + dir, 0, slugMap.length - 1)
          const nextSlug = slugMap[nextindex][0]
          slug.set(nextSlug)
        }
      } else {
        isHolding.set(false)
      }
    }
  }

  function onDirectionLock(dir) {
    axis.set(dir)
  }

  function onTap(e, info) {
    if (e.pointerType === "mouse" || !isTapReady.get()) return
    const onX = info.point.x >= tapPt.x - 2 && info.point.x <= tapPt.x + 2
    const onY = info.point.y >= tapPt.y - 2 && info.point.y <= tapPt.y + 2
    if (!onX || !onY) return

    const curSlug = slugList.indexOf(slug.get())
    if (info.point.x > viewport.width.get() * 0.7) {
      const nextslug = getNextSlug(slugList, slug.get())
      if (nextslug === slug.get()) {
        storyOpener.set("")
      } else {
        slug.set(nextslug)
      }
    } else if (info.point.x < viewport.width.get() * 0.3 && curSlug > 0) {
      slug.set(getPrevSlug(slugList, slug.get()))
    }
    tapPt.x = -1
    tapPt.y = -1
  }

  function onTapStart(e, info) {
    isHolding.set(true)
    tapPt.x = info.point.x
    tapPt.y = info.point.y

    isTapReady.set(true)
    setTimeout(() => isTapReady.set(false), 500)
  }

  function onPointerUp() {
    if (!isPanStarted.get()) {
      isHolding.set(false)
    }
  }

  function onKeyDown(e) {
    if (e.keyCode !== 9) return

    e.preventDefault()
    e.stopPropagation()

    const currArr = focusable.get(slug.get())
    const currIndex = currArr.indexOf(document.activeElement)
    const nextFocusIndex = !!e.shiftKey ? (currIndex <= 0 ? currArr.length - 1 : currIndex - 1) : currIndex >= currArr.length - 1 ? 0 : currIndex + 1

    currArr[nextFocusIndex].focus()
  }

  useLayoutEffect(() => {
    if (!isTouchDevice) {
      arrowPrevRef.current = arrowsRef.current.getElementsByClassName("previous")[0].getElementsByTagName("button")[0]
      arrowNextRef.current = arrowsRef.current.getElementsByClassName("next")[0].getElementsByTagName("button")[0]

      const arrFoc = [...focusable]

      arrFoc.forEach(([s, arr], i) => {
        if (i < focusable.size - 1) arr.push(arrowNextRef.current)
        if (i > 0) arr.unshift(arrowPrevRef.current)
        arr.unshift(closeRef.current)
      })

      focusable.get(slug.get())[0].focus()
    }

    const r = ref.current
    scrollLock.lock(r)
    return () => scrollLock.unlock(r)
  })

  return (
    <FocusScope contain restoreFocus autoFocus>
      <Root
        ref={ref}
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 200 }}
        transition={{ type: "tween", duration: 0.2 }}
        style={{ y }}
        onKeyDown={onKeyDown}
        aria-modal='true'
        role='dialog'
      >
        <SolidBg />
        <InteractionZone
          drag
          dragDirectionLock
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          onDirectionLock={onDirectionLock}
          onPan={onPan}
          onPanEnd={onPanEnd}
          onTap={onTap}
          onTapStart={onTapStart}
          onPointerUp={onPointerUp}
        />
        {stories.map((story, i, arr) => (
          <Story key={story.uid} {...story} indexOfStory={i} num={arr.length} openId={openId} />
        ))}
        {!isTouchDevice ? <Arrows ref={arrowsRef} /> : null}
        <Close ref={closeRef} />
        <KeyCtrl />
      </Root>
    </FocusScope>
  )
}

export default function Stories({ openId, stories, fromKeyDown }) {
  return (
    <PlayerProvider stories={stories} fromKeyDown={fromKeyDown}>
      <StoryContainer stories={stories} openId={openId} />
    </PlayerProvider>
  )
}
