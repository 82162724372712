import { useEffect, useState } from "react"
import { useConsole } from "contexts/Console"
import { AnimatePresence } from "framer-motion"

import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import { useHeader } from "contexts/Header"
import { useDictionary } from "contexts/Dictionary"

import { useActions } from "./hooks/useActions"

import { Icon } from "components/icon/Icon"
import { InnerList, FlexLi, ResultLine, TitleLine } from "../store-locator/components/Search/SuggestionsList/styles"
import { Root, SuggList } from "./styles/suggestions"
import { SearchContainer, EraseButton } from "../store-locator/components/Search/styles"
import { SearchRoot, SearchButton, SearchInput } from "./styles/searchBox"
import { useSearch, SEARCH_RESULTS_PAGE_SLUG } from "./context"

export const SearchBox = props => {
  const { controller, inputRef } = props
  const console = useConsole()
  const [state, setState] = useState(process.browser && controller.state)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const navigation = useNavigation()
  const header = useHeader()
  const locale = useLocale()
  const dictionary = useDictionary()

  const {
    activeFilter,
    setActiveFilter,
    queryValue,
    setQueryValue,
    setIsloading,
    setGroupedResults,
    setLastSearchValue,
    setValueWasCleared,
    isOnSearchResultsPage,
  } = useSearch()
  const { searchAllFilters, updateQueryWithValue } = useActions()

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller])

  useEffect(() => {
    //clean up search when leave search results page
    if (!navigation.slug.includes(`/${SEARCH_RESULTS_PAGE_SLUG}`)) {
      clearCurrentSearch()
      setLastSearchValue("")
    }
  }, [navigation.slug])

  useEffect(() => {
    if (!state?.suggestions) {
      return
    }
  }, [state?.suggestions])

  const localeCode = locale?.current?.codes?.www == locale.defaultLocale.codes.www ? "" : `${locale?.current?.codes?.www}/`

  const suggVariants = {
    hidden: {
      height: 0,
    },
    visible: {
      height: "auto",
      transition: {
        staggerChildren: 0.07,
      },
    },
  }
  const childVariants = {
    hidden: {
      opacity: 0,
      x: -10,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  }

  const handleSubmit = async e => {
    e.preventDefault()
    window.scrollTo(0, 0)
    setShowSuggestions(false)
    header.forceUnlock()

    setLastSearchValue(queryValue)

    if (isOnSearchResultsPage) {
      setIsloading(true)

      const groupedResults = await searchAllFilters(queryValue)
      setGroupedResults(groupedResults)
      setActiveFilter("all")

      updateUrlParamater(queryValue)

      setIsloading(false)
    } else {
      setActiveFilter("all")
      setLastSearchValue("")

      navigation.push(`/${localeCode}${SEARCH_RESULTS_PAGE_SLUG}?q=${queryValue}`)
    }
  }

  const handleClickOnSuggestion = async value => {
    setShowSuggestions(false)
    header.forceUnlock()

    setLastSearchValue(value)

    if (isOnSearchResultsPage) {
      setIsloading(true)

      if (activeFilter === "all") {
        const groupedResults = await searchAllFilters(value)
        setGroupedResults(groupedResults)
      } else {
        //query will be launched on filter change in "results"
        setGroupedResults(null)
        setActiveFilter("all")
      }

      updateUrlParamater(value)

      setIsloading(false)
    } else {
      setLastSearchValue("")
      navigation.push(`/${localeCode}${SEARCH_RESULTS_PAGE_SLUG}?q=${value}`)
    }
  }

  const clearCurrentSearch = async () => {
    setShowSuggestions(false)
    await updateQueryWithValue("")
    setQueryValue("")
    controller.clear()
    setValueWasCleared(true)
    // setActiveFilter("all")
  }

  const handleChange = e => {
    state?.suggestions?.length > 0 && setShowSuggestions(true)
    setValueWasCleared(false)
    controller.updateText(e.target.value)
    setQueryValue(e.target.value)
  }

  const updateUrlParamater = value => {
    if (process.browser) {
      const url = new URL(window.location)
      url.searchParams.set("q", value)
      window.history.pushState({}, "", url)
    }
  }

  console.verbose("SearchBox(%o)", props)

  return (
    <SearchRoot>
      <SearchContainer>
        <SearchInput
          id='search-input'
          ref={inputRef}
          tabindex='0'
          placeholder={dictionary.search()}
          value={state?.value ?? ""}
          onChange={e => handleChange(e)}
          onKeyDown={e => e.key === "Enter" && handleSubmit(e)}
          autoFocus
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          type='text'
          maxLength='1010'
          enterKeyHint='search'
          aria-label={dictionary.searchAria()}
        />
        {queryValue && (
          <EraseButton aria-label={dictionary.clearSearch()} onClick={clearCurrentSearch} className={state?.value ? "visible" : ""}>
            <Icon type='close' />
          </EraseButton>
        )}

        <SearchButton onClick={handleSubmit} aria-label={dictionary.validateSearch()}>
          <Icon type='arrowRight' viewBox='0 0 15 15' />
        </SearchButton>
      </SearchContainer>
      {/* //todosearch: suggestions in separate component */}
      <AnimatePresence>
        {showSuggestions && state.suggestions.length && (
          <Root initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SuggList>
              <TitleLine variants={childVariants}>{dictionary.suggestions()}</TitleLine>
              <InnerList initial='hidden' animate={showSuggestions ? "visible" : "hidden"} variants={suggVariants}>
                {state.suggestions.map(suggestion => {
                  return (
                    <FlexLi key={suggestion.rawValue} variants={childVariants}>
                      <ResultLine
                        onClick={() => handleClickOnSuggestion(suggestion.rawValue)}
                        dangerouslySetInnerHTML={{ __html: suggestion.highlightedValue }}
                      ></ResultLine>
                    </FlexLi>
                  )
                })}
              </InnerList>
            </SuggList>
          </Root>
        )}
      </AnimatePresence>
    </SearchRoot>
  )
}
