import { useState, useLayoutEffect, useRef, useEffect, memo } from "react"
import styled from "@emotion/styled"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import hash from "@emotion/hash"
import { colors, pageGradientVar } from "./../model/colors"

const Main = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  transform-style: preserve-3d;
  ${colors}

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    transform: translateZ(0);
    will-change: opacity, visibility;
    animation-fill-mode: both;
    animation-name: wvwbgfadeout;
    animation-duration: 0ms;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
    animation-delay: 400ms;
    &.wv_reveal {
      animation-name: wvwbgfadein;
      animation-duration: 400ms;
      animation-delay: 0ms;
    }
    background-image: var(${pageGradientVar});
  }

  &.wv_straight {
    > div {
      visibility: hidden;
      animation-delay: 0ms;
      &.wv_reveal {
        visibility: visible;
      }
    }
  }

  @keyframes wvwbgfadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes wvwbgfadeout {
    0% {
      opacity: initial;
    }
    100% {
      opacity: 0;
    }
  }
`

const CfgBackground = memo(({ uid }) => {
  return <div className={`pcs${hash(uid)}`}></div>
})

function buildZIndexes(arr) {
  let k = 1
  return arr.reduce((a, item) => {
    a[item] = k++
    return a
  }, Object.create(null))
}

function firstPos(arr, item) {
  const index = arr.indexOf(item)
  if (index < 0) return
  arr.splice(index, 1)
  arr.unshift(item)
}

function animate(list, selected, zIndexes) {
  list.forEach((el, i) => {
    el.style.zIndex = zIndexes[i]
    el.classList[i === selected ? "add" : "remove"]("wv_reveal")
  })
}

function useHighlight(items, rf, model, getModel, restore, lastStep, swapping, fromlaststep) {
  const listRef = useRef(null)
  const colRef = useRef(null)
  const rfpassed = useRef(false)

  function update(model) {
    const m = getModel(model)
    if (rfpassed.current) rf.current.classList.remove("wv_straight")
    const current = listRef.current
    if (!current) return
    const currentbg = !m ? null : lastStep() && !restore.get() ? m.color_set : m.material_id
    const el = items.find(item => item === currentbg)
    const index = items.indexOf(el)
    firstPos(current, index)
    const zIndexes = buildZIndexes(current.slice().reverse())
    animate(colRef.current, index, zIndexes)
    if (!!m) rfpassed.current = true
  }

  function onSwapping(v) {
    if (v) return
    update(model.get())
  }

  function onFromLastStep(v) {
    if (v) return
    update(model.get())
  }

  useLayoutEffect(() => mvSubscribe(swapping, onSwapping), [])
  useLayoutEffect(() => mvSubscribe(fromlaststep, onFromLastStep, false), [])

  useLayoutEffect(() => {
    if (!items) return
    listRef.current = items.map((v, i) => i)
    colRef.current = [...rf.current.querySelectorAll("div")]

    return mvSubscribe(model, update)
  }, [items])
}

export const CfgBackgrounds = memo(({ Ctx }) => {
  const { model, getModel, restore, lastStep, swapping, fromlaststep } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const all = Array.from({ length: 18 }).map((v, i) => {
    return `page-color-set-rolex-v7-color-${(i + 1).toString().padStart(3, "0")}`
  })
  const [items] = useState(all)

  useHighlight(items, rfmain, model, getModel, restore, lastStep, swapping, fromlaststep)

  function kill() {
    return true
  }

  useEffect(() => kill)

  return (
    <Main ref={rfmain} className='wv_straight'>
      {items?.map(v => (
        <CfgBackground uid={v} key={v} />
      ))}
    </Main>
  )
})
