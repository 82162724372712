import { useEffect, useLayoutEffect, useState, useRef } from "react"
import styled from "@emotion/styled"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { CfgNavigation } from "./navigation"
import { CfgBackgrounds } from "./backgrounds"
import { CfgSteps } from "./steps"
import { CfgActiveWishlist } from "./active"
import { CfgRoller } from "./dials"
import { CfgCanvas } from "./canvas"
import { CfgLauncher } from "./launcher"
import { CfgLauncher as CfgLauncherb64 } from "./launcher.b64"
import { CFGV7 } from "./context"
const { STEPS_LENGTH } = CFGV7

const Contents = styled.section`
  position: relative;
  justify-content: end;
  align-items: start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.dark-theme {
    background-color: black;
  }
  &.light-theme {
    background: linear-gradient(to top, #f0f0f0, #fff);
  }

  & > div {
    position: relative;
    height: 100%;

    &.cfgv7_frozen {
      pointer-events: none;
    }
  }

  @keyframes wvreveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const CfgLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  //  min-height: 100vh;
  justify-content: end;
  padding: 0 0 3vh;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > section {
    position: relative;
  }
`

const Lock = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.cfgv7_frozen {
    display: none;
  }
`

export function CfgContents({ Ctx }) {
  const { step } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const rfupper = useRef()

  function print(v) {
    //    console.log(">>> print", v)
    const { current: main } = rfmain
    if (!main) return
    Array.from({ length: STEPS_LENGTH + 1 }).map((v, i) => {
      main.classList.remove(`cfgv7_step_${i}`)
    })
    main.classList.add(`cfgv7_step_${v}`)
    if (v === STEPS_LENGTH) main.classList.add(`cfgv7_laststep`)
  }

  //  useEffect(() => mvSubscribe(fetching, init), [])
  useEffect(() => mvSubscribe(step, print), [])
  //  useEffect(() => mvSubscribe(swapping, update, false), [])
  /*
  const b64 = window?.location?.hash?.includes("b64")

  return (
    <Contents ref={rfupper} className={`cfgv7Contents dark-theme`}>
      <div ref={rfmain}>
        <CfgBackgrounds Ctx={Ctx} />
        <CfgCanvas Ctx={Ctx} />
        <CfgRoller Ctx={Ctx} />
        <CfgLayout>
          <CfgActiveWishlist Ctx={Ctx} />
          <CfgSteps Ctx={Ctx} />
          <CfgNavigation Ctx={Ctx} />
        </CfgLayout>
      </div>
      {b64 ? <CfgLauncherb64 Ctx={Ctx} /> : <CfgLauncher Ctx={Ctx} />}
    </Contents>
  )
  */
  return (
    <Contents ref={rfupper} className={`cfgv7Contents dark-theme`}>
      <div ref={rfmain}>
        <CfgBackgrounds Ctx={Ctx} />
        <CfgCanvas Ctx={Ctx} />
        <CfgRoller Ctx={Ctx} />
        <CfgLayout>
          <CfgActiveWishlist Ctx={Ctx} />
          <CfgSteps Ctx={Ctx} />
          <CfgNavigation Ctx={Ctx} />
        </CfgLayout>
      </div>
      <CfgLauncher Ctx={Ctx} />
    </Contents>
  )
}
