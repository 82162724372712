import { createContext, useContext } from "react"
import { useMotionValue, transform } from "framer-motion"
import easing from "./easing"

export const CFGV7 = (function computeConfigurator() {
  const MAX_FRAMES = 250
  const DIAL_OFFSET_Y = { portrait: -30, portrait_fat: -30, landscape: -20 }
  const DIAL_SCALE = { portrait: 80, portrait_fat: 60, landscape: 65 }
  const STEPS = ["flagship", "model", "size", "material", "bezel", "bracelet", "dial"]
  const STEPS_LENGTH = STEPS.length - 1

  const QUALITYSIZE = {
    /* Quality format q[value] */
    Q: {
      hi: [100, 100],
      lo: [20, 15],
      start: [100, 100],
    },
    /* Size format [value]x[value] */
    S: {
      hi: [1440, 960],
      lo: [960, 960],
      start: [1440, 1440],
    },
  }

  const PK20_SCALE = { portrait: 1 / 1.1, landscape: 4 / 3 }
  const PK20_OFFSET_X = { portrait: -0.2, landscape: -0.5 }
  const PK20_OFFSET_Y = { portrait: 4.6, landscape: 6 }
  const PK20_RATIO = 800 / 1180
  const SHADOW_RATIO = 1180 / 1380
  const PK20_BOUNCE = 0.9

  const FRAMES = new Map([
    [
      "flagship",
      {
        i: 58,
        x: "0/-17",
        y: "10/5",
        s: "1.7/3.2",
        r: "82",
      },
    ],
    [
      "model",
      {
        i: 235,
        x: "-5/-10",
        y: "-10/-15",
        s: "1.1/1.3",
        r: "16",
      },
    ],
    [
      "size",
      {
        i: 25,
        x: "10/5",
        y: "-5/-15",
        s: "0.95/1.1",
        r: "348",
      },
    ],
    [
      "material",
      {
        i: 25,
        x: "5",
        y: "-5/-10",
        s: "1.3/1.5",
        r: "70",
      },
    ],
    [
      "bezel",
      {
        i: 235,
        x: "0/-10",
        y: "-2/-10",
        s: "1.5/1.6",
        r: "327",
      },
    ],
    [
      "bracelet",
      {
        i: 132,
        x: "15",
        y: "-15",
        s: "1.6",
        r: "10",
      },
    ],
    [
      "dial",
      {
        i: 249,
        x: `${PK20_OFFSET_X.landscape / PK20_SCALE.landscape}/${PK20_OFFSET_X.portrait / PK20_SCALE.portrait}`,
        y: `${(DIAL_OFFSET_Y.landscape * PK20_SCALE.landscape) / PK20_BOUNCE + PK20_OFFSET_Y.landscape / PK20_SCALE.landscape}/${(DIAL_OFFSET_Y.portrait * PK20_SCALE.portrait) / PK20_BOUNCE + PK20_OFFSET_Y.portrait / PK20_SCALE.portrait
          }`,
        s: `${(DIAL_SCALE.landscape * PK20_BOUNCE) / (100 * PK20_SCALE.landscape)}/${(DIAL_SCALE.portrait * PK20_BOUNCE) / (100 * PK20_SCALE.portrait)}`,
        r: "0",
      },
    ],
  ])

  const SPECIFICS = new Map([
    /*  [
      "flagship,material",
      {
        inc: 1,
      },
    ],*/
    [
      "flagship,model",
      {
        p: 1,
      },
    ],
    [
      "material,bracelet",
      {
        p: 0.5,
      },
    ],
    [
      "bracelet,material",
      {
        p: 0.5,
      },
    ],
    [
      "bezel,bracelet",
      {
        p: 0.5,
      },
    ],
    [
      "bracelet,bezel",
      {
        p: 0.5,
      },
    ],
    [
      "size,material",
      {
        p: -50,
      },
    ],
    [
      "material,size",
      {
        p: -50,
      },
    ],
    [
      "size,dial",
      {
        p: 2,
        e: "easeInQuad",
      },
    ],
    [
      "dial,size",
      {
        p: 2,
      },
    ],
    [
      "model,dial",
      {
        p: 2,
        e: "easeInQuad",
      },
    ],
    [
      "dial,model",
      {
        p: 2,
      },
    ],
    [
      "bracelet,dial",
      {
        p: 0.5,
        e: "easeInQuad",
      },
    ],
    [
      "dial,bracelet",
      {
        p: 0.5,
      },
    ],
    [
      "material,dial",
      {
        p: 1.2,
        e: "easeInQuad",
      },
    ],
    [
      "dial,material",
      {
        p: 1.2,
      },
    ],
    [
      "bezel,dial",
      {
        p: 3,
        e: "easeInQuad",
      },
    ],
    [
      "dial,bezel",
      {
        p: 3,
      },
    ],
  ])

  const PATHS = new Map([
    [
      "cosmograph-daytona",
      new Map([
        ["5,6", 1],
        ["6,5", 1],
      ]),
    ],
    [
      "sky-dweller",
      new Map([
        ["5,6", 1],
        ["6,5", 1],
      ]),
    ],
    [
      "lady-datejust",
      new Map([
        ["4,5", 1],
        ["5,4", 1],
        ["5,6", 1],
        ["6,5", 1],
      ]),
    ],
    //  ["gmt-master-ii", new Map([["5,6", 0]])],
    [
      "day-date",
      new Map([
        ["4,5", 1],
        ["5,4", 1],
        ["5,6", 1],
        ["6,5", 1],
      ]),
    ],
    [
      "datejust",
      new Map([
        ["4,5", 1],
        ["5,4", 1],
        ["5,6", 1],
        ["6,5", 1],
      ]),
    ],
  ])

  const PARAMS = compute()

  return {
    MAX_FRAMES,
    STEPS,
    STEPS_LENGTH,
    PATHS,
    PARAMS,
    QUALITYSIZE,
    DIAL_OFFSET_Y,
    DIAL_SCALE,
    PK20_RATIO,
    SHADOW_RATIO,
  }

  function getOutrange(sfrom, sto, v) {
    let from_split = sfrom[v].split("/")
    if (from_split.length < 2) from_split[1] = from_split[0]
    let to_split = sto[v].split("/")
    if (to_split.length < 2) to_split[1] = to_split[0]
    let ret = []
    for (let i = 0; i < 2; i++) {
      let from = +from_split[i]
      let to = +to_split[i]
      if (v !== "r") ret[i] = [from, to]
      else {
        let diff = Math.abs(from - to + 1)
        let dir = diff <= 180 ? 1 : -1
        if (dir < 0) {
          if (to > from) from += 360
          else to += 360
        }
        ret[i] = [from, to]
      }
    }
    return ret
  }

  function getSequence(sfrom, sto, straight) {
    let loframes = []
    let hiframes = []
    let dir = 0
    const last = sto === "dial"
    const spe = SPECIFICS.get(`${sfrom},${sto}`)
    const pitch = spe?.p || 1
    const ease = spe?.e || "linear"
    //  let truerange = inc < 0 ? -inc : 0
    //  if (truerange > 0) inc = 1
    //    console.log(`${sfrom},${sto}`)
    sfrom = FRAMES.get(sfrom)
    sto = FRAMES.get(sto)
    let diff = Math.abs(sfrom.i - sto.i)
    let shortest = diff <= MAX_FRAMES / 2
    shortest = shortest && straight
    if (shortest) {
      dir = 1
      let indexfrom = Math.min(sfrom.i, sto.i)
      loframes = Array.from({ length: diff + 1 }).map((v, i) => i + indexfrom)
    } else {
      dir = -1
      diff = MAX_FRAMES - diff
      let from = sfrom.i < sto.i ? sfrom.i + MAX_FRAMES : sfrom.i
      let to = sfrom.i < sto.i ? sto.i : sto.i + MAX_FRAMES
      let indexfrom = Math.min(from, to)
      //    console.log(from, to, from - to, diff)
      loframes = Array.from({ length: diff + 1 }).map((v, i) => (i + indexfrom) % MAX_FRAMES)
    }
    //    console.log(dir, loframes)
    loframes = loframes.slice(1, -1)
    hiframes.push(sfrom.i)
    if (!last) hiframes.push(sto.i)
    else loframes.push(sto.i)
    let ifrom = sfrom.i
    let ito = sto.i
    if (dir < 0) {
      if (ito > ifrom) ifrom += MAX_FRAMES
      else ito += MAX_FRAMES
    }
    let inrange = [ifrom, ito]
    //  console.log(inrange)
    let range = pitch < 0 ? -pitch : pitch * Math.abs(ifrom - ito)
    //  console.log(range)
    let outrange
    let trans = "i,x,y,s,r".split(",").reduce(
      (a, v) => {
        outrange = v === "i" ? [inrange, inrange] : getOutrange(sfrom, sto, v)
        if (v !== "i") {
          //      inrange = [ifrom + 2 * dir, ito - 2 * dir]
        }
        for (let i = 0; i < 2; i++) a[i][v] = transform([0, range], outrange[i], { ease: easing[ease] })
        //a[i][v] = `transform(${trange}, ${outrange[i]}, { ease: easing.linear })`
        return a
      },
      [Object.create(null), Object.create(null)]
    )
    if (sfrom === sto) {
      loframes = hiframes = []
    }
    loframes = loframes.join(",")
    hiframes = hiframes.join(",")
    //  loframes = loframes.concat(hiframes).join(",")
    //  hiframes = [].join(",") //hiframes.join(",")
    //  let range = trange
    //  let fromto = `${ifrom},${ito}`
    return { range, trans, loframes, hiframes }
  }

  function compute() {
    const ret = new Map()
    let sid
    const allsequencesfrom = [...FRAMES.keys()]
    while (allsequencesfrom.length) {
      let allsequencesto = [...FRAMES.keys()]
      let sfrom = allsequencesfrom.shift()
      //    if (sfrom === "dial") continue
      //    if (sfrom === "dial") continue
      while (allsequencesto.length) {
        let sto = allsequencesto.shift()
        //      if (sto === "flagship") continue
        if (sto === sfrom) sid = STEPS.indexOf(sfrom)
        else sid = `${STEPS.indexOf(sfrom)},${STEPS.indexOf(sto)}`
        //      console.log("sid", sid)
        ret.set(sid, [getSequence(sfrom, sto, true), getSequence(sfrom, sto, false)])
      }
    }
    return ret
  }
})()

//console.log("cfgv7", CFGV7)

export const Context = createContext()

export const ConfiguratorProvider = ({ children }) => {
  const route = useMotionValue(null)
  const launched = useMotionValue(true)
  const fromui = useMotionValue(false)

  return <Context.Provider value={{ route, launched, fromui }}>{children}</Context.Provider>
}

export const useConfigurator = () => useContext(Context)
