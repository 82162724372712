import { useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useRollerAnimation } from "./RollerAnimation"
import { mvSubscribe } from "./utils"
import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"
import { useDictionary } from "contexts/Dictionary"

const Main = styled.nav`
  position: absolute;
  visibility: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;

  @media (hover: hover) and (pointer: fine) {
    display: flex;
  }

  > button {
    --arrow-size: 40px;
    ${getMediaQuery("m")} {
      --arrow-size: 44px;
    }
    ${buttonIcon}
    ${translucentDark}

    pointer-events: all;
    display: flex;
    border: 0;
    border-radius: 50%;

    width: var(--arrow-size);
    height: var(--arrow-size);

    &.previous {
      justify-self: start;
    }
    &.next {
      justify-self: end;
    }
    &[aria-disabled="true"] {
      visibility: hidden;
    }
    &.previous svg {
      transform: scale(-1);
    }
    [dir="rtl"] &.previous svg {
      transform: scale(1);
    }
    [dir="rtl"] &.next svg {
      transform: scale(-1);
    }
  }
`

export function CfgArrows({ ctx }) {
  const { rmfocus, focus, count } = useRollerAnimation(ctx)
  const rfmain = useRef(null)
  const dictionary = useDictionary()

  function update(v) {
    rmfocus.set(focus.get() + v)
  }

  function onPrevious(e) {
    update(-1)
  }

  function onNext(e) {
    update(1)
  }

  function onFocus(v) {
    let col = [...rfmain.current?.querySelectorAll("button")]
    if (!col) return
    col.forEach((el, i) => {
      let state = (i === 0 && v <= 0) || (i === 1 && v >= count.get() - 1)
      el.setAttribute("aria-disabled", state)
    })
  }

  function onCount(v) {
    onFocus(focus.get())
  }

  useLayoutEffect(() => mvSubscribe(focus, onFocus, false), [])
  useLayoutEffect(() => mvSubscribe(count, onCount), [])

  return (
    <Main ref={rfmain}>
      <button className='previous' aria-label={dictionary.previous()} onClick={onPrevious}>
        <Icon type={"moveNext"} />
      </button>
      <button className='next' aria-label={dictionary.next()} onClick={onNext}>
        <Icon type={"moveNext"} />
      </button>
    </Main>
  )
}
