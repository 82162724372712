import { useEffect, useState, useRef, memo, useLayoutEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useTransform } from "framer-motion"
import { useFrameAnimation } from "./FrameAnimation"
import { CFGV7 } from "./context"
import { rafDelay, mvSubscribe } from "./utils"
import { headline70, headline100, body50, body70, bold, normal, legend100 } from "css/text"
import { buttonText, buttonReset, buttonIcon } from "css/buttons"
import { CfgActive } from "./active"
import { useDictionary } from "contexts/Dictionary"
import { useFocusManager } from "react-aria"
import camelCase from "./../../utils/camelCase"
const { STEPS, STEPS_LENGTH } = CFGV7

const SWAP_IN = css`
  transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

const Links = styled.section`
  position: relative;
`

const Main = styled.section`
  position: relative;
  z-index: 4;
  flex: 1;
  align-self: end;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: white;
  flex: 0;
  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }

  & > h2 {
    padding: 0 var(--outer-margin) 0.5rem;
    box-sizing: border-box;
    //    line-height: 1;
    //    max-width: 40%;
    @media (max-width: 479px) {
      //      max-width: 100%;
    }
    ${body50}
    ${bold}

    ${SWAP_OUT}
    &.wv_reveal {
      ${SWAP_IN}
    }

    & > span {
      display: block;
      ${headline70}
    }
  }

  & > section {
    &.wv_reveal {
      ul {
        ${SWAP_IN}
      }
    }
  }

  & ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: auto;
    pointer-events: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      flex: 0 0 calc(var(--outer-margin) - 20px);
    }

    ${SWAP_OUT}

    & li {
      //      flex: 0;
      & span {
        display: block;
        .dark-theme & {
          color: rgb(var(--pure-white));
        }
        .light-theme & {
          color: rgb(var(--light-black));
        }
      }
      div {
        position: relative;
        padding-block: 5px;
        //        overflow: hidden;
        label {
          font-size: 16px;
          ${bold}
          padding-block: 3px;
          padding-inline: 20px;
          white-space: nowrap;
          cursor: pointer;
          opacity: 0.5;
          transition: color 0.3s, opacity 0.3s;
          .dark-theme & {
            color: rgb(var(--pure-white));
          }
          .light-theme & {
            color: rgb(var(--light-black));
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              opacity: 1;
            }
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            margin-block-start: -2px;
            margin-inline-start: 8px;
            width: 6px;
            height: 6px;
            border-radius: 8px;
            .dark-theme & {
              background: rgb(var(--pure-white));
            }
            .light-theme & {
              background: rgb(var(--green));
            }
            opacity: 0;
            transition: opacity 0.5s;
          }
        }
        input {
          position: absolute;
          top: -10px;
          left: 0px;
          opacity: 0;

          &:checked + label {
            opacity: 1;
            &::after {
              opacity: 1;
            }
          }

          &.focus-visible + label {
            outline: 2px solid rgb(var(--focus, 0 255 255) / 1);
            outline-offset: 0px !important;
          }
        }
      }
      & button {
        position: relative;
        ${buttonReset}
        font-size: 16px;
        ${bold}
        padding-block: 6px;
        padding-inline: 20px;
        white-space: nowrap;
        cursor: pointer;
        opacity: 0.5;
        transition: color 0.3s, opacity 0.3s;
        .dark-theme & {
          color: rgb(var(--pure-white));
        }
        .light-theme & {
          color: rgb(var(--light-black));
        }
        &.focus-visible {
          opacity: 1;
          outline-offset: 0px !important;
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
          }
        }
        &[aria-current="true"] {
          cursor: default !important;
          opacity: 1;
          &::after {
            opacity: 1;
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          margin-block-start: -2px;
          margin-inline-start: 8px;
          width: 6px;
          height: 6px;
          border-radius: 8px;
          .dark-theme & {
            background: rgb(var(--pure-white));
          }
          .light-theme & {
            background: rgb(var(--green));
          }
          opacity: 0;
          transition: opacity 0.5s;
        }
      }
    }
  }
`

/*

const Label = styled.label`
  display: block;
  font-size: 16px;
  line-height: 80px;
  ${bold}
  cursor: pointer;
  user-select: none;
  transition: color 0.3s;
  overflow: hidden;

  &:hover {
    color: rgb(var(--rolex-green));
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-block-start: -4px;
    margin-inline-start: 8px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: rgb(var(--rolex-green));
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${getMediaQuery("xl")} {
    &:after {
      margin-inline-start: 6px;
    }
  }
`
const Input = styled.input`
  position: absolute;
  top: -10px;
  left: -10px;
  opacity: 0;

  &:checked + label {
    color: rgb(var(--rolex-green));

    &:after {
      opacity: 1;
    }
  }

  &.focus-visible + label {
    outline: 2px solid rgb(var(--focus, 0 255 255) / 1);
    outline-offset: -2px;
  }
`

*/

function novars(v) {
  return !v || v.indexOf(",") < 0
}

const CfgStepTitle = memo(({ Ctx }) => {
  const { getModel, model, step, swapping, reset } = useFrameAnimation(Ctx)
  const [label, setLabel] = useState(null)
  const rfmain = useRef()
  const dictionary = useDictionary()
  const _label = useTransform([model, step], update)

  function update([model, step]) {
    if (step === 0 || !model) return null
    const category = STEPS[step]
    let title = dictionary[camelCase(`configurator-title-${category}`)]("<span>", "</span>")
    const vars = getModel(model)?.[`variations_${category}`]
    if (novars(vars)) title = null
    return title
  }

  function render(v) {
    if (v) return display(false)
    const label = _label.get()
    setLabel(label)
    rafDelay(() => display(!!label))
  }

  function display(state) {
    //    state = true
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(swapping, render, false), [])

  function kill() {
    setLabel(null)
  }

  useEffect(() => kill, [])
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  return <h2 ref={rfmain} dangerouslySetInnerHTML={{ __html: label }}></h2>
})

const CfgStepLink = memo(({ Ctx, rmc, label, index, step }) => {
  const { model } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const [chkd, setChkd] = useState(false)

  function onChange(e) {
    model.set(rmc)
  }

  function onModel(v) {
    setChkd(rmc === v)
  }

  useLayoutEffect(() => mvSubscribe(model, onModel), [])

  useLayoutEffect(() => {
    const { current: input } = rfmain
    if (!input) return

    if (chkd) {
      input.checked = true
      input.value = rmc
      input.focus()
    }
  }, [chkd])

  return (
    <div>
      <input ref={rfmain} type='radio' id={`cfg-step-radio-${index}`} name={`cfg-step-${step}`} value={rmc} onChange={onChange} tabIndex={chkd ? 0 : -1} />
      <label htmlFor={`cfg-step-radio-${index}`}>{label}</label>
    </div>
  )
})

const CfgStepLinks = memo(({ Ctx }) => {
  const { model, getModel, step, modellist, reset, lastStep, rolling, swapping } = useFrameAnimation(Ctx)
  const [items, setItems] = useState([])
  const rfmain = useRef()
  const focusManager = useFocusManager()

  function update() {
    let stepindex = step.get()
    if (!modellist.get() || stepindex <= 0 /* || stepindex >= STEPS_LENGTH - 1*/) return setItems(null)
    let _step = STEPS[stepindex]
    setItems(
      stepindex < STEPS_LENGTH
        ? modellist
          .get()
          .split(",")
          .map(getModel)
          .map((v, i) => ({
            rmc: v.rmc,
            step: _step,
            index: i,
            label: v[_step],
          }))
        : null
    )
  }

  function render(v) {
    if (v) return display(false)
    //    setItems(rfstack.current)
    if (lastStep() && items?.[0]?.novars) {
      rolling.set(0)
      return display(false)
    }
    rafDelay(() => {
      display(true)
    })
  }

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    if (!state || step.get() === 6) return
    const R = rfmain.current?.getBoundingClientRect()
    const el = rfmain.current?.querySelector("input[tabIndex='0']")
    if (!el) return
    const r = el?.parentNode?.getBoundingClientRect()
    if (R && r && (r.right > R.right)) rfmain.current.querySelector("ul").scrollLeft = r.right - R.right + 20
    setTimeout(() => {
      focusManager.focusNext({ from: el.parentNode, wrap: true })
    }, 40)
  }

  useEffect(() => mvSubscribe(step, update, false), [])
  useEffect(() => mvSubscribe(modellist, update, false), [])
  useEffect(() => mvSubscribe(swapping, render, false), [])

  function kill() {
    setItems(null)
  }

  useEffect(() => kill, [])
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  return (
    <Links ref={rfmain}>
      <ul>
        {items?.map(item => (
          <li key={item.rmc}>
            <CfgStepLink Ctx={Ctx} {...item} />
          </li>
        ))}
      </ul>
    </Links>
  )
})

export const CfgSteps = memo(({ Ctx }) => {
  return (
    <Main>
      <CfgStepTitle Ctx={Ctx} />
      <CfgStepLinks Ctx={Ctx} />
      <CfgActive Ctx={Ctx} />
    </Main>
  )
})
