import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { mvSubscribe } from "./utils"
import useSSR from "hooks/useSSR"
import { useConfigurator } from "./context"
import Configurator from "./index"
import { useLocale } from "contexts/Locale"

export default function ConfiguratorPlayer({ initialState }) {
  const ssr = useSSR()
  const [active, setActive] = useState(null)
  const { route } = useConfigurator()
  const locale = useLocale()
  //  console.log("ConfiguratorPlayerConfiguratorPlayer initialState", initialState)
  function kill() {
    route.set(null)
  }

  function onRoute(v) {
    if (!v) {
      setActive(null)
      return
    }
    const families = initialState[locale.current.codes.www]?.configurator?.[0]?.families
    let items = families.find(f => f.family_code === v)?.params

    const cfgprops = {
      bkgmode: "linear",
      items: JSON.stringify(items),
      kill: kill,
    }
    setActive(<Configurator {...cfgprops} />)
  }

  useEffect(() => mvSubscribe(route, onRoute), [])
  /*
  function onHashChange(e) {
    let _hash = window.location.hash.split("").slice(1).join("")
    if (_hash.indexOf("cfgv7=") === 0) {
      let [, cat] = _hash.split("=")
      route.set(cat)
    } else route.set(null)
  }

  useEffect(() => {
    if (!process.browser) return
    window.addEventListener("hashchange", onHashChange, false)

    return () => window.removeEventListener("hashchange", onHashChange, false)
  }, [])
*/
  return !ssr && active ? createPortal(active, document.body) : null
}
