import { Provider } from "contexts/User"
import { useConsole } from "contexts/Console"
import useTopDomain from "hooks/useTopDomain"

export default function User({ children, reqcontext = {} }) {
  const console = useConsole()
  const topDomain = useTopDomain()

  const ctx = {
    ...reqcontext,
    get locale() {
      return "x"
    },
    set locale(code) {
      const cookie = [
        `__usr-locale=${code}`,
        `domain=${topDomain}`,
        `path=/`,
        `expires=${+new Date() + 31891878000}`,
        `SameSite=Strict`,
        global.location.protocol === "https:" ? "Secure" : null,
      ]
        .filter(Boolean)
        .join("; ")

      console.verbose("User:locale(%s) [%s]", code, cookie)
      document.cookie = cookie
    },
  }

  console.verbose("User(%o)", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
