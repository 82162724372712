import { createContext, useContext, useState } from "react"

import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { useSuspender } from "contexts/Suspender"
import { useNavigation } from "contexts/Navigation"

export const PopinContext = createContext({})
export function usePopin() {
  return useContext(PopinContext)
}

async function load(path, { console }) {
  return Promise.resolve(new URL(path, process.browser ? global.location.origin : process.env.REVERSE_PROXY_URL))
    .then(url => new Request(url))
    .then(request => fetch(request))
    .then(response => response.json())
    .then(body => [null, body])
    .catch(err => [err, {}])
}

export const PopinContextProvider = ({ children, uid, preload /*background, components*/ }) => {
  const console = useConsole()
  const locale = useLocale()
  const navigation = useNavigation()
  const path = navigation.localize(`/popin/${uid}.extra.json`, locale.current.codes.www)
  const [[err, preloaded], preloadSSR] = useSuspender(["pop", path], preload ? () => load(path, { console }) : () => Promise.resolve([null, {}]), preload)
  const [JITData, setJITData] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(false)
  }

  const onOpen = async () => {
    if (!preload) {
      const [err, body] = await load(path, { console })
      if (err) console.error(err)
      setJITData(body)
    }
    setIsOpen(true)
  }

  const onOverlayClick = evt => {
    if (evt.clientY >= window.innerHeight / 10 && evt.currentTarget !== evt.target) return
    setIsOpen(false)
  }

  const ctx = {
    ...preloaded,
    ...JITData,
    isOpen,
    onOpen,
    onClose,
    onOverlayClick,
  }

  console.verbose("PopinContext(%o)", { uid, preloaded })
  return (
    !err && (
      <>
        {preloadSSR}
        <PopinContext.Provider value={ctx}>{children}</PopinContext.Provider>
      </>
    )
  )
}
