import { useRef, useId, useState } from "react"
import { createPortal } from "react-dom"
import { AnimatePresence } from "framer-motion"
import { useLink } from "@react-aria/link"
import styled from "@emotion/styled"

import { useNavigation } from "contexts/Navigation"
import { useDictionary } from "contexts/Dictionary"

import { useConsole } from "contexts/Console"
import { bold } from "css/text"

import { Icon } from "components/icon/Icon"
import WeChatModal from "components/footer/WeChatModal"
import A11yModal from "components/footer/A11yModal"
import { waTriggerEvent } from "components/analytics/DigitalDataLayer"

const Root = styled.li`
  > a,
  > span {
    ${bold}
    display: block;
  }

  > a {
    color: inherit;
    font-size: clamp(1.125rem, 0.875rem + 0.625vw, 1.625rem); // 18px > 26px
    text-decoration: none;
    transition: color 0.3s;
    display: block;

    @media (hover: hover) {
      :hover {
        color: rgb(var(--rolex-green));
      }
    }
  }

  > span {
    font-size: var(--baseline-font-size); // 14px > 16px
  }

  li {
    font-size: var(--baseline-font-size); // 14px > 16px
    font-weight: normal;

    a,
    button {
      all: unset;

      align-items: baseline; // center increased height, broke baseline grid
      color: rgb(var(--light-black));
      cursor: pointer;
      display: inline-flex;
      gap: 0.5rem;
      text-decoration: none;
      transition: color 0.3s;

      svg {
        fill: currentColor;
        height: 0.875rem;
        width: 0.875rem;
        transform: translateY(0.25ex); // tweak alignment because of align-items: baseline on parent
      }

      @media (hover: hover) {
        :hover {
          color: rgb(var(--rolex-green));
        }
      }

      &.reverseIcon {
        flex-direction: row-reverse;
      }
    }
  }
`

export default function Category({ label, href, links, social, accessibility, wechat, modal }) {
  const console = useConsole()

  const rlink = useRef()

  const { linkProps } = useLink(rlink)
  const navigation = useNavigation()
  const uid = useId()
  const dictionary = useDictionary()

  const [isWeChatModalOpen, setIsWeChatModalOpen] = useState(false)
  const [isA11yModalOpen, setIsA11yModalOpen] = useState(false)

  const bodyElement = process.browser && document.body

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.target.getAttribute("href"))
  }

  
  const triggerEvent = (href, outlinkName) => {
    waTriggerEvent({
      eventName: "outlink",
      eventType: "Click",
      outlinkInfo : {
        linkURL: href,
        linkName: outlinkName,
      }
    })
  }

  const onCloseWeChatModal = () => {
    setIsWeChatModalOpen(false)
  }

  const onOpenWeChatModal = e => {
    e.preventDefault()
    setIsWeChatModalOpen(true)

    // V7-2559: Send an event akin to an outlink
    triggerEvent("https://weixin.qq.com/r/LkjSykbE3xugrQKp9x08", "WeChat")
  }

  const onCloseA11yModal = () => {
    setIsA11yModalOpen(false)
  }

  const onOpenA11yModal = e => {
    e.preventDefault()
    setIsA11yModalOpen(true)
  }

  return (
    <Root>
      {href ? (
        <a id={uid} {...linkProps} ref={rlink} href={navigation.localize(href)} onClick={navigate}>
          {label}
        </a>
      ) : (
        <span id={uid}>{label}</span>
      )}
      <ul aria-labelledby={uid}>
        {links?.map(({ label, target, href, outlink_name }, index) => (
          <li key={index}>
            {social ? (
              label.toLowerCase() === "wechat" ? (
                <button aria-label={`${label} - ${dictionary.openInPopin()}`} onClick={onOpenWeChatModal}>
                  <Icon type={label.toLowerCase()} />
                  {label}
                </button>
              ) : (
                <a
                  {...linkProps}
                  aria-label={`${label} - ${dictionary.openInNewTab()}`}
                  href={href}
                  ref={rlink}
                  rel='noopener noreferrer'
                  target='_blank'
                  onClick={() => triggerEvent(href, label)}
                >
                  <Icon type={label.toLowerCase()} />
                  {label}
                </a>
              )
            ) : accessibility ? (
              <button onClick={onOpenA11yModal}>{label}</button>
            ) : target === "external" ? (
              <a
                className='reverseIcon'
                {...linkProps}
                ref={rlink}
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                aria-label={`${label} - ${dictionary.openInNewTab()}`}
                onClick={() => triggerEvent(href, outlink_name || label)}
              >
                <Icon type='externalLink' />
                {label}
              </a>
            ) : (
              // TODO: redo all the thingz
              <a {...linkProps} ref={rlink} href={navigation.localize(href)} onClick={navigate}>
                {label}
              </a>
            )}
          </li>
        ))}
      </ul>
      {process.browser &&
        createPortal(<AnimatePresence>{isWeChatModalOpen && <WeChatModal {...wechat} onClose={onCloseWeChatModal} />}</AnimatePresence>, bodyElement)}
      {process.browser &&
        createPortal(<AnimatePresence>{isA11yModalOpen && <A11yModal {...modal} onCloseA11yModal={onCloseA11yModal} />}</AnimatePresence>, bodyElement)}
    </Root>
  )
}
