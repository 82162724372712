import {
  loadAdvancedSearchQueryActions,
  loadFieldActions,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  loadSearchConfigurationActions,
} from "@coveo/headless"
import { useLocale } from "contexts/Locale"

import { useSearch } from "../context"
import { sourceIndexes } from "../utils/source-types"

export const useActions = process.browser
  ? () => {
      const locale = useLocale()

      const localeCode = locale?.current?.codes?.www || locale.defaultLocale.codes.www

      const { setIsloading } = useSearch()

      if (global.coveoHeadlessEngine) {
        try {
          window.__tcfapi("getNonIABVendorConsents", 2, nonIabConsent => {
            const vendors = nonIabConsent?.nonIabVendorConsents
            if (!nonIabConsent?.gdprApplies || vendors?.["16"] === true) {
              global.coveoHeadlessEngine.enableAnalytics()
            } else {
              global.coveoHeadlessEngine.disableAnalytics()
            }
          })
        } catch {
          global.coveoHeadlessEngine.enableAnalytics()
        }
      }

      const { registerAdvancedSearchQueries } = loadAdvancedSearchQueryActions(global.coveoHeadlessEngine)
      const { updateQuery } = loadQueryActions(global.coveoHeadlessEngine)
      const { updateSearchConfiguration } = loadSearchConfigurationActions(global.coveoHeadlessEngine)

      global.coveoHeadlessEngine.dispatch(updateSearchConfiguration({ locale: localeCode }))

      const activateFilter = async filterName => {
        if (!filterName || typeof filterName !== "string") {
          return
        }

        try {
          await global.coveoHeadlessEngine.dispatch({ type: "tab/updateActiveTab", payload: filterName })
        } catch (err) {
          console.error(err)
        }
      }

      const executeSearch = () => {
        const { logInterfaceLoad } = loadSearchAnalyticsActions(global.coveoHeadlessEngine)
        const { executeSearch } = loadSearchActions(global.coveoHeadlessEngine)

        return new Promise(async resolve => {
          try {
            await registerFields()
            const response = await global.coveoHeadlessEngine.dispatch(executeSearch(logInterfaceLoad()))
            const { payload } = response
            if (!payload) {
              console.log("payload issue: ", response)
              resolve(null)
            }
            const { results } = payload.response
            if (results.length > 0) {
              resolve(results)
            } else {
              console.log("result issue: ", response)
              resolve(null)
            }
          } catch (err) {
            console.error(err)
          }
        })
      }

      const registerFilter = (id, expression) => {
        if (!id || typeof id !== "string" || !expression || typeof expression !== "string") {
          return
        }

        global.coveoHeadlessEngine.dispatch({
          type: "tab/register",
          payload: {
            id,
            expression,
          },
        })
      }

      const fieldsToInclude = [
        "rlx_contenttype",
        "rlx_rmc",
        "rlx_title",
        "rlx_family",
        "rlx_family_facet",
        "rlx_case_title",
        "rlx_newmodelselection",
        "rlx_familycode",
        "rlx_description",
        "rlx_searchimage",
        "rlx_search_section",
        "rlx_search_title",
        "rlx_path",
      ]

      const registerFields = (fields = [...fieldsToInclude]) => {
        const { registerFieldsToInclude } = loadFieldActions(global.coveoHeadlessEngine)

        return new Promise(resolve => {
          try {
            setTimeout(() => {
              const response = global.coveoHeadlessEngine.dispatch(registerFieldsToInclude(fieldsToInclude))
              resolve("expression added")
            }, 100)
          } catch (err) {
            console.error(err)
          }
        })
      }

      /* 
		add an epxression to query 
		expression = watches||articles||retailers
	*/
      const registerAdvancedSearchQuery = async expression => {
        if (!expression || typeof expression !== "string" || !sourceIndexes.hasOwnProperty(expression)) {
          console.error("invalid query expression")
          return
        }

        await global.coveoHeadlessEngine.dispatch(registerAdvancedSearchQueries({ cq: "" }))

        // executeSearch()
        await global.coveoHeadlessEngine.dispatch(registerAdvancedSearchQueries({ cq: `${sourceIndexes[expression]} AND @rlx_lang==${localeCode}` }))
      }

      const updateQueryWithValue = (q = "") => {
        return new Promise(async resolve => {
          setTimeout(() => {
            global.coveoHeadlessEngine.dispatch(
              updateQuery({
                q: q,
              })
            )
            resolve("query value added")
          }, 300)
        })
      }

      /* 
		launch 3 querys for each filter
		settimeout is used to make async actions in redux 
	*/
      const searchAllFilters = async (q = "") => {
        if (typeof q !== "string") {
          console.error("invalid query expression")
          return
        }

        let groupedResults = {}

        return new Promise(async resolve => {
          await updateQueryWithValue(q)

          setTimeout(async () => {
            await registerAdvancedSearchQuery("watches")
            await activateFilter("watches")
            // setTimeout(async () => {
            const results = await executeSearch()
            results && Object.assign(groupedResults, { watches: results })
            // }, 300)

            // setTimeout(async () => {
            await registerAdvancedSearchQuery("articles")
            await activateFilter("articles")
            setTimeout(async () => {
              const results = await executeSearch()
              results && Object.assign(groupedResults, { articles: results })

              // setTimeout(async () => {
              //   await registerAdvancedSearchQuery("retailers")
              //   const results = await executeSearch()
              //   results && Object.assign(groupedResults, { retailers: results })
              // }, 100)
              resolve(groupedResults)
              // }, 300)
            }, 100)
          }, 100)
        })
      }

      const searchIndex = async (index, q = "") => {
        //todosearch: remove if still unused
        if (!index || typeof index !== "string" || !sourceIndexes.hasOwnProperty(index)) {
          console.error("invalid query expression")
          return
        }

        return new Promise(async resolve => {
          await updateQueryWithValue(q)
          await registerAdvancedSearchQuery(index)

          setTimeout(() => {
            global.coveoHeadlessEngine.dispatch(
              updateQuery({
                q: q,
              })
            )

            setTimeout(async () => {
              const results = await executeSearch()
              resolve(results)
            }, 300)
          }, 300)
        })
      }

      return {
        activateFilter,
        executeSearch,
        registerFilter,
        registerAdvancedSearchQuery,
        searchAllFilters,
        searchIndex,
        updateQueryWithValue,
      }
    }
  : () => ({})
