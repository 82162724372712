import hash from "@emotion/hash"
import styled from "@emotion/styled"
import EventTarget from "@ungap/event-target"
import { motion } from "framer-motion"
import { videoLoader } from "./videoLoader"

import Button from "components/button/Button"
import { useConfigurator } from "components/configurator/context"
import Image from "components/media/ImageCLD"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useEnv } from "contexts/Env"
import { useViewport } from "contexts/Viewport"
import useConstant from "hooks/useConstant"
import useSSR from "hooks/useSSR"
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react"

export const cssContainer = `css-${hash("video:container")}`
export const cssVideo = `css-${hash("video:video")}`
export const cssPoster = `css-${hash("video:poster")}`
export const cssFallback = `css-${hash("video:fallback")}`
export const cssControls = `css-${hash("video:controls")}`
export const cssPlayButton = `css-${hash("video:play-button")}`
export const cssMuteButton = `css-${hash("video:mute-button")}`

export const cssIsReady = `css-${hash("video:state:is-ready")}`
export const cssIsPlaying = `css-${hash("video:state:is-playing")}`
export const cssIsSuspended = `css-${hash("video:state:is-suspended")}`

const Container = styled.div`
  * {
    pointer-events: none;
  }

  display: grid;
  grid-template-areas: "video";
  overflow: hidden;
  position: relative;

  picture {
    grid-area: video;
  }

  img,
  video {
    height: auto;
    grid-area: video;
    width: 100%;
  }

  img {
    display: block;
  }

  video {
    object-fit: cover;
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: rgba(var(--light-black));
  pointer-events: none;
`

const Controls = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  grid-area: video;
  justify-self: end;
  margin-block-end: clamp(1.25rem, 1.56vw + 0.63rem, 2.5rem);
  margin-inline-end: clamp(1.25rem, 1.56vw + 0.63rem, 2.5rem);
  row-gap: 1.25rem;

  button {
    pointer-events: all;
  }
`

function Source({ src, type, width, quality, path, policy, ...rest }) {
  return <source {...rest} src={src} type={type} />
}

function VideoCLD(props, handle) {
  const {
    autoPlay = true,
    autoPause = true,
    // fallback,
    has_audio = false,
    loop = true,
    // mime = "video/mp4",
    noButton,
    poster,
    preload = "metadata",
    quality,
    sizes,
    sources,
    animate = false,
  } = props
  const console = useConsole()
  const env = useEnv()
  const ssr = useSSR()
  const rcontainer = useRef()
  const rvideo = useRef()
  const dictionary = useDictionary()
  const { route } = useConfigurator()

  const videos = sources?.reduce((acc, v) => {
    acc[v.width / v.height < 1 ? "portrait" : "landscape"] = v
    return acc
  }, {})
  const policy = null
  const widthLandscape = videos?.landscape?.width > 1920 ? 2440 : videos?.landscape?.width // resize landscape asset if too big
  const { src: srcS /*, poster: postS */ } = videoLoader(
    { src: sources?.[0].public_id, policy },
    env
  )({ width: sources?.[0]?.width, quality: quality ?? "auto:eco" })
  const { src: srcP /*, poster: postP */ } = videoLoader(
    { src: videos?.portrait?.public_id, policy },
    env
  )({ width: videos?.portrait?.width, quality: quality ?? "auto:eco" })
  const { src: srcL /*, poster: postL */ } = videoLoader(
    { src: videos?.landscape?.public_id, policy },
    env
  )({ width: widthLandscape, quality: quality ?? "auto:eco" })

  const prefersReducedMotion = () => process.browser && document.documentElement.classList.contains("prefers-reduced-motion")
  const prefersReducedData = () => process.browser && global.matchMedia("(prefers-reduced-data: reduce)").matches

  const et = useConstant(() => new EventTarget())
  const addEventListener = (...args) => et.addEventListener(...args)
  const removeEventListener = (...args) => et.removeEventListener(...args)

  const oncanplay = {
    addCanPlayListener: (...args) => rvideo.current.addEventListener("canplay", ...args),
    removeCanPlayListener: (...args) => rvideo.current.removeEventListener("canplay", ...args),
  }

  const oncanplaythrough = {
    addCanPlayThroughListener: (...args) => rvideo.current.addEventListener("canplaythrough", ...args),
    removeCanPlayThroughListener: (...args) => rvideo.current.removeEventListener("canplaythrough", ...args),
  }

  const onplaying = {
    addPlayingListener: (...args) => rvideo.current.addEventListener("playing", ...args),
    removePlayingListener: (...args) => rvideo.current.removeEventListener("playing", ...args),
  }

  const onpause = {
    addPauseListener: (...args) => rvideo.current.addEventListener("pause", ...args),
    removePauseListener: (...args) => rvideo.current.removeEventListener("pause", ...args),
  }

  const onsuspend = {
    addSuspendListener: (...args) => rvideo.current.addEventListener("suspend", ...args),
    removeSuspendListener: (...args) => rvideo.current.removeEventListener("suspend", ...args),
  }

  const onended = {
    addEndedListener: (...args) => rvideo.current.addEventListener("ended", ...args),
    removeEndedListener: (...args) => rvideo.current.removeEventListener("ended", ...args),
  }

  const { isMobile } = useViewport()

  const [src, setSrc] = useState(null)
  const [playerState, setPlayerState] = useState({ isReady: false, isSuspended: false, isPlaying: false, isLocked: false, isMuted: true })

  const onOrientationChange = bool => {
    if (sources.length < 2) return
    const orient = bool ? "portrait" : "landscape"
    setSrc(orient === "portrait" ? srcP : srcL)
  }

  useLayoutEffect(() => {
    if (sources?.length < 2) {
      setSrc(srcS)
      return
    }

    const orient = isMobile.get() ? "portrait" : "landscape"
    setSrc(orient === "portrait" ? srcP : srcL)
    const unsub = isMobile.onChange(onOrientationChange)
    return () => unsub()
  }, [])

  // Pause video when 80% out of viewport
  useLayoutEffect(() => {
    const { current: node } = rvideo

    let observer
    if (autoPause) {
      observer = new IntersectionObserver(
        ([{ intersectionRatio }]) => {
          if (intersectionRatio >= 0.2) {
            if (!prefersReducedMotion() && !prefersReducedData()) ctx.play({ auto: true, isLocked: playerState.isLocked })
          } else {
            node.removeAttribute("data-visible")
            ctx.pause()
          }
        },
        { threshold: 0.2 }
      )
      observer.observe(node)
    }

    return () => {
      if (observer) observer.unobserve(node)
    }
  })

  // Pause video when "configurator" is open
  const onRouteChange = v => {
    if (!!v) {
      setPlayerState({ ...playerState, isLocked: true })
      ctx.pause()
    } else {
      ctx.play({ auto: true, isLocked: false })
    }
  }

  useLayoutEffect(() => route.onChange(onRouteChange))

  useLayoutEffect(function detectA11yChanges() {
    const html = document.documentElement
    const video = rvideo.current
    const observer = new MutationObserver(([{ attributeName }]) => {
      if (attributeName === "class") {
        if (html.classList.contains("prefers-reduced-motion") && !video.paused) {
          video.pause({ keepPlayAttribute: true })
        } else if (!html.classList.contains("prefers-reduced-motion") && video.hasAttribute("data-play") && video.paused)
          ctx.play({ auto: true, isLocked: false })
      }
    })

    observer.observe(html, {
      subtree: false,
      attributes: true,
    })
    return () => {
      observer.disconnect()
    }
  })

  const ctx = {
    addEventListener,
    removeEventListener,
    oncanplay,
    oncanplaythrough,
    onplaying,
    onpause,
    onsuspend,
    onended,
    playerState,
    play: ({ rewind, auto, isLocked, bypassReducedMotion } = {}) => {
      let isAutoAndLocked = auto && isLocked

      if (rewind) ctx.rewind()
      if (!isAutoAndLocked) {
        rvideo.current.setAttribute("data-play", 1) // keep track of the "natural" state
        if (!prefersReducedMotion() || bypassReducedMotion) rvideo.current.play()
        else {
          rvideo.current.currentTime = 0
        }
      }
    },
    pause: ({ keepPlayAttribute } = {}) => {
      if (!keepPlayAttribute) rvideo.current.removeAttribute("data-play")
      rvideo.current.pause()
    },
    togglePlayPause: ({ bypassReducedMotion, isLocked, keepPlayAttribute } = {}) => {
      if (rvideo.current.paused) ctx.play({ bypassReducedMotion })
      else ctx.pause({ keepPlayAttribute })
      setPlayerState({ ...playerState, isLocked: isLocked })
    },
    stop: ({ keepPlayAttribute } = {}) => {
      ctx.pause({ keepPlayAttribute })
      ctx.rewind()
    },
    rewind: () => {
      rvideo.current.currentTime = 0
    },
    mute: () => {
      rvideo.current.muted = true
    },
    unmute: () => {
      rvideo.current.muted = false
    },
    toggleMute: () => {
      setPlayerState({ ...playerState, isMuted: !rvideo.current.muted })
      rvideo.current.muted = !rvideo.current.muted
    },
    get node() {
      return rvideo.current
    },
    get currentTime() {
      return rvideo.current.currentTime
    },
    set currentTime(v) {
      rvideo.current.currentTime = v
    },
    get duration() {
      return rvideo.current.duration
    },
    get canPlay() {
      return rvideo.current.readyState >= 3
    },
    get canPlayThrough() {
      return rvideo.current.readyState === 4
    },
    get isPlaying() {
      return !!(rvideo.current.currentTime > 0 && !rvideo.current.paused && !rvideo.current.ended && rvideo.current.readyState > 2)
    },
    get isMuted() {
      return rvideo.current.muted
    },
    get hasAudio() {
      return !!(
        (rvideo.current.audioTracks && rvideo.current.audioTracks.length > 0) ||
        rvideo.current.webkitAudioDecodedByteCount > 0 ||
        rvideo.current.mozHasAudio
      )
    },
    get prefersReducedMotion() {
      return prefersReducedMotion()
    },
    get prefersReducedData() {
      return prefersReducedData()
    },
  }
  useImperativeHandle(handle, () => ctx)

  const className = [playerState.isReady && cssIsReady, playerState.isPlaying && cssIsPlaying, playerState.isSuspended && cssIsSuspended]
    .filter(Boolean)
    .join(" ")

  console.verbose("VideoCLD(%o)", src, props)
  return (
    <Container className={`${cssContainer} ${className}`} ref={rcontainer}>
      {poster?.sources?.length >= 1 && <Image {...poster} className={cssPoster} sizes={sizes} />}
      {/* <Image {...fallback} className={cssFallback} sizes={sizes} /> */}
      <motion.video
        autoPlay={prefersReducedMotion() ? null : autoPlay}
        className={cssVideo}
        controls={false}
        controlsList='nofullscreen nodownload'
        disablePictureInPicture
        disableRemotePlayback
        key={src} // force re-render the component on source change
        loop={loop || null}
        muted={true}
        onCanPlay={() => setPlayerState({ ...playerState, isReady: true })}
        onEnded={() => setPlayerState({ ...playerState, isSuspended: true })}
        onPause={() => setPlayerState({ ...playerState, isPlaying: false })}
        onPlay={() => setPlayerState({ ...playerState, isPlaying: true, isSuspended: false })}
        // onSuspend={() => setPlayerState({ ...playerState, isSuspended: true })}
        playsInline
        poster={""}
        preload={prefersReducedMotion() ? null : preload}
        ref={rvideo}
        // style={{ opacity: `${playerState.isSuspended || ssr ? "0" : "1"}` }}
        tabIndex='-1' // non-controllable videos shouldn't be focusable (Firefox)
      >
        {src && <Source src={src} type='video/mp4' />}
      </motion.video>

      {animate && <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9, type: "easeIn" } }} />}

      {!noButton && !ssr && (
        <Controls className={cssControls}>
          {has_audio && (
            <Button
              aria_label={playerState.isMuted ? dictionary.mute() : dictionary.unmute()}
              className={`${cssMuteButton} icon translucent-dark`}
              icon={playerState.isMuted ? "soundOff" : "soundOn"}
              onPress={() => ctx.toggleMute()}
            />
          )}
          <Button
            aria_label={playerState.isPlaying ? dictionary.pause() : dictionary.play()}
            className={`${cssPlayButton} icon translucent-dark`}
            icon={playerState.isPlaying ? "pause" : "play"}
            onPress={() => ctx.togglePlayPause({ bypassReducedMotion: true, isLocked: !playerState.isLocked })}
          />
        </Controls>
      )}
    </Container>
  )
}

export default forwardRef(VideoCLD)
