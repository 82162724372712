import styled from "@emotion/styled"
import { motion } from "framer-motion"

import getMediaQuery from "css/breakpoints"
import { headline100, surtitle100 } from "css/text"
import { contrastMode } from "css/a11y"

import { kickerCN, subtitleCN } from "components/page-heading/style"
import Link from "components/link/Link"
import VideoLauncher from "components/video-launcher/VideoLauncher"

const Hgroup = styled(motion.hgroup)`
  position: relative;
  grid-column: col 1 / span 6;
  grid-row: 1;
  height: min-content;
  align-self: center;
  text-align: center;
  z-index: 1;
  margin-block-end: -0.8rem;

  .multirows & {
    align-self: start;
    padding-block-start: 15vh;
  }

  ${getMediaQuery("m")} {
    grid-column: col 2 / span 10;
  }
  ${getMediaQuery("l")} {
    grid-column: col 3 / span 8;
  }

  & h1 {
    ${headline100}
    grid-row: mid-line / title;
    padding-block-end: 0.8rem;
  }

  & .${kickerCN} {
    ${surtitle100}
    grid-row: kicker / mid-line;
  }

  & .${subtitleCN} {
    ${surtitle100}
    grid-row: subtitle / mid-line;
  }

  ${contrastMode}

  @media (prefers-contrast: no-preference) {
    &.gradient {
      background: var(--gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  > a,
  > button {
    margin-block-start: 1.075rem;
  }
`

export default function PageHeading({ heading, heading_gradient, link, video_amp }) {
  const { kicker, title, subtitle } = heading

  const colors = heading_gradient?.colors ? heading_gradient?.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
  const gradient = colors.length ? `linear-gradient(${heading_gradient.angle ? `${heading_gradient.angle}deg, ` : ""}${colors})` : ""

  return (
    <Hgroup className={gradient ? "gradient" : ""} style={{ "--gradient": gradient }}>
      {kicker ? <motion.p className={kickerCN}>{kicker}</motion.p> : null}
      {title ? <h1 dangerouslySetInnerHTML={{ __html: title }} /> : null}
      {subtitle ? <p className={subtitleCN}>{subtitle}</p> : null}
      {link?.href ? <Link {...link}>{link?.label}</Link> : video_amp ? <VideoLauncher video_amp={video_amp} /> : null}
    </Hgroup>
  )
}
