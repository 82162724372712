import { useEffect, useRef, useState, useLayoutEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import getMediaQuery from "css/breakpoints"
import { useTransform } from "framer-motion"
import { CFGV7 } from "./context"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { buttonFilled, buttonIcon, translucentDark, opaqueWhite, translucentLight, opaqueGreen } from "css/buttons"
import { Icon } from "components/icon/Icon"
import Link from "components/link/Link"
import { useDictionary } from "contexts/Dictionary"
import { useFocusManager } from "react-aria"
import { storeCustomEvent } from "components/analytics/DigitalDataLayer"

const { STEPS, STEPS_LENGTH } = CFGV7

const SWAP_IN = css`
  transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms !important;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

function Loader({ Ctx, size, color = "#808284" }) {
  const { rolling } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)

  function onRolling(v) {
    //    if (!lastStep()) return
    rfmain.current?.classList[v ? "add" : "remove"]("wv_reveal")
  }

  useEffect(() => mvSubscribe(rolling, onRolling, false), [])

  return (
    <svg ref={rfmain} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
      {[
        {
          rotate: "rotate(0 50 50)",
          begin: "-0.9166666666666666s",
        },
        {
          rotate: "rotate(30 50 50)",
          begin: "-0.8333333333333334s",
        },
        {
          rotate: "rotate(60 50 50)",
          begin: "-0.75s",
        },
        {
          rotate: "rotate(90 50 50)",
          begin: "-0.6666666666666666s",
        },
        {
          rotate: "rotate(120 50 50)",
          begin: "-0.5833333333333334s",
        },
        {
          rotate: "rotate(150 50 50)",
          begin: "-0.5s",
        },
        {
          rotate: "rotate(180 50 50)",
          begin: "-0.4166666666666667s",
        },
        {
          rotate: "rotate(210 50 50)",
          begin: "-0.3333333333333333s",
        },
        {
          rotate: "rotate(240 50 50)",
          begin: "-0.25s",
        },
        {
          rotate: "rotate(270 50 50)",
          begin: "-0.16666666666666666s",
        },
        {
          rotate: "rotate(300 50 50)",
          begin: "-0.08333333333333333s",
        },
        {
          rotate: "rotate(330 50 50)",
          begin: "0s",
        },
      ].map(({ rotate, begin }, index) => (
        <g transform={rotate} key={index}>
          <rect x='46' y='2.5' rx='9.200000000000001' ry='0.5' width='8' height='15' fill={color}>
            <animate attributeName='opacity' values='1;0' keyTimes='0;1' dur='1s' begin={begin} repeatCount='indefinite' />
          </rect>
        </g>
      ))}
    </svg>
  )
}

const Navigation = styled.section`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: center;
  flex: 0;
  align-self: end;
  padding: 18px 0 0;
  box-sizing: border-box;
  pointer-events: none;
  //  transition: opacity 10ms cubic-bezier(1, 1, 1, 1) 0ms, visibility 0ms 10ms;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  &.wv_reveal {
    transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 40ms, visibility 0ms 0ms;
    opacity: 1;
    visibility: visible;
  }

  & > div {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    grid-gap: 1rem;

    > svg {
      position: absolute;
      right: -30px;
      transform: translateX(100%);
      [dir="rtl"] & {
        right: auto;
        left: -30px;
        transform: translateX(-100%);
      }
      ${SWAP_OUT}
      &.wv_reveal {
        ${SWAP_IN}
        transition-delay: 400ms;
      }
    }

    > div {
      display: inline-block;
      ${SWAP_OUT}
      &.wv_reveal {
        ${SWAP_IN}
      }
      &.wv_remove {
        display: none;
      }
    }

    & button,
    & a {
      pointer-events: auto;
      ${SWAP_OUT}
      &.wv_reveal {
        ${SWAP_IN}
      }

      > span {
        clip: unset !important;
        -webkit-clip-path: unset !important;
        clip-path: unset !important;
        height: auto !important;
        position: relative !important;
        width: auto !important;
      }

      &.cfgprev,
      &.cfgrestore {
        position: absolute;
        left: -30px;
        transform: translateX(-100%);
        & svg {
          transform: scale(-1);
        }
        [dir="rtl"] & {
          left: auto;
          right: -30px;
          transform: translateX(100%);
          & svg {
            transform: scale(1);
          }
        }
        ${buttonIcon}
        ${translucentDark}
      }
      &.cfgnext {
        ${buttonFilled}
        .dark-theme & {
          ${opaqueWhite}
        }
        .light-theme & {
          ${opaqueGreen}
        }
      }
      &.cfgview {
        ${SWAP_IN}
        ${buttonFilled}
        .dark-theme & {
          ${opaqueWhite}
        }
        .light-theme & {
          ${opaqueGreen}
        }
      }
      &.cfgrestart {
        position: absolute;
        left: -30px;
        transform: translateX(-100%);
        [dir="rtl"] & {
          left: auto;
          right: -30px;
          transform: translateX(100%);
        }
        ${buttonIcon}
        > span {
          display: none;
        }
        ${getMediaQuery("s")} {
          ${buttonFilled}
          > span {
            display: block;
          }
        }
        ${translucentDark}
      }

      &.wv_remove {
        display: none;
      }
    }
  }
`

const getButtonProps = {
  next: (m, s) => {
    if (!m || s === 0) return null
    let step = m.next[s]
    let label = s >= STEPS_LENGTH ? null : step < 0 ? "configuratorButtonNext" : STEPS[step]
    return { step: step < 0 ? STEPS_LENGTH : step, label }
  },
  prev: (m, s, split) => {
    if (!m || s === 0) return null
    let step = m.prev[s]
    step = step <= 0 && split ? 0 : step
    let label = s >= STEPS_LENGTH ? null : step > 0 || split ? STEPS[step] : null
    return { step, label }
  },
  restore: (m, s) => {
    if (!m || s === 0) return null
    let step = m.prev[s]
    let label = s >= STEPS_LENGTH ? STEPS[step] : null
    return { step, label }
  },
  view: (m, s) => {
    if (!m || s === 0) return null
    let step = -1
    let label = s < STEPS_LENGTH ? null : "discover"
    return { step, label }
  },
  restart: (m, s) => {
    if (!m || s === 0) return null
    let step = 0
    let label = s < STEPS_LENGTH ? null : "configuratorButtonRestart"
    return { step, label }
  },
}

const getButtonIcons = {
  next: {
    icon: "chevron",
    reverseicon: true,
  },
  prev: {
    icon: "chevron",
    reverseicon: true,
    icononly: true,
  },
  restore: {
    icon: "chevron",
    reverseicon: true,
    icononly: true,
  },
  view: {},
  restart: {
    icon: "restart",
  },
}

function useActive(model, getModel) {
  const [href, setHref] = useState(null)

  function onmodel(v) {
    setHref(getModel(v)?.href)
  }

  useEffect(() => mvSubscribe(model, onmodel), [])

  return href
}

function CfgNavigationButton({ Ctx, btntype }) {
  const { step, updateStep, getModel, model, swapping, mobile, startrmc, split, wa, fromlaststep, restore } = useFrameAnimation(Ctx)
  const [title, setTitle] = useState(null)
  const props = useTransform([model, step, fromlaststep], ([model, step, fromlaststep]) =>
    getButtonProps[btntype](getModel(btntype === "restore" ? fromlaststep : model), step, split)
  )
  const href = useActive(model, getModel)
  const lasttitle = useRef(null)
  const modelswap = useRef(false)
  const rfmain = useRef()
  const dictionary = useDictionary()
  //  const navigation = useNavigation()

  function display(state) {
    if (!state) return
    let { label } = props.get() || { label: null }
    label = label && dictionary[label]()
    //    console.log("test display", btntype, state, label)
    //    console.log("test display", btntype, label)
    rfmain.current?.classList[!label ? "add" : "remove"]("wv_remove")
    setTitle((lasttitle.current = label))
  }

  function onmodel(v) {
    if (!lasttitle.current) return
    modelswap.current = true
  }

  function onprops(v) {
    if (!modelswap.current) return

    if (lasttitle.current !== v?.label) display(true) //rafSwap(rfmain.current, "wv_reveal", () => display(true)) //cssSwap(rfmain.current, "wv_reveal", () => display(true))
    modelswap.current = false
  }

  function onDiscover(e) {
    storeCustomEvent({ eventName: "configuratorCompleted" })
    //    console.log("myDDL onDiscover")
    //    wa({ eventName: ["configuratorCompleted"] })
  }

  function onClick(e) {
    let step = props.get().step
    if (btntype === "restore") {
      restore.set(1)
      model.set(fromlaststep.get())
      fromlaststep.set(null)
    }
    if (step === 0) {
      if (btntype === "restart") {
        //        wa({ eventName: "configuratorReset" })
        let m = getModel(startrmc.get())
        step = -m.next[0] / 10
      }
      model.set(startrmc.get())
    }
    updateStep(step)
  }

  function onSwapping(v) {
    if (v) return display(false)
    display(true)
  }

  useEffect(() => mvSubscribe(model, onmodel, false), [])
  useEffect(() => mvSubscribe(props, onprops), [])
  useEffect(() => mvSubscribe(swapping, onSwapping, false), [])

  return btntype === "view" ? (
    <div ref={rfmain} className='wv_reveal' onClick={onDiscover}>
      <Link className={`cfg${btntype}`} icon='none' href={href}>
        {title}
      </Link>
    </div>
  ) : (
    <button
      ref={rfmain}
      className={`${getButtonIcons[btntype].reverseicon ? "reverseIcon" : ""} cfg${btntype} wv_reveal`}
      aria-label={btntype === "prev" ? dictionary.goBackTo()?.replace("{{1}}", title) : title}
      onClick={onClick}
    >
      {getButtonIcons[btntype].icon && <Icon type={getButtonIcons[btntype].icon} />}
      {!getButtonIcons[btntype].icononly && <span>{title}</span>}
    </button>
  )
}

export function CfgNavigation({ Ctx }) {
  const { swapping, step } = useFrameAnimation(Ctx)
  //  const list = "next,restart,view,prev".split(",")
  const list = "next,restore,view,prev".split(",")
  const rfmain = useRef()
  const focusManager = useFocusManager()

  function display(state) {
    rfmain.current?.classList[!state ? "add" : "remove"]("wv_reveal")
    if (!state && step.get() === 6)
      setTimeout(() => {
        let el = [...rfmain.current?.querySelectorAll("button")].pop()
        focusManager.focusPrevious({ from: el, wrap: true })
      }, 40)
  }

  useEffect(() => mvSubscribe(swapping, display, false), [])

  return (
    <Navigation ref={rfmain}>
      <div>
        {list.map(v => (
          <CfgNavigationButton key={v} Ctx={Ctx} btntype={v} />
        ))}
        <Loader Ctx={Ctx} size={20} color='#fff' />
      </div>
    </Navigation>
  )
}
