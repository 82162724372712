import { createContext } from "react"
import { FrameAnimationProvider } from "./FrameAnimation"
import { CfgContents } from "./contents"
import { CfgModal } from "./modal"

export default function Configurator({ items, bkgmode, kill }) {
  const cfgCtx = createContext()

  return (
    <CfgModal kill={kill}>
      <FrameAnimationProvider Ctx={cfgCtx} items={items} bkgmode={bkgmode} clear={kill}>
        <CfgContents Ctx={cfgCtx} />
      </FrameAnimationProvider>
    </CfgModal>
  )
}
