import { useEffect, useRef, useState, memo } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation } from "./FrameAnimation"
import { useMotionValue, animate } from "framer-motion"
import { mvSubscribe } from "./utils"
import { buttonFilled, opaqueWhite, translucentDark, opaqueGreen, opaqueBlack, translucentLight } from "css/buttons"
import { bold, visuallyHidden } from "css/text"
import { useDictionary } from "contexts/Dictionary"

const SWAP_IN = css`
  transition: opacity 400ms linear 400ms, visibility 0ms 0ms !important;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  transition: opacity 100ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 100ms !important;
`

const LoaderBtn = styled.div`
  display: inline-flex;
  position: relative;
  transform-style: preserve-3d;
  --cfg-loader-progress: 0%;
  padding: 30px 0 0;
  will-change: opacity, visibility;

  ${SWAP_OUT}
  &.wv_reveal {
    ${SWAP_IN}
  }

  .dark-theme & {
    & > button {
      ${opaqueWhite}
    }
  }
  .light-theme & {
    & > button {
      ${opaqueGreen}
    }
  }

  & > button {
    position: relative;
    z-index: 1;
    ${buttonFilled}

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    :is(:disabled) {
      opacity: 1;
    }
    & > span {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      clip: unset;
      width: unset;
      height: unset;
      position: relative;
      clip-path: unset;
      visibility: hidden;
      &.wv_loader-label {
        visibility: inherit;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      border: 3px solid;
      border-radius: 9999px;
      top: 50%;
      left: 50%;
      //      visibility: hidden;
      transform: translate3d(-50%, -50%, 0);
      --mask: conic-gradient(#000 var(--cfg-loader-progress), transparent var(--cfg-loader-progress));
      [dir="rtl"] & {
        --mask: conic-gradient(transparent calc(100% - var(--cfg-loader-progress)), #000 calc(100% - var(--cfg-loader-progress)));
      }
      -webkit-mask: var(--mask);
      mask: var(--mask);

      .dark-theme & {
        border-color: rgb(var(--pure-white));
      }
      .light-theme & {
        border-color: rgb(var(--light-black));
      }
    }
  }

  &.wv_progress {
    & > button {
      ${translucentDark}
      &::before {
        visibility: visible;
      }
    }
    & .wv_loader-label {
      visibility: hidden;
    }
    & .wv_loader-progress {
      visibility: inherit;
    }
  }
`

const LoopLoaderBtn = styled.div`
  display: inline-flex;
  position: relative;
  transform-style: preserve-3d;
  --cfg-loader-progress: 0%;
  padding: 30px 0 0;
  will-change: opacity, visibility;

  ${SWAP_OUT}
  &.wv_reveal {
    ${SWAP_IN}
  }

  .dark-theme & {
    & > button {
      ${opaqueWhite}
    }
  }
  .light-theme & {
    & > button {
      ${opaqueGreen}
    }
  }

  > div {
    ${visuallyHidden}
  }

  & > button {
    position: relative;
    z-index: 1;
    ${buttonFilled}

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    :is(:disabled) {
      opacity: 1;
    }
    & > span {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      clip: unset;
      width: unset;
      height: unset;
      position: relative;
      clip-path: unset;
      visibility: hidden;
      &.wv_loader-label {
        visibility: inherit;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      border: 3px solid;
      border-radius: 9999px;
      top: 50%;
      left: 50%;
      visibility: hidden;
      transform: translate3d(-50%, -50%, 0);
      --mask: conic-gradient(#000 var(--cfg-loader-progress), transparent var(--cfg-loader-progress));
      [dir="rtl"] & {
        --mask: conic-gradient(transparent calc(100% - var(--cfg-loader-progress)), #000 calc(100% - var(--cfg-loader-progress)));
      }
      -webkit-mask: var(--mask);
      mask: var(--mask);

      .dark-theme & {
        border-color: rgb(var(--pure-white));
      }
      .light-theme & {
        border-color: rgb(var(--light-black));
      }
    }
  }

  &.wv_reverse {
    & > button {
      &::before {
        --mask: conic-gradient(transparent calc(100% - var(--cfg-loader-progress)), #000 calc(100% - var(--cfg-loader-progress)));
        [dir="rtl"] & {
          --mask: conic-gradient(#000 var(--cfg-loader-progress), transparent var(--cfg-loader-progress));
        }
      }
    }
  }

  &.wv_progress {
    & > button {
      ${translucentDark}

      > span {
        opacity: 0.4;
      }
      &::before {
        visibility: visible;
      }
    }
  }
`

const SimpleLoaderBtn = styled.div`
  --cfg-loader-dot-wh: 4px;
  --cfg-loader-dot-margin: 8px;
  --cfg-loader-dot-radius: 3px;
  display: flex;
  justify-content: center;
  position: relative;
  transform-style: preserve-3d;
  --cfg-loader-progress: 0%;
  padding: 30px 0 0;
  will-change: visibility;
  transform-style: preserve-3d;
  visibility: hidden;

  &.wv_reveal {
    visibility: visible;
  }

  .dark-theme & {
    & > button {
      ${opaqueWhite}
    }
  }
  .light-theme & {
    & > button {
      ${opaqueGreen}
    }
  }

  &.wv_progress {
    > div {
      ${SWAP_IN}
    }
    > button {
      ${SWAP_OUT}
    }
  }

  > div {
    will-change: opacity, visibility;
    ${SWAP_OUT}
    height: var(--btn-height);
    padding-inline: 1.875rem;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateZ(0);

    > div {
      display: flex;
      flex-direction: row;
      align-items: end;

      > span {
        ${bold}
        padding: 0 calc(var(--cfg-loader-dot-wh) + var(--cfg-loader-dot-margin));
        line-height: 0.75;
      }
    }
  }

  > button {
    will-change: opacity, visibility;
    transform: translateZ(0);
    ${SWAP_IN}
    position: relative;
    z-index: 1;
    ${buttonFilled}

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    :is(:disabled) {
      opacity: 1;
    }
    & > span {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      clip: unset;
      width: unset;
      height: unset;
      position: relative;
      clip-path: unset;
      visibility: hidden;
      &.wv_loader-label {
        visibility: inherit;
      }
    }
  }
`

const AbstractLoaderBtn = styled.div`
  --cfg-loader-dot-wh: 6px;
  --cfg-loader-dot-margin: 12px;
  --cfg-loader-dot-radius: 3px;
  display: inline-flex;
  position: relative;
  transform-style: preserve-3d;
  --cfg-loader-progress: 0%;
  padding: 30px 0 0;
  will-change: opacity, visibility;

  ${SWAP_OUT}
  &.wv_reveal {
    ${SWAP_IN}
  }

  .dark-theme & {
    & > button {
      ${opaqueWhite}
    }
  }
  .light-theme & {
    & > button {
      ${opaqueGreen}
    }
  }

  & > button {
    position: relative;
    z-index: 1;
    ${buttonFilled}

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    :is(:disabled) {
      opacity: 1;
    }
    & > span {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      clip: unset;
      width: unset;
      height: unset;
      position: relative;
      clip-path: unset;
      visibility: inherit;
    }
    & > div {
      overflow: visible;
      margin: 0 auto;
      grid-row: 1 / -1;
      grid-column: 1 / -1;
      clip: unset;
      width: unset;
      height: unset;
      position: relative;
      clip-path: unset;
      visibility: hidden;
    }
  }

  &.wv_progress {
    & > button {
      ${translucentDark}
      border: 2px solid rgb(var(--pure-white));
    }
    & .wv_loader-label {
      visibility: hidden;
    }
    & .wv_loader-progress {
      visibility: inherit;
    }
  }
`

const AbstractLoaderDots = styled.div`
  clip: unset;
  clip-path: unset;
  display: inline-block;
  position: relative;
  width: var(--cfg-loader-dot-wh) !important;
  height: var(--cfg-loader-dot-wh) !important;
  border-radius: var(--cfg-loader-dot-radius);
  background-color: rgb(var(--pure-white));
  color: rgb(var(--pure-white));
  animation: rlx-cfg-dots 1s infinite linear alternate;
  animation-delay: 0.4s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: var(--cfg-loader-dot-wh);
    height: var(--cfg-loader-dot-wh);
    border-radius: var(--cfg-loader-dot-radius);
    background-color: rgb(var(--pure-white));
    color: rgb(var(--pure-white));
    animation: rlx-cfg-dots 1s infinite linear alternate;
  }
  &::before {
    left: calc(-1 * var(--cfg-loader-dot-margin));
    animation-delay: 0s;
  }
  &::after {
    left: var(--cfg-loader-dot-margin);
    animation-delay: 0.8s;
  }

  @keyframes rlx-cfg-dots {
    0% {
      background-color: rgb(var(--pure-white));
    }
    50%,
    100% {
      background-color: rgb(var(--pure-white), 0.2);
    }
  }
`

export const CfgLoopLoaderBtn = memo(({ Ctx, label, prelabel }) => {
  const { getModel, progress, updateStep, fetching, frames } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfbutton = useRef(null)
  const [prog, setProg] = useState("0%")
  const localprog = useMotionValue(0)
  const rfstop = useRef(null)
  const [loadingState, setLoadingState] = useState(null)
  const dictionary = useDictionary()
  const [truelabel, setTruelabel] = useState(prelabel)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onFetching(v) {
    if (!v) return
    rfstop.current?.stop()
    localprog.set(0)
    onUpdate(0)
    loopit(100)
    setTruelabel(prelabel)
    //    rafSwap(rfmain?.current, "wv_reveal", () => {
    rfmain.current?.classList.remove("wv_reverse")
    rfmain.current?.classList.add("wv_progress")
    rfbutton.current?.setAttribute("disabled", "")
    //    })
    setLoadingState(dictionary.loadingInProgress())
    display(true)
  }

  useEffect(() => mvSubscribe(fetching, onFetching), [])

  function onClick(e) {
    let m = getModel()
    if (!m) return
    let _step = m.next[0]
    updateStep(_step)
  }

  function onEnd() {
    return new Promise(resolve => {
      setTruelabel(label)
      rfstop.current?.stop()
      rfmain.current?.classList.remove("wv_progress")
      rfbutton.current?.removeAttribute("disabled")
      fetching.set(0)
      //      console.log(frames.current)
      resolve()
    })
  }

  function onUpdate(p) {
    rfbutton.current?.style?.setProperty("--cfg-loader-progress", `${p}%`)
    setProg(`${Math.floor(p)}%`)
  }

  async function update(v) {
    /*    rfstop.current = animate(localprog, 100 * v, {
      onUpdate: onUpdate,
      onComplete: async () => {*/
    if (v >= 1) {
      rfstop.current?.stop()
      setLoadingState(dictionary.loadingCompleted())
      display(false)
      await onEnd()
      rfeasing.current = 0
      setTimeout(() => display(true), 0)
      /*          rafSwap(rfmain?.current, "wv_reveal", () => {
            rfmain.current?.classList.remove("wv_progress")
            rfbutton.current?.removeAttribute("disabled")
            fetching.set(0)
          })*/
    }
    //      },
    //    })
  }

  const easings = ["1,1,1,1", "0.33, 1, 0.68, 1"].map(v => v.split(",").map(parseFloat))
  const rfeasing = useRef(0)

  function loopit(v) {
    rfstop.current = animate(localprog, v, {
      duration: 3,
      ease: easings[rfeasing.current],
      onUpdate: onUpdate,
      onComplete: () => {
        if (progress.get() >= 1) {
          return
        }
        rfmain.current?.classList[v >= 100 ? "add" : "remove"]("wv_reverse")
        rfeasing.current = 1 - rfeasing.current
        loopit(v >= 100 ? 0 : 100)
      },
    })
  }

  useEffect(() => mvSubscribe(progress, update), [])

  return (
    <LoopLoaderBtn ref={rfmain} className='wv_progress' aria-live='polite'>
      <div>{loadingState}</div>
      <button ref={rfbutton} onClick={onClick}>
        <span className='wv_loader-label'>{truelabel}</span>
      </button>
    </LoopLoaderBtn>
  )
})

export const CfgLoaderBtn = memo(({ Ctx, label }) => {
  const { getModel, progress, updateStep, fetching, frames } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfbutton = useRef(null)
  const [prog, setProg] = useState("0%")
  const localprog = useMotionValue(0)
  const rfstop = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onFetching(v) {
    if (!v) return
    rfstop.current?.stop()
    localprog.set(0)
    onUpdate(0)
    //    rafSwap(rfmain?.current, "wv_reveal", () => {
    rfmain.current?.classList.add("wv_progress")
    rfbutton.current?.setAttribute("disabled", "")
    //    })
    display(true)
  }

  useEffect(() => mvSubscribe(fetching, onFetching), [])

  function onClick(e) {
    let m = getModel()
    if (!m) return
    let _step = m.next[0]
    updateStep(_step)
  }

  function onEnd() {
    return new Promise(resolve => {
      rfmain.current?.classList.remove("wv_progress")
      rfbutton.current?.removeAttribute("disabled")
      fetching.set(0)
      //      console.log(frames.current)
      resolve()
    })
  }

  function onUpdate(p) {
    rfbutton.current?.style?.setProperty("--cfg-loader-progress", `${p}%`)
    setProg(`${Math.floor(p)}%`)
  }

  function update(v) {
    rfstop.current = animate(localprog, 100 * v, {
      onUpdate: onUpdate,
      onComplete: async () => {
        if (v >= 1) {
          display(false)
          await onEnd()
          display(true)
          /*          rafSwap(rfmain?.current, "wv_reveal", () => {
            rfmain.current?.classList.remove("wv_progress")
            rfbutton.current?.removeAttribute("disabled")
            fetching.set(0)
          })*/
        }
      },
    })
  }

  useEffect(() => mvSubscribe(progress, update), [])

  return (
    <LoaderBtn ref={rfmain} className='wv_progress'>
      <button ref={rfbutton} onClick={onClick}>
        <span className='wv_loader-label'>{label}</span>
        <span className='wv_loader-safe' aria-hidden='true'>
          100%
        </span>
        <span className='wv_loader-progress'>{prog}</span>
      </button>
    </LoaderBtn>
  )
})

export const CfgAbstractLoaderBtn = memo(({ Ctx, label }) => {
  const { getModel, progress, updateStep, fetching, frames } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfbutton = useRef(null)
  const [prog, setProg] = useState("0%")
  const localprog = useMotionValue(0)
  const rfstop = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onFetching(v) {
    if (!v) return
    if (rfstop.current) rfstop.current.stop()
    localprog.set(0)
    //    rafSwap(rfmain?.current, "wv_reveal", () => {
    rfmain.current?.classList.add("wv_progress")
    rfbutton.current?.setAttribute("disabled", "")
    //    })
    display(true)
  }

  useEffect(() => mvSubscribe(fetching, onFetching), [])

  function onClick(e) {
    let m = getModel()
    if (!m) return
    let _step = m.next[0]
    updateStep(_step)
  }

  function onEnd() {
    return new Promise(resolve => {
      rfmain.current?.classList.remove("wv_progress")
      rfbutton.current?.removeAttribute("disabled")
      fetching.set(0)
      //      console.log(frames.current)
      resolve()
    })
  }

  async function update(v) {
    if (v >= 1) {
      display(false)
      await onEnd()
      display(true)
    }
  }

  useEffect(() => mvSubscribe(progress, update), [])

  return (
    <AbstractLoaderBtn ref={rfmain} className='wv_progress'>
      <button ref={rfbutton} onClick={onClick}>
        <span className='wv_loader-label'>{label}</span>
        <AbstractLoaderDots className='wv_loader-progress' />
      </button>
    </AbstractLoaderBtn>
  )
})

export const CfgSimpleLoaderBtn = memo(({ Ctx, label, prelabel }) => {
  const { getModel, progress, updateStep, fetching, frames } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfbutton = useRef(null)
  const dictionary = useDictionary()
  const [loadingState, setLoadingState] = useState(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onFetching(v) {
    swap(v)
    display(true)
  }

  useEffect(() => mvSubscribe(fetching, onFetching, false), [])

  function onClick(e) {
    let m = getModel()
    if (!m) return
    let _step = m.next[0]
    updateStep(_step)
  }

  function swap(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_progress")
    setTimeout(
      () => {
        setLoadingState(state ? dictionary.configuratorButtonLoading() : dictionary.loadingCompleted())
      },
      state ? 0 : 400
    )
  }

  function update(v) {
    if (v >= 1) {
      fetching.set(0)
    }
  }

  useEffect(() => mvSubscribe(progress, update), [])

  return (
    <SimpleLoaderBtn ref={rfmain} aria-live='polite'>
      <button ref={rfbutton} onClick={onClick}>
        <span className='wv_loader-label'>{dictionary.configuratorButtonStart()}</span>
      </button>
      <div>
        <div>
          <span>{loadingState}</span>
          <AbstractLoaderDots className='wv_loader-progress' />
        </div>
      </div>
    </SimpleLoaderBtn>
  )
})
