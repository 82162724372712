import { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe, rafDelay } from "./utils"
import { CFGV7 } from "./context"
const { MAX_FRAMES, QUALITYSIZE } = CFGV7

const Main = styled.div`
  position: absolute;
  //  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //  transform-style: preserve-3d;
  aspect-ratio: 1 / 1;

  @media (orientation: portrait) {
    width: 100%;
  }
  @media (orientation: portrait) and (min-width: 481px) {
    width: 80%;
  }
  @media (orientation: landscape) {
    height: 100%;
  }
  visibility: hidden;
  opacity: 0;
  will-change: visibility, opacity;

  &.wv_reveal {
    transition: opacity 600ms cubic-bezier(1, 1, 1, 1) 0ms, visibility 0ms 0ms;
    visibility: inherit;
    opacity: 1;
  }

  & > canvas {
    display: block;
    will-change: transform;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    aspect-ratio: 1 / 1;
    //    height: 100%;
  }
`

export function CfgCanvas({ Ctx }) {
  const { model, rfcanvas, updateStep, preload, frames, playing, canvasctx, getParams, swapping, orientation, lastStep, fromlaststep, resized, amget } =
    useFrameAnimation(Ctx)
  const rfmain = useRef()
  //  const rfimages = useRef(null)
  const rfpreload = useRef(new Image())
  const lastdrawn = useRef(null)
  let size = useRef(null).current
  let play = useRef(null).current
  let stop = useRef(null).current
  let inc = useRef(null).current
  //  let indexdir = useRef(null).current
  let trans = useRef(getParams().trans[orientation.get()]).current
  const rftimer = useRef(0)
  //  console.log("re-render")

  function trueindex(v) {
    //    console.log("trueindex", v)
    v = Math.round(v)
    v = v >= 0 ? v : MAX_FRAMES + v
    v %= MAX_FRAMES
    return v
  }

  function onEnd() {
    //    display(true)

    if (!swapping.get()) return
    play += inc
    if (play >= stop) updateStep()
  }

  function sleep() {
    //    console.log("sleep")
    return new Promise(window.requestAnimationFrame)
  }

  const CINEFPS = (1000 / 60).toFixed(1)

  const fpsscores = useRef([]).current
  const FPSSCORESRANGE = 100

  function feedFps(v) {
    //    fpsscores.push(v)
    fpsscores.push(+v.toFixed(1))
    if (fpsscores.length > FPSSCORESRANGE) fpsscores.splice(fpsscores.length - FPSSCORESRANGE, 1)
    let inc = fpsscores.length < 100 ? 1 : fpsscores.reduce((a, b) => a + b, 0) / fpsscores.length
    //    console.log("666 feedFps", v, fpsscores, inc)
    return +inc.toFixed(1)
  }

  async function render() {
    //    meas = Date.now()
    inc = 0
    let spent = 0
    let start = 0
    do {
      start = Date.now()
      //      console.log("-------")
      paintindex()
      //      let toofast = true
      //      do {
      await sleep()
      spent = Date.now() - start
      //      console.log(spent, FPS, spent / FPS)
      inc = feedFps(spent / CINEFPS)
      //      console.log(feedFps(spent / CINEFPS))
      //        toofast = spent < CINEFPS
      //     } while (toofast)
      //      console.log(Date.now() - start)
      onEnd()
    } while (swapping.get())
  }

  function paintindex() {
    //console.log("paintindex")
    //    console.log("paintindex", trans, model.get())
    if (!trans) return
    let tindex = trueindex(trans.i(play))
    let frame = amget(frames.current?.get(model.get()), tindex)
    //    console.log(frame)
    let src = frame?.src
    if (!src || lastdrawn.current === src) return move()
    size = frame?.size || 0
    if (!size) return
    //    console.log(play, tindex, size)
    rfpreload.current.src = lastdrawn.current = src
  }

  function move() {
    let scale = trans.s(play)
    let x = trans.x(play)
    let y = trans.y(play)
    if (!rfmain.current) return
    let sfactor = [rfmain.current.offsetHeight, rfmain.current.offsetWidth][orientation.get()]
    let rh = sfactor * scale
    scale = rh / size
    x *= scale
    y *= scale
    x -= 50
    y -= 50
    rfcanvas.current.style.transform = `translate3d(${x}%,${y}%,0) rotate(${trans.r(play)}deg) scale(${scale})`
  }

  function draw(e) {
    rfcanvas.current.width = rfcanvas.current.height = size
    canvasctx.current.drawImage(e.target, 0, 0, size, size)
    move()
    display(true)
  }

  async function swap(v) {
    //    console.log("cfgv7", getModel(v))
    if (!v || lastStep()) return
    display(false)
    //    canvasctx.current.globalAlpha = 0
    //    clear()
    rafDelay(() => {
      if (!swapping.get()) {
        play = stop = 0
        render()
      }
      display(true)
    })
    /*    cssSwap(rfmain.current, "wv_reveal", () => {
      //      rfimages.current = frames.current.get(v)
      //      alert(rfimages.current.size)
      if (swapping.get()) return
      play = stop = 0
      render()
    })*/
  }

  function onOrientation(v) {
    if (lastStep()) return
    if (rfcanvas.current.width === 0) rfcanvas.current.width = rfcanvas.current.height = QUALITYSIZE.S.hi[v]
    //    paintindex()
    //    let p = getParams()
    //    console.log("getParams", p)
    //    return
    let p = getParams()
    trans = p.trans[v]
    //    console.log("trans", trans.i(0))
    if (swapping.get()) return
    play = stop = 0
    render()
  }

  function onPreload(v) {
    if (v) return
    updateStep(0)
  }

  function refresh() {
    if (!playing.get()) render()
  }

  //  useTransform(trueindex, rafmode ? refresh : paintindex)

  useLayoutEffect(() => {
    const { current: main } = rfmain
    //    main.style.width = main.style.height = `${size}px`
    const { current: canvas } = rfcanvas
    canvasctx.current = canvas.getContext("2d", { alpha: true })
    //    canvasctx.current.imageSmoothingEnabled = false
    //    canvasctx.current.scale(ratio, ratio)
    const { current: preload } = rfpreload
    preload.addEventListener("load", draw, false)
    //    preload.addEventListener("error", draw, false)

    return () => {
      preload.removeEventListener("load", draw, false)
      //      preload.removeEventListener("error", draw, false)
    }
  }, [])

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onSwapping(v) {
    if (!model.get()) return
    if (!v && lastStep()) return display(false)
    //    if (!v) return
    let p = getParams()
    trans = p.trans[orientation.get()]
    play = 0
    stop = p.range
    render()
  }
  function onFromLastStep(v) {
    if (!v) display(true)
  }

  function onresized(v) {
    if (swapping.get()) return
    render()
  }

  //  useEffect(() => mvSubscribe(ready, onready, false), [])
  useEffect(() => mvSubscribe(resized, onresized), [])
  ////  useEffect(() => mvSubscribe(reload, onreload, false), [])
  useEffect(() => mvSubscribe(model, swap), [])
  useEffect(() => mvSubscribe(preload, onPreload, false), [])
  //  useEffect(() => mvSubscribe(playing, onPlaying, false), [])
  useEffect(() => mvSubscribe(swapping, onSwapping, false), [])
  useEffect(() => mvSubscribe(fromlaststep, onFromLastStep, false), [])
  //  useEffect(() => mvSubscribe(size, onSize), [])
  useLayoutEffect(() => mvSubscribe(orientation, onOrientation), [])

  function kill() {
    lastdrawn.current = null
    //    mylog("Func", "CfgCanvas", "kill")
  }

  useEffect(() => kill, [])
  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  return (
    <Main ref={rfmain}>
      <canvas ref={rfcanvas} width='0' height='0' />
    </Main>
  )
}
