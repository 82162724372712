import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { memo, useEffect, useState } from "react"

import { CFGV7 } from "./context"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"

function mapMaterial(v) {
  switch (v) {
    case "steel":
    case "platinum":
    case "titanium":
    case "18_ct_white_gold":
    case "steel_and_18_ct_white_gold":
    case "steel_and_platinum":
      return "page-color-set-rolex-v7-color-007"
    case "steel_and_18_ct_yellow_gold":
    case "18_ct_yellow_gold":
      return "page-color-set-rolex-v7-color-004"
    case "18_ct_pink_gold":
    case "steel_and_18_ct_pink_gold":
      return "page-color-set-rolex-v7-color-001"
    default:
      return null
  }
}

function buildJourneyByStep(all, stepindex, o) {
  const updateJourneyState = (a, v) => {
    let vars = o[`variations_${v}`]
    let does_exist = !!vars
    let is_alone = does_exist && !~vars.indexOf(",")
    return !!a && !!a.length ? a : does_exist && !is_alone ? v : ""
  }

  let nextlist = all.slice(stepindex + 1)
  let prevlist = all.slice(0, stepindex)
  prevlist.reverse()

  return {
    prev: prevlist.reduce(updateJourneyState, "") || "",
    next: nextlist.reduce(updateJourneyState, "") || "",
  }
}

function buildJourney(all, item) {
  const res = {
    prev: [],
    next: [],
    sindex: [],
  }
  let journey = {}
  let sindex = 0
  let last = 0
  let next = 0

  all.map((v, i) => {
    journey = buildJourneyByStep(all, i, item)
    res.prev.push(all.indexOf(journey.prev))
    res.next.push(all.indexOf(journey.next))
  })
  //  console.log(item)
  if (item.has_3d_rendition) {
    let src = res.next.slice(0, -1).map(v => (v < 0 ? 6 : v))
    ////    console.log(item.rmc, src)
    let to = 0
    let ret = []
    do {
      ////      console.log(`${to},${src[to]}`)
      ret.push(`${to},${src[to]}`)
      //      if (src[to] < 6) ret.push(`${src[to]},${to}`)
      to = src[to]
    } while (to < 6)
    //    console.log(item.rmc, ret)
    res.paths = ret
  }

  res.sindex = res.next.map(v => {
    next = v > last ? sindex++ : sindex
    last = v
    return next
  })

  return { ...item, ...res }
}

function mapCatalog(all, a) {
  return a.map(v => {
    const {
      familyCode,
      rmc,
      variations_model,
      variations_size,
      variations_material,
      variations_bezel,
      variations_bracelet,
      variations_dial,
      has_3d_rendition,
      part_config_diameter,
    } = v
    const {
      family,
      part_dial_file_name: dial_id,
      part_shadow_file_name: shadow_id,
      part_material_id,
      part_case_id,
      part_bracelet_id,
      spec_material: material,
      spec_bracelet: bracelet,
      spec_diameter: size,
      spec_bezel: bezel,
      spec_dial: dial,
      spec_case: description,
      name: familyName,
      name: model,
      newmodelselection,
      groupName,
      nameCode,
      color_set,
    } = v.watch.docs[0]

    return buildJourney(all, {
      rmc,
      family,
      part_config_diameter,
      familyCode,
      familyName,
      nameCode,
      description,
      variations_model: variations_model?.join(","),
      variations_size: variations_size?.join(","),
      variations_material: variations_material?.join(","),
      variations_bracelet: variations_bracelet?.join(","),
      variations_bezel: variations_bezel?.join(","),
      variations_dial: variations_dial?.join(",") || rmc,
      path3d: `${part_case_id}-${part_bracelet_id}`,
      model: groupName || model,
      newmodelselection,
      size,
      bracelet,
      bezel,
      dial_id,
      dial,
      material_id: mapMaterial(part_material_id),
      material,
      shadow_id,
      has_3d_rendition,
      color_set,
      href: `/watches/${familyCode}/${rmc}`,
    })
  })
}

export const CfgCatalog = memo(({ Ctx }) => {
  const { catalog, family, fetching, reset, precatalog, preload, flagships, preloading, flagship } = useFrameAnimation(Ctx)
  const locale = useLocale()
  const env = useEnv()
  const { STEPS } = CFGV7

  async function loadCatalog(v) {
    if (!v) return
    let q = new URL(
      `/api/catalog/variations${v}&language=${locale.current.codes.www}`,
      process.browser ? /*"https://staging.dev.rlx-staging.com"*/ env.services : process.env.SERVICE_CATALOG_URL
      //process.browser ? global.location.origin : process.env.SERVICE_CATALOG_URL
    )
    let results = await fetch(new Request(q))
      .then(response => response.json())
      .then(data => mapCatalog(STEPS, data.results))

    let all = results.map(({ rmc }) => rmc)
    //    catalog.current = results
    catalog.current = precatalog.current?.filter(({ rmc }) => !all.includes(rmc)).concat(results)
    preload.set(1)
  }

  function kill() {
    ////    console.log("%cCfgCatalog kill", "color: orange")
    catalog.current = precatalog.current = null
    fetching.set(0)
  }

  async function loadFlagships(v) {
    let items = JSON.parse(v).map(({ rmc }) => rmc)
    let q = new URL(
      `/api/catalog/variations/global-3d?rmc=(${items.join(",")})&language=${locale.current.codes.www}`,
      process.browser ? /*"https://preview-rlxcom.rlx-prod.com"*/ env.services : process.env.SERVICE_CATALOG_URL
    )
    let results = await fetch(new Request(q))
      .then(response => response.json())
      .then(data => mapCatalog(STEPS, data.results))

    catalog.current = precatalog.current = results
    family.set(results[0].family)
    ////    console.log("catalog.current", catalog.current)
    preloading.set(1)
  }

  useEffect(() => kill)

  useEffect(() => mvSubscribe(flagships, loadFlagships), [])
  useEffect(() => mvSubscribe(fetching, loadCatalog), [])

  return null
})
