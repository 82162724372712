import { useEffect, useRef, memo } from "react"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe, setSource } from "./utils"
import { useEnv } from "contexts/Env"
import { CFGV7 } from "./context"

const { MAX_FRAMES, QUALITYSIZE } = CFGV7

function trueIndex(v) {
  return v.toString().padStart(3, "0")
}

function initSource3d(path3d, quality, imgpath, catalogYear) {
  return `${imgpath}/v7/dam/${catalogYear}/360/${quality}/${path3d}/${path3d}--`
}

function initSourceCld(path3d, quality, secureDistribution, catalogYear) {
  return `https://${secureDistribution}/image/upload/${quality}/v1/catalogue/${catalogYear}/360/${path3d}/${path3d}--`
}

function initSourceJson(rmc, imgpath, mobile, catalogYear) {
  let path = `${imgpath}/v7/dam/${catalogYear}/configurator/data`
  path = `${path}/${mobile ? "mobile" : "desktop"}/${rmc}.json`
  // /v7/dam/test/3d-configurator/base64
  return path
}

function initSourceJsonCld(rmc, secureDistribution, mobile, catalogYear) {
  let path = `https://${secureDistribution}/raw/upload/v1706804922/catalogue/${catalogYear}/configurator/data`
  path = `${path}/${mobile ? "mobile" : "desktop"}/${rmc}.json`
  // /v7/dam/test/3d-configurator/base64
  return path
}

function initSource2d(rmc, imgpath, facet, catalogYear) {
  return `${imgpath}${setSource(facet, rmc, catalogYear)}`
}

function createUrl(blobs) {
  return function (b) {
    let url = URL.createObjectURL(b)
    blobs.push(url)
    return url
  }
}

function createJsonUrl() {
  return function (b) {
    const stype = "data:image/webp;base64,"
    //    alert(b.filter(v => !!v).length)
    return b.map((v, i) => {
      if (!!v?.length) {
        let [size, data] = v.split("@")
        //        console.log(i, size)
        //        console.log(size, data)
        //        let b = await fetch(`${stype}${data}`).then(r => r.blob())
        return {
          src: `${stype}${data}`,
          size: +size,
        }
      }
      return v
    })
  }
}

function lohi(getParams, o) {
  //  console.log(":::::", o.rmc, "lohi", o.paths)
  let loframes = o.paths.reduce((a, v) => {
    //  console.log("lohi", v)
    //  console.log("lohi", getParams(v))
    a = a.concat(getParams(v).loframes.split(","))
    return [...new Set(a)]
  }, [])
  //    .filter(v => !!v)
  //  console.log(o.rmc, "loframes", loframes)
  let hiframes = o.paths.reduce((a, v) => {
    a = a.concat(getParams(v).hiframes.split(","))
    return [...new Set(a)]
  }, [])
  //    .filter(v => !!v)
  //  console.log(o.rmc, "hiframes", hiframes)
  hiframes.forEach(i => {
    let found = loframes.indexOf(i)
    if (found >= 0) loframes.splice(found, 1)
  })
  //  console.log(o.rmc, loframes.length + hiframes.length)
  return { loframes, hiframes }
}

function qualityPath(s, q) {
  return `${s}-webp-${q}`
}

function qualityPathCld(s, q) {
  return `w_${s}/q_${q}`
}

function fetchRmc(rmc, loframes, hiframes, imgpath, secureDistribution, catalogYear, mobile) {
  ////  console.log(rmc, path3d)
  //  const pk20watchpath = initSource2d(rmc, imgpath, "watch", catalogYear)
  //  const pk20shadowpath = initSource2d(rmc, imgpath, "shadow", catalogYear)
  let jsondata = {
    rmc: rmc,
    path: initSourceJson(rmc, imgpath, mobile, catalogYear),
    //    index: 0,
    count: loframes.length + hiframes.length,
  }
  /*  let watchdata = {
      rmc: rmc,
      path: pk20watchpath,
      index: MAX_FRAMES,
      count: 1,
    }
    let shadowdata = {
      rmc: rmc,
      path: pk20shadowpath,
      index: MAX_FRAMES + 1,
      count: 1,
    }*/

  return [jsondata/*, watchdata, shadowdata*/]
}

function loadBlob(aborts, blobs) {
  return function (path, index) {
    let controller = new AbortController()
    let fn = index > 0 ? r => r.blob() : r => r.json()
    let fn2 = index > 0 ? createUrl : createJsonUrl
    aborts.push(controller)
    return fetch(new Request(path), { signal: controller.signal })
      .then(r => r.status === 200 && fn(r))
      .then(fn2(blobs))
      .catch(e => null)
  }
}

const CCR = 25

export const CfgPreload = memo(({ Ctx }) => {
  const {
    getParams,
    model,
    getModel,
    family,
    updateStep,
    orientation,
    flagship,
    frames,
    preload,
    startrmc,
    updateProgress,
    loaded,
    total,
    catalog,
    fetching,
    preloading,
    flagships,
    clearFrames,
    precatalog,
    mobile,
    wa,
    pklist
  } = useFrameAnimation(Ctx)
  const rfaborts = useRef([])
  const rfblobs = useRef([])
  const rfloop = useRef(null)
  const env = useEnv()
  const { cloudinary: { secureDistribution } } = env

  async function init(v) {
    if (!v) return
    loaded.current = total.current = 0
    updateProgress(0)
    let p = getParams(0)
    let index = p.trans[orientation.get()].i(0)
    //    await clearFrames()
    await clearFrames(
      precatalog.current.map(({ rmc }) => rmc),
      index
    )
    //    await kill()
    let rmcs3d = catalog.current?.filter(v => v.has_3d_rendition)
    pklist.set(rmcs3d.map(v => v.rmc).join(","))
    //    return
    rfloop.current = rmcs3d.reduce((a, o) => {
      const { loframes, hiframes } = lohi(getParams, o)
      //      total.current += loframes.length + hiframes.length + 2
      return a.concat(fetchRmc(o.rmc, loframes, hiframes, env.content, secureDistribution, env.catalogYear, mobile.get()))
    }, [])
    //    total.current = rmcs3d.length * (MAX_FRAMES + 2)
    total.current = rmcs3d.length * MAX_FRAMES + 1
    //    let gcount = 0
    //    console.log("total frames =", total.current)
    //    return
    for (let i = 0; i < CCR; i++) {
      load(v)
    }

    async function load(uid) {
      if (v !== uid || !rfloop.current.length) return
      let { rmc, path, index, count } = rfloop.current.shift()
      //console.log(rmc, path, index, count)
      let bloburl = await loadBlob(rfaborts.current, rfblobs.current)(path, index)
      //      updateProgress(count / 2)
      //      console.log("++", count)

      if (!frames.current.has(rmc)) frames.current.set(rmc, [])
      const arr = frames.current.get(rmc)
      /*      if (index > 0) {
              //        rfblobs.current.push(bloburl)
              arr[+index] = {
                src: bloburl,
              }
              updateProgress(1)
              ++gcount
            } else {
              //        updateProgress(bloburl.filter(v => !!v).length * 0.5)*/
      if (bloburl) {
        bloburl.forEach((v, i) => {
          arr[i] = v
          updateProgress(1)
          //          ++gcount
        })
      }
      else {
        updateProgress(1)
        //        gcount += MAX_FRAMES
      }
      //      }
      /*      console.log(`===== ${gcount}/${total.current}`)
            if (gcount >= total.current) {
              //        console.log(frames.current)
              return
            }*/
      load(uid)
    }
  }

  async function initflagships(v) {
    if (!v) return
    let p = getParams(0)
    let index = p.trans[orientation.get()].i(0)
    await clearFrames()
    //    await kill()
    let list = JSON.parse(flagships.get())
    let q = Array.from({ length: 2 }).map((v, i) => qualityPathCld(QUALITYSIZE.S.start[i], QUALITYSIZE.Q.start[i]))
    rfloop.current = list
      .map(({ rmc }) => getModel(rmc))
      .map(({ rmc, path3d }) => ({
        rmc: rmc,
        path: `${initSourceCld(path3d, q[mobile.get()], secureDistribution, env.catalogYear)}${trueIndex(index)}.webp`,
        index: index,
        size: QUALITYSIZE.S.start[mobile.get()],
      }))
    let total = rfloop.current.length
    let count = 0
    startrmc.set(rfloop.current[0].rmc)
    for (let i = 0; i < total; i++) {
      load()
    }

    async function load() {
      if (!rfloop.current.length) return
      let { rmc, path, index, size } = rfloop.current.shift()
      let bloburl = await loadBlob(rfaborts.current, rfblobs.current)(path, 1)
      const arr = []
      arr[+index] = {
        src: bloburl,
        size: size,
      }
      //      rfblobs.current.push(bloburl)
      frames.current.set(rmc, arr)
      if (++count >= total) {
        model.set(startrmc.get())
        wa()
        if (list.length < 2) {
          fetching.set(list[0].path)
        }
        return
      }
      load()
    }
  }

  function kill() {
    return new Promise(resolve => {
      //    console.log("Func", "CfgPreload", "kill")
      rfaborts.current.forEach(controller => controller.abort())
      rfblobs.current.forEach(url => {
        URL.revokeObjectURL(url)
        //        console.log("rfblobs revoked", url)
      })
      rfaborts.current = []
      rfblobs.current = []
      rfloop.current = []
      resolve()
    })
  }

  useEffect(() => kill)

  //  useEffect(() => mvSubscribe(reset, kill, false), [])
  useEffect(() => mvSubscribe(preload, init), [])
  useEffect(() => mvSubscribe(preloading, initflagships), [])

  function onPklist(v) {
    //    console.log("onPklist", v)
    if (!v) return
    if (v === "_") {
      updateProgress(1)
      pklist.set(null)
    }
  }
  useEffect(() => mvSubscribe(pklist, onPklist), [])

  return null
})
