import styled from "@emotion/styled"
import { useRef } from "react"
import { useScroll } from "framer-motion"

import Video, { cssContainer } from "components/media/VideoCLD"

import { getGradient } from "components/articles/common"

import PageTitleBlock from "./PageTitleBlock"
import BlockCTA from "./BlockCTA"
import Skyline from "./Skyline"
import SubHeading from "./SubHeading"
import Overlay from "./Overlay"
import { sectionStyle } from "./style"

const Section = styled.section`
  ${sectionStyle}
  margin-block-start: calc(var(--subnav-height) / 2);

  & > div.${cssContainer} {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    grid-column: doc;
    grid-row: 1;

    z-index: -1;
  }

  & > div.${cssContainer} img,
  & > div.${cssContainer} video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`

export default function CoverIntroVideo({ heading, video_cld: video, intro, video_amp_cta, sub_heading, title_gradient, chapo_gradient, className, ...props }) {
  const blocks = intro?.reduce((acc, item) => new Map([...acc, ...Object.entries(item)]), new Map())
  const videoCTA = video_amp_cta[1]

  const ref = useRef()
  const vidRef = useRef()

  const { scrollY } = useScroll({ target: ref, offset: ["start start", "end start"] })

  const ttlGradient = title_gradient?.colors ? getGradient(title_gradient.colors) : null
  const chapoGradient = chapo_gradient?.colors ? getGradient(chapo_gradient.colors) : null

  const styles = {
    ...(ttlGradient && { "--title-gradient": ttlGradient }),
    ...(chapoGradient && { "--chapo-gradient": chapoGradient }),
    "--row-h": sub_heading?.length ? "150vh" : "100vh",
  }

  return (
    <>
      <PageTitleBlock {...heading} videoCTA={videoCTA} prog={scrollY} />
      <Section ref={ref} className={className} style={styles}>
        {video && <Video ref={vidRef} autoPlay loop noButton {...video} sizes='100vw' />}
        {sub_heading?.length ? <SubHeading prog={scrollY} {...sub_heading[0].heading} /> : null}
        <Overlay prog={scrollY} doubled={sub_heading?.length} />
        {blocks?.has("cta_block") ? (
          <BlockCTA {...blocks?.get("cta_block")} prog={scrollY} doubled={sub_heading?.length} />
        ) : blocks?.has("skyline") ? (
          <Skyline {...blocks?.get("skyline")} isHeading={heading?.title?.length} prog={scrollY} doubled={sub_heading?.length} />
        ) : null}
      </Section>
    </>
  )
}
