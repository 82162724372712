import styled from "@emotion/styled"
import useSSR from "hooks/useSSR"
import { useCallback, useRef } from "react"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useEnv } from "contexts/Env"
import { useNavigation } from "contexts/Navigation"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { Icon } from "components/icon/Icon"

import { bold, legend100 } from "css/text"

import { useButton } from "@react-aria/button"
import { VisuallyHidden } from "@react-aria/visually-hidden"

const Root = styled.div`
  position: relative;
  padding-block: 20px;
  padding-inline: var(--outer-margin);
  overflow-x: auto;
  scrollbar-width: none;
  white-space: nowrap;
  background-color: white;

  &::-webkit-scrollbar {
    display: none;
  }

  a,
  button,
  span {
    all: unset;
    ${legend100}
    ${bold}
    align-items: center;
    display: inline-flex;
    gap: 1ex;
    margin-inline-start: -0.5rem;
    min-height: 2.25rem;
    padding: 0.5rem;
    transition: color 0.3s;
    user-select: none;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  ul {
    display: inline-flex;
    gap: 0.5rem;
    margin-inline-start: 0.5rem;

    a {
      all: unset;
      color: rgb(var(--rolex-green));
      cursor: pointer;
      line-height: 0;
      padding: 0.5rem;
      vertical-align: middle;

      @media (hover: hover) {
        :hover {
          color: inherit;
        }
      }
    }
  }

  svg {
    fill: currentColor;
    transition: color 0.3s;
  }
`

// Component for each share item
function Item({ label, text }) {
  const env = useEnv()
  const dictionary = useDictionary()
  const currentUrl = process.browser && window?.location.href

  // Generate aria label for accessibility
  const ariaLabel = text !== "" ? `${text.replace("%s", label)} - ${dictionary.openInPopin()}` : `${label} - ${dictionary.openInPopin()}`

  // Function to open share dialog
  const share = url => {
    process.browser && window?.open(url, "share", "height=500, width=500")
  }

  // Generate sharing URLs for each network
  const sharingUrl = env.sharing.reduce((acc, item) => {
    return { ...acc, [item.network]: item.url.replace("%u", currentUrl) }
  }, {})

  // Get share link name for analytics
  const shareLinkName = sharingUrl[label.toLowerCase()].split("/").slice(2)[0]

  // Share on family and model pages
  const url = currentUrl ? new URL(currentUrl) : null
  const isWatchesPage = url?.pathname.includes("/watches/") && !url?.pathname.includes("/new-watches") && !url?.pathname.includes("/configure")
  const isRetailerPage = url?.pathname.includes("/rolex-dealers/")
  const retailerType = url?.pathname.includes("rswi") ? "store" : "retailer"
  const productFamily = isWatchesPage ? url.pathname.split("/watches/")[1].split("/")[0] : ""
  const productRMC =
    isWatchesPage && /^m[a-z0-9]+\-[a-z0-9]+$/.test(url.pathname.split("/watches/")[1].split("/")[1]) ? url.pathname.split("/watches/")[1].split("/")[1] : ""

  let shareType = "generic-share"
  if (isRetailerPage) shareType = "retailer-share"
  if (productFamily) shareType = productRMC ? "model-share" : "family-share"
  if (url?.pathname.includes("find-rolex")) shareType = "generic-share"

  const data = {
    eventName: "share",
    eventType: "Click",
    shareInfo: {
      type: shareType,
      shareLinkName,
      position: "pre-footer",
      platform: label,
    },
  }

  // No products node on all models page and find rolex page
  if (productRMC)
    data.products = [
      {
        productCategory: productFamily,
        productID: productRMC,
        productQuantity: "1",
      },
    ]

  if (isRetailerPage) {
    const [split] = (url?.pathname?.split("/") || []).reverse()
    const did = split?.split("-")
    data.retailerInfo = {
      id: did?.[retailerType === "store" ? 0 : did.length - 1]?.toUpperCase(),
      type: retailerType,
      previewsComponent: "retailer page",
    }
  }

  // Trigger analytics event and open share dialog on click
  return (
    <a
      onClick={() => {
        waTriggerEvent(data)
        share(sharingUrl[label.toLowerCase()])
      }}
      aria-label={ariaLabel}
      href={sharingUrl[label.toLowerCase()]}
      rel='noopener noreferrer'
      target='_blank'
      title={ariaLabel}
    >
      <Icon type={label.toLowerCase()} size='20' />
      <VisuallyHidden>{label}</VisuallyHidden>
    </a>
  )
}

// Fallback component for when native sharing is not available
function ShareFallback({ label, text, networks: items, aria_label }) {
  return (
    <Root>
      <span>{label}</span>
      <ul aria-label={aria_label}>
        {items.map((item, index) => (
          <li key={index}>
            <Item {...item} text={text} />
          </li>
        ))}
      </ul>
    </Root>
  )
}

// Component for native sharing
function ShareNative({ label }) {
  const console = useConsole()
  const rbutton = useRef()
  const navigator = process.browser && global?.navigator

  const { buttonProps } = useButton(
    {
      onPress: () => onClick(),
    },
    rbutton
  )

  const onClick = useCallback(() => {
    try {
      navigator?.share({
        title: document?.title,
        url: window?.location.href,
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <Root>
      <button {...buttonProps} ref={rbutton}>
        <Icon type='share' />
        <span>{label}</span>
      </button>
    </Root>
  )
}

// Main Share component
export default function Share(props) {
  const console = useConsole()
  const ssr = useSSR()
  const navigation = useNavigation()
  const isNativeAllowed = process.browser && global?.navigator?.canShare && global?.matchMedia("(hover: none) and (pointer: coarse)").matches

  console.verbose("Share(%o)", props)

  // Don't render on 404 page
  if (navigation.slug === "/404") return

  // Render native sharing component if available
  if (isNativeAllowed) return <ShareNative {...props} />

  // Otherwise, render fallback component
  return !ssr && <ShareFallback {...props} />
}
