import styled from "@emotion/styled"
import { motion } from "framer-motion"
import getMediaQuery from "css/breakpoints"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"

export const Root = styled(motion.div)`
  position: relative;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  margin: 0;
  width: 86vw;

  ${getMediaQuery("m")} {
    min-width: 576px;
    width: 56vw;
  }

  ${getMediaQuery("l")} {
    width: 38vw;
  }
  ${getMediaQuery("m", { max: true })} {
    // height: 100vh;
    height: auto;
  }
  grid-column: doc;
  top: 0;
  --fx: 0.4s;
  --delay: calc((1 - var(--t, 0)) * 600ms);
  --t: calc(70px + var(${cssVarHeaderHeight}));
`

export const SuggList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-inline-start: 35px;

  grid-column: main;
  flex-direction: column;

  span:last-of-type {
    margin-top: 20px;
  }

  ${getMediaQuery("m", { max: true })} {
    padding: 6px 0px 30px 0px;
    padding-inline-start: 0px;
  }
`